import * as yup from "yup";
import {addMonths, isDate, isValid, parse, startOfToday} from 'date-fns';

const parseDateString = (value, originalValue) =>  {

    if (!isValid(originalValue)){
        return undefined;
    }


    return isDate(originalValue) ? originalValue : parse(originalValue, "dd/MM/yyyy", new Date())
};

export const contractCreateSchema = yup.object().shape({
    storeId: yup.string().nullable().required("Mağaza gereklidir.").typeError("Mağaza gereklidir."),
    discountRate: yup.number().nullable().required("İskonto oranı gereklidir.").typeError("İskonto oranı gereklidir."),
    exportRate: yup.number().nullable().required("İhracat fiyat farkı oranı gereklidir.").typeError("İhracat fiyat farkı oranı gereklidir."),
    minimumSalesRate: yup.number().nullable().required("Minimum mağaza satış oranı gereklidir.").typeError("Minimum satış oranı gereklidir."),
    startDate: yup.date().nullable().default(undefined).required("Başlangıç tarihi gereklidir.").transform(parseDateString).typeError("Başlangıç tarihi gereklidir.").min(startOfToday(), "En erken başkangıç tarihi bugün olmalıdır."),
    endDate: yup.date().nullable().transform(parseDateString).typeError("Bitiş tarihi gereklidir.")
        .when('startDate', (startDate, schema) => {
            if (isValid(startDate)){
                let oneMonthAfter = addMonths(new Date(startDate), 1)
                return startDate && schema.min(oneMonthAfter, "Sözleşme bitiş tarihi ile sözleşme başlangıç tarihi arasında en az 1 ay süre olmalıdır.");
            }
            return schema;
        })
}).required();

export const contractUpdateSchema = yup.object().shape({
    storeId: yup.string().nullable().required("Mağaza gereklidir.").typeError("Mağaza gereklidir."),
    discountRate: yup.number().nullable().required("İskonto oranı gereklidir.").typeError("İskonto oranı gereklidir."),
    exportRate: yup.number().nullable().required("İhracat fiyat farkı oranı gereklidir.").typeError("İhracat fiyat farkı oranı gereklidir."),
    minimumSalesRate: yup.number().nullable().required("Minimum mağaza satış oranı gereklidir.").typeError("Minimum satış oranı gereklidir."),
    startDate: yup.date().nullable().default(undefined).required("Başlangıç tarihi gereklidir.").transform(parseDateString).typeError("Başlangıç tarihi gereklidir."),
    endDate: yup.date().nullable().transform(parseDateString).typeError("Bitiş tarihi gereklidir.")
        .when('startDate', (startDate, schema) => {
            if (isValid(startDate)){
                let oneMonthAfter = addMonths(new Date(startDate), 1)
                return startDate && schema.min(oneMonthAfter, "Sözleşme bitiş tarihi ile sözleşme başlangıç tarihi arasında en az 1 ay süre olmalıdır.");
            }
            return schema;
        })
}).required();