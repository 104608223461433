import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { ButtonGroup, Divider, TextField } from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    textAlign: "right",
  },
  root: {
    display: "inline-block",
    margin: 2
  },
}));

export default function FeatureItem({
  value,
  onRemove,
  onFeatureChange,
  showEdit,
  color,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [featureValue, setfeatureValue] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onFeatureChange(value, featureValue);
    setAnchorEl(null);
  };

  const handleFeatureChange = (e) => {
    setfeatureValue(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <ButtonGroup
        variant="outlined"
        color={color}
        aria-label="split button"
      >
        <Button
          style={{textTransform: 'none'}}
          aria-describedby={id}
          variant="outlined"
          size="small"
          color={color}
        >
          {`${value.featureValue || ""} ${value.name}`}
        </Button>
        {showEdit && (
          <Button color={color} size="small" onClick={handleClick}>
            <Edit fontSize={"small"} />
          </Button>
        )}
        <Button color={color} size="small" onClick={onRemove}>
          <Close />
        </Button>
      </ButtonGroup>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TextField
          label="Özellik Değeri"
          fullWidth
          type="number"
          onChange={handleFeatureChange}
          value={featureValue || ""}
        />
        <Divider className={classes.divider} />
        <div className={classes.buttons}>
          <Button size="small" color="primary" onClick={handleClose}>
            Kaydet
          </Button>
          <Button size="small" color="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </div>
      </Popover>
    </div>
  );
}

FeatureItem.defaultProps = {
  showEdit: true,
  color: 'primary'
};
