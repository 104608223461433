import {Box} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export default function TabPanel(props) {
    const { children, tabValue, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={tabValue !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {tabValue === index && (<Box>{children}</Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    tabValue: PropTypes.any.isRequired,
};