import React from "react";
import { Line, Doughnut } from 'react-chartjs-2';
import {alpha, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "components/container";
import Grid from "components/grid";
import Card from "components/card"

import {Box, CardContent, Avatar, CardHeader, Divider, Button, useTheme} from "@material-ui/core";
import {
  blue,
  blueGrey,
  brown, common,
  green, grey,
  indigo,
  lightBlue, orange,
  pink,
  red
} from "@material-ui/core/colors";
import {
  ArrowRight,
  ShoppingCart,
  AcUnit,
  Assessment,
  LocalShipping,
  People,
  PermIdentity,
  Money,
  Business, Public, ArrowDropDown, TrendingFlat
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cardHeaderAction: {
    margin:0
  }
}));

export default function Dashboard() {

  const theme = useTheme();
  const classes = useStyles();
  const salesData = {
    datasets: [
      {
        fill: true,
        lineTension: 0.5,
        borderJoinStyle: 'miter',
        pointRadius: 3,
        pointHitRadius: 10,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        borderColor: alpha(green[700], 0.8),
        backgroundColor: alpha(green[600], 0.8),
        data: [12, 15, 23, 17, 18, 21, 28],
      }
    ],
    labels: ['1 Mayıs', '2 Mayıs', '3 Mayıs', '4 Mayıs', '5 Mayıs', '6 Mayıs', '7 Mayıs']
  };

  const salesChartOptions = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true,
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
      }
    }
  };

  const categoryProductData = {
    datasets: [
      {
        data: [22, 15, 63],
        backgroundColor: [
          indigo[500],
          red[600],
          orange[600]
        ],
        borderWidth: 8,
        borderColor: common.white,
        hoverBorderColor: common.white
      }
    ],
    labels: ['Koltuk Takımı', 'Köşe Koltuk', 'Yatak Odası']
  };

  const categoryProductDataOptions = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },

    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        callbacks: {
          labelTextColor: function(context) {
            return grey[800];
          }
        }

      }
    }
  };

  const categories = [
    {
      title: 'Yatak Odası',
      value: 63,
      color: orange[600]
    },
    {
      title: 'Koltuk Takımı',
      value: 22,
      color: indigo[500]
    },
    {
      title: 'Köşe Koltuk',
      value: 15,
      color: red[600]
    },
  ];

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} m={2} >
        {/* Chart */}
        <Grid item xs={12} md={8} lg={8}>
          <Card>
            <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} classes={{action: classes.cardHeaderAction }}
                action={(
                    <Button endIcon={<ArrowDropDown />} size="small" variant="text" color="primary">Son 7 gün</Button>
                )}
            />
            <Divider />
            <CardContent>
              <Box style={{height: 268, position: 'relative'}}>
                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                  <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                  <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                </Box>
                <Line data={salesData} options={salesChartOptions} type="line"/>
              </Box>
            </CardContent>
            <Divider />
            <Box style={{display: 'flex', justifyContent: 'flex-end', padding: 3}}>
              <Button href="/reports" color="primary" endIcon={<ArrowRight />} size="small"variant="text">SİPARİŞ RAPORUNA GENEL BAKIŞ</Button>
            </Box>
          </Card>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={4}>
          <Card>
            <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} classes={{action: classes.cardHeaderAction }}
                        action={(
                            <Button endIcon={<ArrowDropDown />} size="small" variant="text" color="primary">Son 7 gün</Button>
                        )}
                        title="Trend Kategoriler"
            />
            <Divider />
            <CardContent>
              <Box style={{height: 182, position: 'relative'}}>
                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                  <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                  <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                </Box>
                <Doughnut data={categoryProductData} options={categoryProductDataOptions} type="doughnut"/>
              </Box>
              <Box style={{display: 'flex',justifyContent: 'space-between', paddingTop: '10px'}}>
                {categories.map(({color,title,value}) => (
                    <Box key={title} style={{padding: '10px', textAlign: 'center'}}>
                      <Typography color="textPrimary" variant="body2" >{title}</Typography>
                      <Typography style={{ color }} variant="h6">%{value}</Typography>
                    </Box>
                ))}
              </Box>
            </CardContent>
            <Divider />
            <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: 3
                }}
            >
              <Button
                  href="/reports"
                  color="primary"
                  endIcon={<ArrowRight />}
                  size="small"
                  variant="text"
              >
                KATEGORİ RAPORUNA GENEL BAKIŞ
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} pt={20}>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/orders" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Siparişler</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: red[600], height: 56, width: 56}}>
                    <ShoppingCart fontSize="small"/>
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>%0</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/reports" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Gelir</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0 TL</Box>
                  </Box>
                  <Avatar style={{backgroundColor: green[600], height: 56, width: 56}}>
                    <Money/>
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>%0</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/customers" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Müşteriler</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: blue[600], height: 56, width: 56}}>
                    <People/>
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>0 Yeni müşteri</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/products" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Ürünler</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: pink[600], height: 56, width: 56}}>
                    <AcUnit/>
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>0 Yeni Ürün</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/reports" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Raporlar</Typography>
                  </Box>
                  <Avatar style={{backgroundColor: red[600], height: 56, width: 56}}>
                    <Assessment />
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>%0 Toplam Ölçüm</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/company">
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Firma Bilgileri</Typography>
                  </Box>
                  <Avatar style={{backgroundColor: blueGrey[600], height: 56, width: 56}}>
                    <Business />
                  </Avatar>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/users" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Kullanıcılar</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: indigo[600], height: 56, width: 56}}>
                    <PermIdentity />
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>0 Personel</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/locations" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Satış Bölgeleri</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: lightBlue[600], height: 56, width: 56}}>
                    <Public />
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center" alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>0 Yeni satış bölgesi</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen aya göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Link underline="none" color="inherit" href="/logistics" >
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                  <Box>
                    <Typography variant="h6">Nakliye</Typography>
                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">0</Box>
                  </Box>
                  <Avatar style={{backgroundColor: brown[600], height: 56, width: 56}}>
                    <LocalShipping />
                  </Avatar>
                </Box>
                <Box display="flex" alignContent="center" alignItems="flex-end">
                  <Box color="grey.600" display="flex" alignContent="center"  alignItems="flex-end" paddingRight={1}>
                    <TrendingFlat/>
                    <Box component="span" fontWeight="fontWeightBold" paddingLeft={1}>%0 Gönderim</Box>
                  </Box>
                  <Box component="span" fontSize="small" color="grey.600">Geçen Aya Göre</Box>
                </Box>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
