import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// number input disable scrolling
document.addEventListener("wheel", function(event){
    if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
});

/* Print with pagination */
//  todo: make more generic for all print pages. get container as parameters.
window.addEventListener('beforeprint', function (){
    const container = document.getElementById('DialogPrint');
    const contentContainer = document.getElementById('DialogPrintContent');

    let minPage = parseInt(contentContainer.getAttribute('data-min-page')) || 1;
    let contentHeight = Math.max(container.scrollHeight, 842 * minPage);

    let pageNumberText = contentContainer.getAttribute('data-lang') === 'tr' ? 'Sayfa' : 'Page';

    let totalPages = Math.floor(contentHeight / 842);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    for (let i = 1; i <= totalPages; i++) {
        let pageNumberDiv = document.createElement("div");
        pageNumberDiv.classList.add('print-page-number');
        let pageNumber = document.createTextNode(`${pageNumberText}: ${i}/${totalPages}`);
        pageNumberDiv.style.position = "absolute";
        pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)"; //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
        pageNumberDiv.style.height = "16px";
        pageNumberDiv.appendChild(pageNumber);
        container.parentNode.insertBefore(pageNumberDiv, container);
        pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 20px))";
    }
    let pageTotalText = contentContainer.getAttribute('data-pagination-message').replace(':total', totalPages.toString());
    let pageTotalDiv = document.createElement("div");
    pageTotalDiv.classList.add('print-page-number');
    pageTotalDiv.style.position = "absolute";
    pageTotalDiv.style.height = "16px";
    pageTotalDiv.style.top = "calc((" + totalPages + " * (297mm - 0.5px)) - 40px)"; //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
    pageTotalDiv.appendChild(document.createTextNode(pageTotalText));
    pageTotalDiv.style.left = "20px";
    container.parentNode.insertBefore(pageTotalDiv, container);
});
window.addEventListener('afterprint', function (){
    document.querySelectorAll('.print-page-number').forEach((el)=>{el.remove()});
});
