const orders = [
  {
    id: 7,
    code: 'OLN657849',
    date: '12/05/2021 15:44',
    status_id: 2,
    product: {
      id: 7,
      sku: 'ABV2131567',
      name: 'Victoria',
      category: 'Yemek Odası',
      active: false,
    },
    shop:{
      id: 1,
      name: 'XYZ Furniture',
    }
  },
  {
    id: 8,
    code: 'OLN657849',
    date: '12/05/2021 15:44',
    status_id: 3,
    product: {
      id: 8,
      sku: 'ABP128790',
      name: 'Picasso',
      category: 'Koltuk Takımı',
      active: false,
    },
    shop:{
      id: 1,
      name: 'XYZ Furniture',
    }
  },
  {
    id: 9,
    code: 'OLN657849',
    date: '12/05/2021 15:44',
    status_id: 4,
    product: {
      id: 9,
      sku: 'ABD5461234',
      name: 'Da Vinci',
      category: 'Tv Ünitesi',
      active: false,
    },
    shop:{
      id: 1,
      name: 'XYZ Furniture',
    }
  },
  {
    id: 10,
    code: 'OLN657849',
    date: '12/05/2021 15:44',
    status_id: 5,
    product: {
      id: 10,
      sku: 'ABT121521',
      name: 'Tesla',
      category: 'Tv Ünitesi',
      active: true,
    },
    shop:{
      id: 1,
      name: 'XYZ Furniture',
    }
  },
];

export default orders;