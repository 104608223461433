import FormItem from "components/form-item";
import PhoneInput from "components/phone-input";
import Select from "components/select";
import TextField from "components/text-field";
import React, {useEffect, useState} from "react";
import useCustomFetch from "hooks/use-fetch";
import {getValueFromTranslation} from "utils";

export default function AuthorizedPersonStep({ control, setValue, visible }) {
  const { get, response } = useCustomFetch();
  const [title, setTitle] = useState([]);
  const [companyType, setCompanyType] = useState([]);
  useEffect(() => {
    async function getTitle() {
      const data = await get("/i18n-service/organization/roles/bytag/owner");
      if (response.ok)
        data &&
          setTitle(
            data.map((item) => ({
              id: item.id,
              name: getValueFromTranslation(item.translations, "value"),
            }))
          );
    }
    async function getCompanyType() {
      const data = await get("/i18n-service/organization/companytypes");
      if (response.ok)
        data &&
          setCompanyType(
            data.map((item) => ({
              id: item.id,
              name: getValueFromTranslation(item.translations, "value"),
            }))
          );
    }
    getCompanyType();
    getTitle();
  }, []);

  return (
    <div style={{ display: visible ? "block" : "none", width: "500px", margin: "0 auto" }}>
      <FormItem control={control} name="companyOwner.companyType" onChange={(v)=>{ setValue("companyOwner.companyType", v && v.id) }}>
        <Select
          id="companyType"
          label="Şirket Türü"
          options={companyType}
          displayMember="name"
        />
      </FormItem>
      <FormItem control={control} name="companyOwner.title" onChange={(v)=>{ setValue("companyOwner.title", v && v.id) }}>
        <Select
          id="title"
          label="Ünvanı"
          options={title}
          displayMember="name"
        />
      </FormItem>
      <FormItem control={control} name="companyOwner.name">
        <TextField label="Yetkili Adı" />
      </FormItem>
      <FormItem control={control} name="companyOwner.surname">
        <TextField label="Yetkili Soyadı" />
      </FormItem>
      <FormItem control={control} name="companyOwner.phone">
        <PhoneInput label="Yetkili Cep Telefonu" />
      </FormItem>
      <FormItem control={control} name="companyOwner.email">
        <TextField label="Yetkili E-posta Adresi" />
      </FormItem>
    </div>
  );
}
