import React, {useEffect} from "react";
import useCustomFetch from "hooks/use-fetch";
import {useRouter} from "hooks/use-router";
import {getCurrentUser} from "../../utils";

export default function SecureFlow() {
    const { history, pathname } = useRouter();
    const {get, response} = useCustomFetch();


    useEffect(() => {
        async function check() {
            const registerCheck =   await get("supplier-service/suppliers/registercheck");

            if (!response.ok || !registerCheck){
                return history.push('/register')
            }

            const activateCheck = await get("supplier-service/suppliers/issupplieractivated");

            if (!response.ok || !activateCheck){
                return history.push('/require-activation')
            }else{
                sessionStorage.setItem('activated', "ok");
            }
        }

        let activated = sessionStorage.getItem('activated');

        if (pathname !== "/register" && pathname !== "/require-activation" && !activated && getCurrentUser()) {
            check();
        }

    }, []);
    return (
        <></>
    );
}