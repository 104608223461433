import React, {useEffect, useState} from "react";
import Stepper from "components/stepper";
import Grid from "components/grid";
import Container from "components/container";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema, steps} from "./const";
import {useRouter} from "hooks/use-router";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import {Box, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import Logo from "assets/logo.svg"
import useCustomFetch from "hooks/use-fetch";

export default function Register(props) {
  const { control, handleSubmit, setValue, getValues, formState, trigger } =
    useForm({
      resolver: yupResolver(schema),
    });

  const {oidcUser} = useReactOidc();

  const [supplier, setSupplier] = useState();
  const { fields } = useFieldArray({
    control,
    name: "departmentChiefs",
  });

  const { post, get, response } = useCustomFetch();
  const router = useRouter();
  useEffect(() => {
    async function getTempData() {
      const data = await get("supplier-service/temporarysuppliers");
      steps.forEach((step) => {
        data[step.key] &&
          Object.keys(data[step.key]).forEach((item) => {
            setValue(`${step.key}.${item}`, data[step.key][item]);
          });
      });
      setSupplier(data);
    }
    getTempData();
  }, []);

  const onSubmit = async (data) => {
    await post("supplier-service/suppliers", data);
    if (response.ok) {
      router.push("/");
    }
  };

  const handleStepChange = async (activeStep) => {
    const { fields } = steps[activeStep];
    let triggered = await trigger(fields);
    const errors =
      fields
        .map((item) => {
          const fieldInf = item.split(".");
          return (
            formState.errors[fieldInf[0]] &&
            formState.errors[fieldInf[0]][fieldInf[1]]
          );
        })
        .filter((item) => item !== undefined).length;

    const valid = triggered && errors === 0;

    if (valid) {
      let requestData = getValues();
      requestData.email = oidcUser.profile.email;
      await post("supplier-service/temporarysuppliers/save", requestData);
    }
    return valid;
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Container fixed>
        <Box style={{margin:"10px auto 0", width: 180, height: 65, position: 'relative'}}>
          <img style={{width: '100%'}} src={Logo} alt="Dataware Production" />
          <span style={{position: 'absolute', bottom: 9, right: 0, fontSize: '13px', color: '#00436e'}}>ÜRETİM</span>
        </Box>
        <Typography align={"center"} variant={"h4"}>
          Şirketinizi Kaydedin
        </Typography>
        <Typography
          align={"center"}
          variant={"body1"}
          style={{ marginTop: 4, color: grey[700] }}
        >
          Ürünlerinizin Türkiye’de <strong>48.000</strong>, Dünya’da <strong>450.000</strong> satış mağazasında sergilenmesine az kaldı.
        </Typography>
        <Stepper
          steps={steps}
          control={control}
          setValue={setValue}
          fields={fields}
          optionalParams={supplier}
          onSubmit={handleSubmit(onSubmit)}
          onStepChange={handleStepChange}
        />
      </Container>
    </Grid>
  );
}
