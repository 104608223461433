import StringSelect from "../../../components/string-select";
import DatePicker from "../../../components/date-picker";
import InputSelector from "../../../components/input-selector";
import React from "react";
import Grid from "../../../components/grid";
import {getValueFromTranslation} from "../../../utils";
import FormItem from "../../../components/form-item";

export const getFeatureComponent = (item, id, definedOptionList) => {
    let component = null;
    switch (item.uiType) {
        case "full_string":
            component = <StringSelect key={`string-select-${id}`} showEdit={false} loadingText={"seçenekler aranıyor..."} definedOptionList={definedOptionList} newItemDescription="yeni seçenek ekle" />;
            break;
        case "full_date":
            component = <DatePicker label="Date" key={`date-${id}`} />;
            break;
        case "year":
            component = (
                <DatePicker views={["year"]} label="Year" key={`year-${id}`} />
            );
            break;
        case "input_selector":
            component = (
                <InputSelector
                    label={item.title}
                    inputCount={item.definedType.inputCount}
                    dropValues={item.definedType.dropValues}
                    key={`input-selector-${id}`}
                />
            );
            break;
        default:
            break;
    }
    return component;
};



export const renderFields = (fields, control) => {
    return fields.map((item, index) => (
        <Grid container spacing={3} key={`feature-list-${index}`}>
            <Grid item sm={3}>
                {getValueFromTranslation(item.feature.translations, "value")}
            </Grid>
            <Grid item sm={9}>
                <FormItem control={control} name={`features.${index}.feature`}>
                    {getFeatureComponent(item.feature.featureFieldType, item.id)}
                </FormItem>
            </Grid>
        </Grid>
    ));
};

export const initializeFeaturesPostValue = (fields, data) => {
    let productFeatures = [];
    fields.forEach((field, index) => {
        var featureData = data[index].feature;
        if (!featureData) return;
        let values = [];
        switch (field.feature.featureFieldType.uiType) {
            case "full_string":
                values = featureData.map((item) => ({
                    wordId: item.value,
                    value: item.featureValue,
                }));
                break;
            case "full_date":
            case "year":
                values.push({
                    wordId: null,
                    value: featureData,
                });
                break;
            case "input_selector":
                values = featureData.map((item) => ({ value: item }));
                break;
            default:
                break;
        }
        productFeatures.push({
            featureId: field.feature.id,
            ordering: productFeatures.length,
            values: values,
        });
    });
    return productFeatures;
};

export const initializeModulesPostValue = (fields, data) => {
    return data.map((item) => ({
        moduleModelName: item.moduleModelName,
        values:
            item.moduleModelNameIds &&
            item.moduleModelNameIds.map((x) => ({
                wordId: x.value,
                value: x.featureValue,
            })),
        isStandard: item.isStandard,
        standardQuantity: item.standardQuantity,
        price: {
            quantity: item.price[0],
            currency: item.price[1],
        },
        dimensions: item.dimensions,
        packaging: item.packaging,
    }));
};
