export const designerList = [
    {
        id: "Abdullah Küçükoğlu",
        title: "Abdullah Küçükoğlu",
    },
    {
        id: "Adnan Kanik",
        title: "Adnan Kanik",
    },
    {
        id: "Ali Osman Seymen",
        title: "Ali Osman Seymen",
    },
    {
        id: "Ayşegül Yavuz",
        title: "Ayşegül Yavuz",
    },
    {
        id: "Berat Atalay",
        title: "Berat Atalay",
    },
    {
        id: "Beyza Korkmaz Doğan",
        title: "Beyza Korkmaz Doğan",
    },
    {
        id: "Cağtay Döner",
        title: "Cağtay Döner",
    },
    {
        id: "Cem Sel",
        title: "Cem Sel",
    },
    {
        id: "Cemil Yavuz",
        title: "Cemil Yavuz",
    },
    {
        id: "Cenkay Çakır",
        title: "Cenkay Çakır",
    },
    {
        id: "Cevat Aksoy",
        title: "Cevat Aksoy",
    },
    {
        id: "Cihan Çetin",
        title: "Cihan Çetin",
    },
    {
        id: "Coşkun Şenel",
        title: "Coşkun Şenel",
    },
    {
        id: "Emre Emet",
        title: "Emre Emet",
    },
    {
        id: "Ercan Çiğdem",
        title: "Ercan Çiğdem",
    },
    {
        id: "Erhan Özbay",
        title: "Erhan Özbay",
    },
    {
        id: "Esat Fişek",
        title: "Esat Fişek",
    },
    {
        id: "Fahrettin Özlen",
        title: "Fahrettin Özlen",
    },
    {
        id: "Ferhat Gögercin",
        title: "Ferhat Gögercin",
    },
    {
        id: "Hakan Sazak",
        title: "Hakan Sazak",
    },
    {
        id: "Hayrettin Bahar",
        title: "Hayrettin Bahar",
    },
    {
        id: "İbrahim Minder",
        title: "İbrahim Minder",
    },
    {
        id: "Kadir Gönül",
        title: "Kadir Gönül",
    },
    {
        id: "Mahir Bayram",
        title: "Mahir Bayram",
    },
    {
        id: "Mahmut Aktağ",
        title: "Mahmut Aktağ",
    },
    {
        id: "Melih Bahadır",
        title: "Melih Bahadır",
    },
    {
        id: "Melike Erol",
        title: "Melike Erol",
    },
    {
        id: "Merve Amuk",
        title: "Merve Amuk",
    },
    {
        id: "Mevlüt Yıldırım",
        title: "Mevlüt Yıldırım",
    },
    {
        id: "Murat İçen",
        title: "Murat İçen",
    },
    {
        id: "Okan Türkoğlu",
        title: "Okan Türkoğlu",
    },
    {
        id: "Ozan Demir",
        title: "Ozan Demir",
    },

    {
        id: "Oğuzhan Demirel",
        title: "Oğuzhan Demirel",
    },
    {
        id: "Ömer Bali",
        title: "Ömer Bali",
    },
    {
        id: "Özge Pişiren Türkoğlu",
        title: "Özge Pişiren Türkoğlu",
    },
    {
        id: "Sefa Elmas",
        title: "Sefa Elmas",
    },
    {
        id: "Serhat Kaplan",
        title: "Serhat Kaplan",
    },
    {
        id: "Serkan Çolakoğlu",
        title: "Serkan Çolakoğlu",
    },
    {
        id: "Şerif Ali Demirtaş",
        title: "Şerif Ali Demirtaş",
    },
    {
        id: "Şenol Vuruşankaplan",
        title: "Şenol Vuruşankaplan",
    },
    {
        id: "Uğur Ümit",
        title: "Uğur Ümit",
    },
    {
        id: "Vedat Doğru",
        title: "Vedat Doğru",
    },
    {
        id: "Vedat Gün",
        title: "Vedat Gün",
    },
    {
        id: "Yakup Sever",
        title: "Yakup Sever",
    },

    {
        id: "Yusufhan Doğan",
        title: "Yusufhan Doğan",
    },
];