import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button, Link, Chip, Tooltip
} from '@material-ui/core';
import {Visibility} from "@material-ui/icons";

const CustomerTable = ({ customers }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
          selectedCustomerIds.slice(0, selectedIndex),
          selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Kod</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Adı</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Lokasyon</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Yetkili Kişi Adı</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Yetkili Kişi Telefon</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Sipariş Sayısı</TableCell>
              <TableCell style={{fontWeight: 'bold'}} align="right">Siparişler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.slice(0, limit).map((customer) => (
                <TableRow hover key={customer.id} selected={selectedCustomerIds.indexOf(customer.id) !== -1}>
                  <TableCell padding="checkbox">
                    <Checkbox
                        checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                        onChange={(event) => handleSelectOne(event, customer.id)}
                        value="true"
                    />
                  </TableCell>
                  <TableCell><Link href={`/customers/${customer.code}`}>{customer.code}</Link></TableCell>
                  <TableCell>
                    <Link href={`/customers/${customer.code}`}>{customer.name}</Link>
                  </TableCell>
                  <TableCell>{customer.location}</TableCell>
                  <TableCell>{customer.authorizedPerson.name}</TableCell>
                  <TableCell>{customer.authorizedPerson.phone}</TableCell>
                  <TableCell><Chip size="small" variant="outlined" label={customer.order_count} color="secondary"/></TableCell>
                  <TableCell>
                    <Tooltip title="Müşteri Siparişilerini İncele" placement="top">
                      <Button variant="outlined" color="primary" size="small" href={`/orders/byCustomer/${customer.code}`}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        labelRowsPerPage="Sayfa başına göster:"
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerTable.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerTable;
