import {Box, Button, CardContent, CircularProgress, Container} from "@material-ui/core";
import Typography from "../../components/typography";
import React, {useEffect, useState} from "react";
import Card from "../../components/card";
import FormItem from "../../components/form-item";
import TextField from "../../components/text-field";
import {useForm} from "react-hook-form";
import Select from "../../components/select";
import DatePicker from "../../components/date-picker";
import {makeStyles} from "@material-ui/core/styles";
import {grey, red} from "@material-ui/core/colors";
import {yupResolver} from "@hookform/resolvers/yup";
import {contractCreateSchema} from "./forms/validation";
import {format} from 'date-fns';
import useCustomFetch from "../../hooks/use-fetch";
import Popup from "../../components/popup";
import {useRouter} from "../../hooks/use-router";
import {getCurrentUser} from "../../utils";

export default function StoreRelationCreate(){

    const currentUser = getCurrentUser();

    const supplier_id = currentUser.profile?.supplier_id;
    const [storeList, setStoreList] = useState([])
    const [loading, setLoading] = useState(true)
    const [supplier, setSupplier] = useState(null)
    const [supplierCanCreateRelation, setSupplierCanCreateRelation] = useState(false)
    const [exportRateEnabled, setExportRateEnabled] = useState(false)

    const useStyles = makeStyles(()=>({
        fieldLabel: {
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles();
    const router = useRouter();
    const { post, get, response,  } = useCustomFetch();

    const {control, handleSubmit, setValue } = useForm({resolver: yupResolver(contractCreateSchema), defaultValues: {}});



    const handleStoreChange = (selectedStore)=>{
        if (!selectedStore){
            setValue("exportRate", "")
            setExportRateEnabled(false);
            return;
        }
        let store = storeList.find(s => s.id === selectedStore.id);
        if (store.countryId !== parseInt(supplier.location.countryId)){
            setValue("exportRate", supplier.money.exportRate)
            setExportRateEnabled(true);
        }else{
            setValue("exportRate", 0)
            setExportRateEnabled(false);
        }
        setValue("storeId", selectedStore.id);
    }

    const onFormSubmit = async (values)=>{

        let {startDate, endDate, ...newData} = values;

        let data = {
            ...newData
        };
        data['startDate'] = format(startDate, 'yyyy-MM-dd');

        if (endDate){
            data['endDate'] = format(endDate, 'yyyy-MM-dd');
        }else{
            data['endDate'] = null;
        }

        await Popup.fire({
            title: "Ekleniyor",
            text: "Lütfen bekleyin",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Popup.showLoading();
                await post("/supplier-service/storecontracts", data)
                Popup.close();
                if (response.ok){
                    Popup.fire({
                        icon: "success",
                        title: "Eklendi",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(()=>{
                        router.push('/store-relations?r=1');
                    })
                }else{
                    Popup.fire({
                        icon: "error",
                        title: "Eklenemedi!",
                        text: "Lütfen bilgilerinizi kontrol edin.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        timer: 3000,
                    }).then(()=>{
                        Popup.close();
                    })
                }
            }
        });
    }

    const onFormError = (errors)=>{
        console.log(errors)
    }

    const getStoreContractStoreIds = async ()=>{
        return await get('/supplier-service/storecontracts/storeids');
    }

    const getContractableStores = async (storeIds)=>{
        return await post('/store-service/stores/contractables', storeIds);
    }

    const getSupplier  = async () => {
        return await get(`/supplier-service/suppliers/GetById/${supplier_id}?_${Date.now()}`);
    }


    useEffect(()=>{
        getSupplier().then((response)=>{
            setSupplier(response);
            setSupplierCanCreateRelation(response.money !== null && response.money.currency !== null);
            getStoreContractStoreIds().then(({storeIds})=>{
                getContractableStores(storeIds).then((response) => {
                    setStoreList(response.map((r) => {
                        return {
                            id: r.id,
                            title: `${r.brand} - ${r.location}`,
                            countryId: r.countryId,
                        };
                    }));
                    setLoading(false);
                })
            })
        })

    }, [])

    return (
        loading
        ?
        <Box textAlign={"center"}>
            <Box><CircularProgress /></Box>
            <Box>Yükleniyor, lütfen bekleyin...</Box>
        </Box>
        :
        <Container maxWidth={"sm"}>
            <Box mb={2} textAlign={"center"}>
                <Typography variant="h4">Yeni Mağaza İlişkisi Oluştur</Typography>
                <Typography variant="subtitle1">Ürünlerinizin satışa sunulacağı bir mağaza bulun ve ilişki kurun.</Typography>
            </Box>
            {
                (!supplierCanCreateRelation) && (
                    <Box style={{textAlign: 'center'}}>
                        <Typography variant="subtitle2" style={{color: red[600]}}>Lütfen ilk önce ana para biriminizi ve oranlarınızı ayarlayın.</Typography>
                        <Button href="/company" variant={"outlined"} color={"primary"} style={{marginTop: 10}}>FİRMA BİLGİLERİNİZİ DÜZENLEYİN</Button>
                    </Box>
                )
            }
            {
                supplierCanCreateRelation && storeList.length === 0 && (
                    <Box style={{textAlign: 'center'}}>
                        <Typography variant="subtitle2" style={{color: red[400]}}>Mevcut mağazalarla zaten ilişki kurmuşsunuz ya da henüz ürünlerinizi satışa sunacağınız bir mağaza mevcut değil.</Typography>
                        <Button href="/store-relations" variant={"outlined"} color={"secondary"} style={{marginTop: 10}}>MAĞAZA İLİŞKİLERİNE GERİ DÖNÜN</Button>
                    </Box>
                )
            }
            {
                supplierCanCreateRelation && storeList.length > 0 && (
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
                                <label className={classes.fieldLabel}>Ürünlerinizi satın almaya hazır mağazalar arasından seçim yapın.</label>
                                <FormItem control={control} name="storeId" onChange={handleStoreChange}>
                                    <Select label="Mağaza" options={storeList} displayMember="title" />
                                </FormItem>
                                <hr/>
                                <label className={classes.fieldLabel}>Tarihlerinizi belirleyin.</label>
                                <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Ürünleriniz belirlediğiniz tarihler aralığında aktif olarak mağazada satışa sunulacaktır.</p>
                                <FormItem control={control} name="startDate">
                                    <DatePicker label={"Başlangıç Tarihi"} />
                                </FormItem>
                                <FormItem control={control} name="endDate">
                                    <DatePicker label={"Bitiş Tarihi"} />
                                </FormItem>
                                <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Bitiş tarihini boş bırakırsanız ilişki süresiz olarak tanımlanacaktır.</p>
                                <hr/>
                                <label className={classes.fieldLabel}>Oranlarınızı belirleyin.</label>
                                <FormItem control={control} name="discountRate" defaultValue={""}>
                                    <TextField label="İskonto Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                                </FormItem>
                                <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>İskonto yapmak istemiyorsanız <b>0</b> olarak girebilirsiniz.</p>
                                {
                                    exportRateEnabled && (
                                        <div>
                                            <FormItem control={control} name="exportRate" defaultValue={""}>
                                                <TextField label="İhracat Fiyat Farkı Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                                            </FormItem>
                                            <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Genel ihracat fiyat farkı oranınız: <b>{supplier.money.exportRate}</b>.</p>
                                        </div>
                                    )
                                }
                                <FormItem control={control} name="minimumSalesRate" defaultValue={supplier.money.storeMinimumSalesRate}>
                                    <TextField label="Minimum Mağaza Satış Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                                </FormItem>
                                <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Genel minimum mağaza satış oranınız: <b>{supplier.money.storeMinimumSalesRate}</b>.</p>

                                <Button style={{marginTop: 16}} size={"large"} variant={"contained"} fullWidth color={"primary"} type="submit">MAĞAZA İLİŞKİSİ OLUŞTUR</Button>
                            </form>
                        </CardContent>
                    </Card>
                )
            }
        </Container>
    )
}