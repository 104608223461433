import {Box, CircularProgress, Dialog, DialogContent, IconButton, Typography} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Close, Print} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {getValueFromTranslation} from "../../utils";
import useCustomFetch from "../../hooks/use-fetch";
import MuiTextField from "@material-ui/core/TextField";
import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiFormControl from "@material-ui/core/FormControl";
import Select from "../../components/select";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        borderBottom: `1px solid #046393`,
        color: "#046393",
        backgroundColor: "#fff"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const PreProductFormDialog = ({onClose}) =>{

    const classes = useStyles();
    const {get, response} = useCustomFetch();
    const [isLoading, setIsLoading] = useState(true);
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [lang, setLang] = useState('tr');
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(undefined);
    const [categoryFeatures, setCategoryFeatures] = useState([]);


    const [isModule, setIsModule] = useState(false);
    const [moduleCount, setModuleCount] = useState(0);
    const [modules, setModules] = useState([]);

    async function getCategories() {
        const data = await get("/product-service/categories");
        if (response.ok && data) {
            let parents = data.filter((cat) => cat.parentId === null && cat.suggested === false)
            let suggesteds = data.filter((cat) => cat.parentId === null && cat.suggested === true)
            let childrens = data.filter((cat) => cat.parentId !== null)

            let tree = [];
            parents.map((parent) => {
                let trs = getValueFromTranslation(parent.translations, "title");
                let chs = childrens
                    .filter((child) => child.parentId === parent.id)
                    .map((ch) => ({
                        groupName: trs,
                        id: ch.id,
                        title: getValueFromTranslation(ch.translations, "title"),
                        isModule: ch.isModule,
                    }))
                tree.push(...chs)
                return parent;
            });
            suggesteds.map((sugg)=> {
                let item =  {
                    groupName: 'Onay Bekleyen',
                    id: sugg.id,
                    title: getValueFromTranslation(sugg.translations, "title"),
                    isModule: sugg.isModule,
                }
                tree.push(item)
                return sugg;
            })
            setCategories(tree);
        }
    }

    async function handleCategoryOnChange(cat) {
        setIsCategoryLoading(true)
        setCategory(undefined);
        if(!cat){
            setIsCategoryLoading(false)
            return;
        }

        // find category
        let category = categories.find((category) => category.id === cat.id);

        if (!category){
            setIsCategoryLoading(false)
            return;
        }


        // check category is modular || may be user is not check is module
        setIsModule(category.isModule);

        if (category.isModule){
            setModulesArray(1);
        }else{
            setModulesArray(0);
        }

        // get/set features
        // get/set modules

        const data = await get(`/product-service/categories/${category.id}`);
        if (response.ok) {
            if (data.features && data.features.length){
                setCategoryFeatures(data.features.filter(f => f.suggested === false));
            }
        }

        setCategory(category);
        setIsCategoryLoading(false)
    }

    const setModulesArray = (count)=>{
        if (count <= 0){
            setModules([]);
            setModuleCount(0);
            return;
        }

        let modulesArray = [];
        for (let i = 1; i <= count; i++){
            modulesArray.push(i);
        }
        setModules(modulesArray);
        setModuleCount(count);
    }

    useEffect(() => {
        if(!isLoading){ return }
        getCategories()
            .then(()=>{
                setIsLoading(false)
            });
    }, [isLoading]);



    return (
        <Dialog fullScreen open={true}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" title={'Kapat'}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6"  className={classes.title}>
                        Ürün Hazırlama Formu
                    </Typography>
                    {
                        !isLoading && (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 16}}>
                                    {/*
                                    <strong style={{marginRight: 8}}>Ürün Kategorisi:</strong>
                                    <select style={{color: '#fff', background: 'none', border: 'none'}} onChange={(e) => handleCategoryOnChange(e.target.value)}>
                                        <option style={{color: '#000'}} value="">Seçiniz</option>
                                        {categories.map((category) => (<option style={{color: '#000'}} value={category.id}>{category.title}</option>))}
                                    </select>
                                    */}
                                    <div style={{width: '400px'}}>
                                        <Select groupBy={(category) => category.groupName} id="categoryId" label="Ürün Kategorisi" options={categories} displayMember="title" onChange={handleCategoryOnChange} />
                                    </div>
                                </div>
                                {
                                    category && (category.isModule || isModule) && (
                                        <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 16}}>
                                            <strong style={{marginRight: 8}}>Modül Sayısı:</strong>
                                            <select style={{fontWeight: 'bold',color: '#046393', background: 'none', border: 'none'}} value={moduleCount} onChange={(e) => setModulesArray(parseInt(e.target.value))}>
                                                <option style={{color: '#000'}} value="0">Seçiniz</option>
                                                <option style={{color: '#000'}} value="1">1</option>
                                                <option style={{color: '#000'}} value="2">2</option>
                                                <option style={{color: '#000'}} value="3">3</option>
                                                <option style={{color: '#000'}} value="4">4</option>
                                                <option style={{color: '#000'}} value="5">5</option>
                                                <option style={{color: '#000'}} value="6">6</option>
                                                <option style={{color: '#000'}} value="7">7</option>
                                                <option style={{color: '#000'}} value="8">8</option>
                                                <option style={{color: '#000'}} value="9">9</option>
                                                <option style={{color: '#000'}} value="10">10</option>
                                                <option style={{color: '#000'}} value="11">11</option>
                                                <option style={{color: '#000'}} value="12">12</option>
                                                <option style={{color: '#000'}} value="13">13</option>
                                                <option style={{color: '#000'}} value="14">14</option>
                                                <option style={{color: '#000'}} value="15">15</option>
                                                <option style={{color: '#000'}} value="16">16</option>
                                                <option style={{color: '#000'}} value="17">17</option>
                                                <option style={{color: '#000'}} value="18">18</option>
                                                <option style={{color: '#000'}} value="19">19</option>
                                                <option style={{color: '#000'}} value="20">20</option>
                                            </select>
                                        </div>
                                    )
                                }
                                {
                                    /*
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 16}}>
                                        <strong style={{marginRight: 8}}>Dil:</strong>
                                        <select style={{color: '#fff', background: 'none', border: 'none'}} onChange={(e) => setLang(e.target.value)}>
                                            <option style={{color: '#000'}} value="tr">TR</option>
                                            <option style={{color: '#000'}} value="en">EN</option>
                                        </select>
                                    </div>
                                    */
                                }


                                <IconButton edge="start" color="inherit" onClick={() => {
                                    window.print()
                                }} aria-label="close" title={'Yazdır'}>
                                    <Print/>
                                </IconButton>
                            </div>
                        )
                    }

                </Toolbar>
            </AppBar>
            <DialogContent id={"DialogPrint"}>
                {
                    isLoading
                        ?
                        <Box textAlign={"center"}>
                            <Box><CircularProgress/></Box>
                            <br/>
                            <Box>Yükleniyor, lütfen bekleyin...</Box>
                        </Box>
                        :
                        (
                            <div id={'DialogPrintContent'} data-lang={lang} data-min-page={2} data-pagination-message={'Bu doküman :total sayfadan oluşmaktadır.'}>
                                {
                                    category
                                        ?
                                        <div>
                                            <div style={{marginBottom: '1rem', width: '100%', display: 'flex',justifyContent:'space-between'}}><strong style={{textAlign: 'left'}}>ÜRÜN BİLGİLERİ FORMU</strong><strong style={{textAlign: 'right'}}>.................................................... {category.title.toLocaleUpperCase(lang)}</strong></div>
                                            {
                                                categoryFeatures.length > 0
                                                    ?
                                                    (
                                                        <div className={'print-first-page'} style={{pageBreakAfter: 'always'}}>
                                                            <div style={{marginBottom: '1rem'}}>
                                                                <div style={{fontWeight: 'bold', fontSize: 16}}>Ürün Özellikleri</div>
                                                                <div style={{color: '#858585'}}>Ürünün tasarım ve teknik özelliklerini tanımlayın.</div>
                                                            </div>
                                                            <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', pageBreakAfter: 'always'}}>
                                                                {
                                                                    categoryFeatures.map((feature, featureIndex) => (
                                                                        <div key={featureIndex}>
                                                                            <div style={{marginBottom: 5, borderBottom: '2px solid #eee'}}>
                                                                                <div style={{fontWeight: 'bold', fontSize: 13}}>
                                                                                    {getValueFromTranslation(feature.feature.translations, "value", lang)}
                                                                                </div>
                                                                                <div style={{color: '#ddd', padding: '5px 0', lineHeight: '14px', height: '30px'}}>
                                                                                    Örn: {Array.from(feature.optionTranslations).slice(0, 2).map((opt) => opt.value).join(', ')} vb.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                <div key='extra-features' style={{gridColumn: '2 span'}}>
                                                                    <div style={{marginBottom: 5, paddingBottom: 5}}>
                                                                        <div style={{fontWeight: 'bold', fontSize: 13}}>Ekstra Özellikler</div>
                                                                        <div style={{color: '#ddd', padding: '5px 0'}}>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <p>Bu kategori için öntanımlı özellik mevcut değil.</p>
                                                    )
                                            }
                                            {
                                                category.isModule && modules.length > 0
                                                    ?
                                                    (
                                                        <div>
                                                            <div style={{margin: '.5rem 0'}}>
                                                                <div style={{fontWeight: 'bold', fontSize: 16}}>Modüller</div>
                                                                <div style={{color: '#858585'}}>Ürünün modüllerini tanımlayın.</div>
                                                            </div>
                                                            {
                                                                modules.map((m) => (
                                                                    <div style={{
                                                                        pageBreakAfter: m % 2 === 0 ? 'always' : 'avoid',
                                                                        display: 'grid',
                                                                        gridTemplateColumns: 'repeat(6, 1fr)',
                                                                        gap: '8px',
                                                                        borderBottom: '2px solid rgb(217 217 217)',
                                                                        paddingBottom: '.5rem',
                                                                        margin: '.5rem 0'
                                                                    }}>
                                                                        <strong style={{margin: '.5rem 0 0',gridColumn: '6 span', textAlign: 'left'}}>Modül #{m}</strong>
                                                                        <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Modül Adı:`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Modül Fiyatı (Türk Lirası TRY):`} type="text"  disabled={true}/>
                                                                        <MuiFormControl fullWidth={true} style={{gridColumn: '3 span'}} margin={'dense'} disabled={true}>
                                                                            <MuiFormControlLabel
                                                                                control={
                                                                                    <MuiCheckbox
                                                                                        size={'medium'}
                                                                                        color={'primary'}

                                                                                        name="checkbox"
                                                                                    />
                                                                                }
                                                                                label={'Modül takım içeriğinde standart mı?'}
                                                                                labelPlacement={'end'}
                                                                            />
                                                                        </MuiFormControl>
                                                                        <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Takım içeriğindeki standart adeti:`} type="text"  disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Genişlik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Yükseklik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Derinlik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Adedi:`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Ağırlık (kg):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Desi:`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Genişlik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Yükseklik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Derinlik (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Genişliği (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Yüksekliği (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Derinliği (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Ayak Yüksekliği (cm):`} type="text" disabled={true}/>
                                                                        <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Ayak Çapı (cm):`} type="text" disabled={true}/>
                                                                    </div>
                                                                ))
                                                            }


                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div>
                                                            <div style={{margin: '.5rem 0'}}>
                                                                <div style={{fontWeight: 'bold', fontSize: 16}}>Fiyat ve Ölçüler</div>
                                                                <div style={{color: '#858585'}}>Ürünün fiyatını ve ölçülerini tanımlayın.</div>
                                                            </div>
                                                            <div style={{
                                                                display: 'grid',
                                                                gridTemplateColumns: 'repeat(6, 1fr)',
                                                                gap: '8px',
                                                            }}>
                                                                <MuiTextField style={{gridColumn: '6 span'}} variant="outlined" margin="dense" label={`Fiyatı (Türk Lirası TRY):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Genişlik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Yükseklik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Derinlik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Adedi:`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Ağırlık (kg):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Paketleme Desi:`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Genişlik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Yükseklik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Açılır Derinlik (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Genişliği (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Yüksekliği (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '2 span'}} variant="outlined" margin="dense" label={`Oturum Derinliği (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Ayak Yüksekliği (cm):`} type="text" disabled={true}/>
                                                                <MuiTextField style={{gridColumn: '3 span'}} variant="outlined" margin="dense" label={`Ayak Çapı (cm):`} type="text" disabled={true}/>
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                        :
                                        <div>
                                            {
                                                isCategoryLoading ? (<p>Ürün için gerekli bilgileri getiriyoruz, lütfen bekleyin...</p>) : (<p>Lütfen sağ üst kısımdam ürün kategorisini seçin.</p>)
                                            }
                                        </div>
                                }
                            </div>
                        )
                }
            </DialogContent>
        </Dialog>
    );
};

PreProductFormDialog.defaultProps = {
    onClose: () => {
    },
}

PreProductFormDialog.propTypes = {
    onClose: PropTypes.func,
}

export default PreProductFormDialog;