import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, FormControl, FormLabel, IconButton, Switch, TextField, Typography} from "@material-ui/core";
import {Add, Close, Print} from "@material-ui/icons";
import Grid from "components/grid";
import Select from "components/select";
import ProductTable from "./ProductTable";
import useCustomFetch from "hooks/use-fetch"
import {getValueFromTranslation} from "../../../utils";
import sampleProducts from './data';
import envConfig from '../../../config/env.config'
import {common, green, purple} from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";

import debounce from 'lodash.debounce';
import PriceListDialog from "../PriceListDialog";
import PreProductFormDialog from "../PreProductFormDialog";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        borderBottom: `1px solid #046393`,
        backgroundColor: "#046393",
        color: "#fff"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function Products(props) {
    const [loading, setLoading] = useState(true);
    const [isDemo, setIsDemo] = useState(false);
    const categoriesSelectList = useRef([]);

    const [productsTableData, setProductsTableData] = useState([]);
    const [total, setTotal] = useState(0);

    const [modelNameInput, setModelNameInput] = useState('');

    const {get, post, response} = useCustomFetch();

    // Preview states
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');

    // Price list states
    const [showPriceDialog, setShowPriceDialog] = useState(false);
    const [priceDialogProductId, setPriceDialogProductId] = useState(undefined);
    const [showPreProductFormDialog, setShowPreProductFormDialog] = useState(false);


    const defaultFilterState = {
        limit: 10,
        page: 1,
        modelName: null,
        categoryId: null,
        active: null,
        isArchived: false,
    };

    const [filterForm, setFilterForm] = useState(defaultFilterState);

    const resetFilterForm = ()=>{
        setModelNameInput('');
        setFilterForm(defaultFilterState);
    }

    const filterByModelName = (e) => {
        let value = e.target.value;

        setModelNameInput(value);

        if (value.length <= 2){
            if (filterForm.modelName != null){
                setFilterForm((prevState) => ({...prevState, modelName: null}))
            }
            return false;
        }

        setFilterForm((prevState) => ({...prevState, modelName: value}))
    }

    const handleLimitChange = (newLimit) => {
        setFilterForm((prevState) => ({...prevState, limit: newLimit, page: 1}));
    };

    const handlePageChange = (newPage) => {
        // table pagination starts with zero, so we add +1 for page number.
        setFilterForm((prevState) => ({...prevState, page: newPage + 1 }));
    };
    const classes = useStyles()

    const getCategories = async () => {
        let data = await get('/product-service/categories');
        if (response.ok && data.length > 0) {
            let parents = data.filter((cat) => cat.parentId === null && cat.suggested === false)
            let suggesteds = data.filter((cat) => cat.parentId === null && cat.suggested === true)
            let childrens = data.filter((cat) => cat.parentId !== null)

            let tree = [];
            parents.map((parent) => {
                let trs = getValueFromTranslation(parent.translations, "title");
                let chs = childrens.filter((child) => child.parentId === parent.id).map((ch) => {
                    return {
                        groupName: trs,
                        id: ch.id,
                        title: getValueFromTranslation(ch.translations, "title"),
                    }
                })
                tree.push(...chs)
                return parent;
            });
            suggesteds.map((sugg)=> {
                let item =  {
                    groupName: 'Onay Bekleyen',
                    id: sugg.id,
                    title: getValueFromTranslation(sugg.translations, "title"),
                }
                tree.push(item)
                return sugg;
            });
            categoriesSelectList.current = tree;
        }
    }

    const getProducts = async () => {
        let request = await post("/product-service/products/listing", filterForm)
        if (response.ok && request.data.length > 0) {
            let products = request.data.map(element => {
                return {
                    ...element,
                    sku: (element.id.substring(0, 8)).toLocaleUpperCase(),
                    category: categoriesSelectList.current.find((c) => c.id === element.categoryId)?.title,
                };
            });
            setIsDemo(false)
            setTotal(request.total);
            setProductsTableData(products);
        } else {
            setIsDemo(true)
            setProductsTableData(sampleProducts);
        }
    };

    const showPreviewFrame = (product) => {
        let url = `${envConfig.PRODUCT_PREVIEW_URL}${product.id}`;
        setPreviewUrl(url)
        setShowPreview(true);
    }

    const closePreviewFrame = ()=>{
        setShowPreview(false);
        setPreviewUrl('');
    }

    const showPriceListDialog = async (productId) => {
        setPriceDialogProductId(productId);
        setShowPriceDialog(true);
        return false;
    }

    const closePriceListDialog = ()=>{
        setShowPriceDialog(false);
        setPriceDialogProductId(undefined);
    }

    const closePreProductFormDialog = ()=>{
        setShowPreProductFormDialog(false);
    }



    useEffect(() => {
        if (!loading) {
            return;
        }
        getCategories().then(() => {
            setLoading(false)
        })

    }, [loading]);

    const debouncedGetProducts = debounce(() => {
        getProducts()
    }, 500);

    useEffect(() => {
        debouncedGetProducts();
    }, [filterForm]);

    return (
        loading
            ?
            <>
                <Box textAlign={"center"}>
                    <Box><CircularProgress/></Box>
                    <br/>
                    <Box>Ürünleriniz yükleniyor, lütfen bekleyin...</Box>
                </Box>
            </>
            :
            <>
                <Container maxWidth="lg">
                    <Box mb={2} display={"flex"} alignItems={"center"} alignContent={"center"}
                         justifyContent={"space-between"}>
                        <Typography variant="h4">Ürünler</Typography>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button href="/products/create" style={{color: common.white, backgroundColor: green[700] }} variant="contained" size={"small"}><Add/> ÜRÜN EKLE</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={()=>{setShowPreProductFormDialog(true);}} style={{color: common.white, backgroundColor: purple[400] }} variant="contained" size={"small"}><Print/>&nbsp;&nbsp;ÜRÜN HAZIRLAMA FORMU OLUŞTUR</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3} style={{alignItems: 'center'}}>
                                <Grid item xs={12} sm={3}>
                                    <TextField value={modelNameInput} onChange={filterByModelName} margin={"dense"} variant="outlined" fullWidth size="small" placeholder="Ürün adı"/>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <Select onChange={(value)=> { setFilterForm((prevState) => ({...prevState, categoryId: (value && value.id) || null }))  }} variant="outlined" size="small" fullWidth
                                                groupBy={(category) => category.groupName} id="categoryId" label="Kategori"
                                                options={categoriesSelectList.current} displayMember="title"/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2} style={{textAlign: 'center'}}>
                                    <FormLabel>Aktif?</FormLabel>
                                    <Switch checked={filterForm.active} onChange={()=> { setFilterForm((prevState) => ({...prevState, active: !prevState.active })) }} color="primary"/>
                                </Grid>
                                <Grid item xs={12} sm={2} style={{textAlign: 'center'}}>
                                    <FormLabel>Arşivlenmiş?</FormLabel>
                                    <Switch checked={filterForm.isArchived} onChange={()=> { setFilterForm((prevState) => ({...prevState, isArchived: !prevState.isArchived })) }} color="primary"/>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <Button variant={"outlined"} color={"secondary"} onClick={()=> { resetFilterForm() }}>SIFIRLA</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box style={{marginTop: 10, position: 'relative'}}>
                        <Box style={{
                            display: isDemo ? 'flex' : 'none',
                            background: '#fff',
                            opacity: 0.8,
                            zIndex: 3,
                            position: 'absolute',
                            right: 0,
                            left: 0,
                            top: 0,
                            bottom: 0,
                            height: '100%'
                        }}/>
                        <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"}
                             flexDirection={"column"} display={"flex"} style={{
                            display: isDemo ? 'flex' : 'none',
                            zIndex: 4,
                            position: 'absolute',
                            right: 0,
                            left: 0,
                            top: 0,
                            bottom: 0
                        }}>
                            <Typography variant="h6" style={{color: '#383838'}}>Aradığınız kriterlerde bir ürün bulunamadı.</Typography>
                            <Button onClick={resetFilterForm} color={"secondary"} variant="contained" size={"small"}>ARAMAYI SIFIRLAYIN</Button>
                        </Box>
                        <ProductTable products={productsTableData} onShowPriceListDialog={showPriceListDialog} onPreview={showPreviewFrame} onRefresh={getProducts} total={total} page={filterForm.page-1} setPage={handlePageChange} limit={filterForm.limit} setLimit={handleLimitChange}/>
                    </Box>
                </Container>
                { showPreview && (
                    <Dialog fullScreen open={showPreview}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={closePreviewFrame} aria-label="close">
                                    <Close />
                                </IconButton>
                                <Typography variant="h6"  className={classes.title}>
                                    Ürün Önizleme
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <iframe frameBorder={0} width="100%" height="90%" src={previewUrl} title={"Ürün Önizleme"}/>
                        </DialogContent>
                    </Dialog>
                )
                }
                { showPriceDialog && priceDialogProductId !== undefined && (<PriceListDialog productId={priceDialogProductId} onClose={closePriceListDialog}/>)}
                { showPreProductFormDialog && (<PreProductFormDialog onClose={closePreProductFormDialog}/>)}
            </>
    );
}