import React, {useEffect, useRef, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup"
import {Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Tab, Tabs, Typography} from "@material-ui/core";
import {Add, CloudDownload, DeleteForever, Description, DragHandle} from "@material-ui/icons";
import {green, red} from "@material-ui/core/colors";
import Paper from "components/paper";
import TextField from "components/text-field";
import FormItem from "components/form-item";
import Select from "components/select";
import Checkbox from "components/checkbox";
import Uploader from "components/uploader";
import TabPanel from "components/tab/tabpanel";

import {getFeatureComponent} from "../form/computedComponents";
import {generateImageURL, generateOriginalImageUrl, getCurrentUser, getValueFromTranslation, removeSpaces} from "../../../utils";

import envConfig from "../../../config/env.config";
import {tabs} from '../form/tabs'
import {designerList} from '../form/designersData'
import {createSchema} from '../form/validation'
import ProductModule from "../containers/module";
import ProductSingle from "../containers/single";
import useCustomFetch from "hooks/use-fetch";
import {useRouter} from "../../../hooks/use-router";
import Popup from "../../../components/popup";
import ImageComponent from "material-ui-image";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";

const useStyles = makeStyles(() => ({
    MuiTab: {
        '@media (min-width: 0px)': {
            minWidth: '130px'
        }
    },
}));

export default function ProductCreate() {
    const classes = useStyles();
    const user = getCurrentUser();
    const { post, get, response,  } = useCustomFetch();
    const router = useRouter();
    const [loading, setLoading] = useState(true);
    const supplier = useRef(null);
    const [selectedTabs, setSelectedTabs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allFeatures, setAllFeatures] = useState([]);
    const [selectFeatures, setSelectFeatures] = useState([]);
    const [featureFieldTypes, setFeatureFieldTypes] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [originalCategories, setOriginalCategories] = useState([]);
    const [categoryFeatures, setCategoryFeatures] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [videoProviders] = useState([{ id: 'youtube', title: 'Youtube' }, { id: 'vimeo', title: 'Vimeo' }]);


    /* Form */
    const { control, handleSubmit, watch, reset, getValues, trigger, formState, setValue } = useForm({
        resolver: yupResolver(createSchema),
    });
    const { append: appendFeature, remove: removeFeature } = useFieldArray({ control, name: "features", keyName: "product_feature_id" });
    const { fields: fieldsModule, append: appendModule, remove: removeModule, } = useFieldArray({ control, name: "modules", keyName: "product_module_id" });
    const { fields: fieldsImage, append: appendImage, remove: removeImage, move: fieldsImageMove } = useFieldArray({ control, name: "images", keyName: "product_image_id" });
    const { fields: fieldsVideo, append: appendVideo, remove: removeVideo, } = useFieldArray({ control, name: "videos", keyName: "product_video_id" });
    const { fields: fieldsDocument, append: appendDocument, remove: removeDocument, } = useFieldArray({ control, name: "documents", keyName: "product_document_id" });

    const watchModelName = watch("modelName", "");
    const watchCategory = watch("categoryId", null);
    const watchIsModule = watch("isModule", true);

    const getProductName = () => {
        const category = categories.find((item) => item.id === watchCategory) || { title: watchCategory };
        return `${watchModelName || ""} ${category.title || ""}`;
    };
    const [activeTab, setActiveTab] = React.useState(0);

    const onFormSubmit = async (values) => {
        let postValues = preparePostValues(values);
        await Popup.fire({
            title: "Ürün ekleniyor",
            text: "Lütfen bekleyin",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Popup.showLoading();
                await post("/product-service/products", postValues)
                Popup.close();
                if (response.ok){
                    Popup.fire({
                        icon: "success",
                        title: "Ürün eklendi",
                        text: "Eklediğiniz ürün editörlerimiz tarafından incelendikten sonra aktif edilebilecektir. Ürün listesine yönlendiriliyorsunuz...",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(()=>{
                        router.push('/products');
                    })
                }else{
                    Popup.fire({
                        icon: "error",
                        title: "Ürün eklenemedi",
                        text: "Ürün bilgilerini kontrol edin.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        timer: 3000,
                    }).then(()=>{
                        Popup.close();
                    })
                }
            }
        })
        //TODO: Handle post request here.
    }
    const onFormError = async (errors) => {
        console.log(errors)
    }

    const preparePostValues = (values) => {
        if (values.modules.length){
            values.modules = values.modules.map((module)=>{
                module.price = {
                    currency: 'try',
                    quantity: module.price
                }
                return module;
            })
        }
        if (values.single && values.single.price){
            values.single.price = {
                currency: 'try',
                quantity: values.single.price
            }
        }
        return values;
    }

    const handleChange = async (event, newValue) => {
        /* If next, check fields */
        if (newValue > activeTab && !await isFormValid()) {
            return false;
        }
        /* Prevent skipping multiple tabs */
        if (newValue > activeTab && newValue - activeTab > 1){
            return false;
        }
        setActiveTab(newValue);
    };

    const isFormValid = async () => {
        let activeFields = selectedTabs[activeTab].fields;
        await trigger(activeFields);
        return activeFields
            .map((field) => formState.errors[field])
            .filter((item) => item !== undefined).length === 0
    }

    const goNext = async () => {
        if (!await isFormValid()) {
            return false;
        }
        if (activeTab < selectedTabs.length - 1) {
            await reset(getValues());
            setActiveTab(activeTab + 1)
        }
    }
    const goBack = async () => {
        if (activeTab > 0) {
            await reset(getValues());
            setActiveTab(activeTab - 1)
        }
    }

    const getImageUploadParams = () => {
        return {
            headers: { "Authorization": `Bearer ${user.access_token}` },
            url: `${envConfig.API_URL}/asset-service/upload/image`
        };
    };

    const getDocumentUploadParams = () => {
        return {
            headers: { "Authorization": `Bearer ${user.access_token}` },
            url: `${envConfig.API_URL}/asset-service/upload/document`
        };
    };

    /**
     * @todo CANCEL MECHANISM WILL BE DEVELOPED
     * @param {*} param0
     * @param {*} status
     */
    const handleImages = ({ meta, file, xhr, remove }, status) => {
        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            appendImage(response, response.id)
            remove(); // Remove from upload list for hide previews.
        }
    }

    const DragHandler = SortableHandle(() => (
        <ListItemIcon title="Görselin sırasını değiştirebilirsiniz.">
            <DragHandle color="primary" fontSize="large" cursor="pointer" />
        </ListItemIcon>
    ));

    const SortableImageListContainer = SortableContainer(({ items }) => {
        return (
            <List component="div">
                {items.map((image, index) => (<SortableImageItem key={index} index={index} image={image} imageIndex={index} />))}
            </List>
        )
    });

    const SortableImageItem = SortableElement(({image, imageIndex}) => {
        const aspectRatio = image.width / image.height;
        return (
            <ListItem key={imageIndex} ContainerComponent="div">
                <ListItemAvatar>
                    <Link href={generateImageURL(image, 800)} target="_blank">
                        <ImageComponent cover={true} aspectRatio={aspectRatio} style={{width: 200}} src={generateImageURL(image, 200)}/>
                    </Link>
                </ListItemAvatar>
                <ListItemText secondary={`Görsel #${imageIndex+1}`}  style={{marginLeft: 10}}  />
                <DragHandler />
                <Button style={{color: green[600]}} href={generateOriginalImageUrl(image)} target="_blank"><CloudDownload/></Button>
                <Button onClick={() => { removeImage(imageIndex) }}><DeleteForever color="secondary" /></Button>
            </ListItem>
        )
    });

    const onImagesSortEnd = ({ oldIndex, newIndex }) => {
        fieldsImageMove(oldIndex, newIndex)
    };

    /**
     * @todo CANCEL MECHANISM WILL BE DEVELOPED
     * @param {*} param0
     * @param {*} status
     */
    const handleDocuments = ({ meta, file, xhr, remove }, status) => {
        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            appendDocument(response, response.id)
            remove(); // Remove from upload list for hide previews.
        }
    };

    function setDefaultFeaturesForNewCategory(){
        let defaultFeatures = allFeatures.filter(f => {
            let feat1 = f.translations.filter(t => t.lang === 'tr' && t.value === 'Ürün Tarzı') ;
            let feat2 = f.translations.filter(t => t.lang === 'tr' && t.value === 'Ürün Rengi') ;
            return feat1.length > 0 || feat2.length > 0;
        });

        let newCategoryFeatures = defaultFeatures.map((feat, index)=>{
            return {
                feature: {
                    id: feat.id,
                    active: feat.active,
                    featureFieldType: featureFieldTypes.find(ft => ft.id === feat.fieldTypeId),
                    fieldTypeId: feat.fieldTypeId,
                    suggested: false,
                    translations: feat.translations,
                },
                featureId: feat.id,
                optionTranslations: [],
                options: [],
                ordering: feat.translations.find(t => t.lang === 'tr' && t.value === 'Ürün Tarzı') ? 0 : index + 1,
                suggested: false,
            }
        }).sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        setCategoryFeatures(newCategoryFeatures.map(({ feature, options, suggested }) => ({ feature, options, suggested })));
        appendFeature(newCategoryFeatures.map((item) => ({ featureId: item.featureId, suggested: item.suggested, values: [] })));
    }

    async function handleCategoriesOnChange(cat) {
        if(!cat){
            return;
        }
        let categoryId = cat.id;
        setCategoryFeatures([]);
        const values = getValues();
        reset({ ...values, features: [], modules: [], single: {} });
        if (categoryId) {
            let selectedCategory = originalCategories.find((category) => category.id === categoryId);
            if (!selectedCategory) {
                setCategories([...originalCategories, { id: categoryId, title: categoryId, isModule: false, groupName: 'Onay Bekleyen' }])
                setValue("categoryId", categoryId)
                setValue("isModule", false);
                handleIsModuleChange(false);
                setDefaultFeaturesForNewCategory();

                return
            }
            setValue("categoryId", categoryId)
            setValue("isModule", selectedCategory.isModule);
            handleIsModuleChange(selectedCategory.isModule)

            const data = await get(`/product-service/categories/${categoryId}`);
            if (response.ok && data.features && data.features.length) {


                setCategoryFeatures(data.features.map(({ feature, options, suggested }) => ({ feature, options, suggested })));

                appendFeature(data.features.map((item) => ({ featureId: item.featureId, suggested: item.suggested, values: [] })));

                if (data.modules.length) {
                    const words = await post("/product-service/viewwords/findbyidlist", { IdList: data.modules });
                    if (words && words.length) {
                        setModuleOptions(words.map((key) => ({ title: key.values[0].value, id: key.id, isNew: false })));
                    }
                }
            }
        }
    }

    async function handleFeatureChange(index) {
        await trigger(`features.${index}`)
    }

    function handleIsModuleChange(IsModule) {
        const values = getValues();
        reset({ ...values, modules: [], single: {} });
        if (IsModule) {
            setSelectedTabs(tabs.filter((tab) => tab.id !== 'single'))
            addModule();
        } else {
            setSelectedTabs(tabs.filter((tab) => tab.id !== 'modules'))
        }
    }

    function handleModelNameChange(value){
        setValue("modelName", removeSpaces(value))
    }

    const addModule = () => {
        appendModule({});
    };

    const addModuleInDefined = (value) => {
        let exists = moduleOptions.find((m) => m.id === value.id);
        if(!exists){
            setModuleOptions([...moduleOptions, value])
        }
    }

    const addVideo = () => {
        appendVideo({});
    }

    const addNewFeature = ()=>{
        if (!selectedFeature){ return; }

        let existFeature = allFeatures.find((feat) => feat.id === selectedFeature.id);
        let f = {
            suggested: true,
            feature: {},
            options: []
        };
        if(existFeature){
            f.feature = existFeature;
            let fieldType = featureFieldTypes.find((ft) => ft.id === existFeature.fieldTypeId)
            if (fieldType){
                f.feature.featureFieldType = fieldType;
            }
            let alreadyAdded = categoryFeatures.find(({feature}) => feature.id === existFeature.id)
            if (alreadyAdded){
                return;
            }
        }else{
            let alreadyAdded = categoryFeatures.find(({feature}) => feature.id === selectedFeature.id)
            if (alreadyAdded){
                return;
            }

            let fieldType = featureFieldTypes.find((ft) => ft.uiType === "full_string")
            f.feature = {
                id : selectedFeature.id,
                featureFieldType: fieldType,
                fieldTypeId: fieldType.id,
                translations : [
                    {
                        lang: 'tr',
                        value: selectedFeature.title,
                    }
                ]
            };
        }
        setCategoryFeatures([...categoryFeatures, f])
        appendFeature({ featureId: f.feature.id, suggested: f.suggested, values: [] });
        setSelectedFeature(null)
    }

    const removeCategoryFeature = (featureIndex)=>{
        setCategoryFeatures(categoryFeatures.filter((feat, index) => index !== featureIndex))
        removeFeature(featureIndex)
    }

    async function getCategories() {
        const data = await get("/product-service/categories");
        if (response.ok && data) {
            let parents = data.filter((cat) => cat.parentId === null && cat.suggested === false)
            let suggesteds = data.filter((cat) => cat.parentId === null && cat.suggested === true)
            let childrens = data.filter((cat) => cat.parentId !== null)

            let tree = [];
            parents.map((parent) => {
                let trs = getValueFromTranslation(parent.translations, "title");
                let chs = childrens
                    .filter((child) => child.parentId === parent.id)
                    .map((ch) => ({
                        groupName: trs,
                        id: ch.id,
                        title: getValueFromTranslation(ch.translations, "title"),
                        isModule: ch.isModule,
                    }))
                tree.push(...chs)
                return parent;
            });
            suggesteds.map((sugg)=> {
                let item =  {
                    groupName: 'Onay Bekleyen',
                    id: sugg.id,
                    title: getValueFromTranslation(sugg.translations, "title"),
                    isModule: sugg.isModule,
                }
                tree.push(item)
                return sugg;
            })
            setCategories(tree);
            setOriginalCategories(tree)
        }
    }

    async function getFeatures(){
        const data = await get(`/product-service/features/getallforselectboxlist`);
        if(response.ok && data){
            setAllFeatures(data)
            setSelectFeatures(data.map((feature) => ({id: feature.id, title: getValueFromTranslation(feature.translations, 'value')})))
        }
    }

    async function getFeatureFieldTypes(){
        const data = await get(`/product-service/featurefieldtypes`);
        if(response.ok && data){
            setFeatureFieldTypes(data)
        }
    }

    async function getSupplier(){
        const user = getCurrentUser();
        let supplier_id = user.profile.supplier_id;
        const data = await get(`/supplier-service/suppliers/GetById/${supplier_id}`)
        if (response.ok && data){
            supplier.current = data;
        }
    }

    useEffect(() => {
        if(!loading){ return }
        getSupplier().then(()=>{
            getCategories().then(()=>{
                handleIsModuleChange(watchIsModule);
                getFeatures().then(()=>{
                    getFeatureFieldTypes().then(()=>{
                        setLoading(false)
                    });
                });
            });
        })

    }, [loading]);

    return (
        loading
            ?
            <Box textAlign={"center"}>
                <Box><CircularProgress /></Box>
                <Box>Yükleniyor, lütfen bekleyin...</Box>
            </Box>
            :
        <Container maxWidth="xl">
            <Paper square>
                <Tabs variant={"standard"} value={activeTab} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="disabled tabs example" centered>
                    {selectedTabs.map((tab, tabIndex) => (<Tab className={classes.MuiTab} label={tab.title} key={tabIndex} id={`tab-${tabIndex}`} disabled={activeTab < 0} />))}
                </Tabs>
            </Paper>
            <form onSubmit={handleSubmit(onFormSubmit, onFormError)} style={{ marginTop: 20 }}>
                <TabPanel tabValue={activeTab} index={0}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"flex-start"} justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Genel Bilgiler"} subheader={"Ürünün genel bilgilerini girin."} />
                                <Divider />
                                <CardContent>
                                    <FormItem control={control} name="categoryId" onChange={handleCategoriesOnChange}>
                                        <Select freeSolo={true} groupBy={(category) => category.groupName} id="categoryId" label="Ürün Kategorisi" options={categories} displayMember="title" newItemDescription="yeni kategori ekle" />
                                    </FormItem>
                                    <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
                                        <Divider />
                                        <FormItem control={control} name="isModule" onChange={handleIsModuleChange}>
                                            <Checkbox label={"Ürün modüllerden oluşmaktadır."} />
                                        </FormItem>
                                        <Divider />
                                    </Box>
                                    <FormItem control={control} name="modelName" onChange={handleModelNameChange}>
                                        <TextField label="Ürün Modeli Adı" />
                                    </FormItem>
                                    <Box style={{ display: watchModelName ? 'block' : 'none', paddingTop: 20, paddingBottom: 20 }}>
                                        <Typography variant={"subtitle2"}>Ürün Adı Önizleme</Typography>
                                        <Divider />
                                        <Typography style={{ paddingTop: 8, paddingBottom: 8 }} variant={"h6"} color={"primary"}>{getProductName()}</Typography>
                                        <Divider />
                                        <Typography style={{ paddingTop: 8 }} variant={"subtitle2"} color="textSecondary">Ürününüz bu isimle görüntülenecektir.</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} xl={3}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Modüler ve Tekil Ürün Ayrımı"}/>
                                <Divider/>
                                <CardContent>
                                    <Box>
                                        <ul style={{color: '#424242', padding: 0, paddingLeft: 16}}>
                                            <li>Eğer ürününüz <u>birden fazla ürün parçasından oluşuyor ve bir arada satışa sunuyorsanız</u> ürünü <strong>modüler</strong> olarak oluşturup her bir parçasını ayrı ayrı ekleyebilirsiniz.</li>
                                            <li><strong>Aksi halde</strong> lütfen ürünün modüllerden oluşmadığını belirtip <strong>tekil ürün</strong> olarak ekleyin.</li>
                                            <li>Bazı ürün kategorileri varsayılan olarak modüler ürün eklemeye açık olarak gelirler.</li>
                                            <li>Modüler ürünler <u>en az 2 adet</u> modül içermelidir.</li>
                                            <li>Modüler ürünlerin <u>en az bir modülü</u> takım içeriğinde standart olarak bulunmalı ve adeti belirtilmelidir.</li>
                                        </ul>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={1}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"flex-end"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Özellikleri"} subheader={"Ürünün tasarım ve teknik özelliklerini tanımlayın."} />
                                <Divider />
                                <CardContent>
                                    {
                                        categoryFeatures.length > 0
                                            ?
                                            (
                                                <Grid container spacing={2}>
                                                    {
                                                        categoryFeatures.map((feature, featureIndex) => (
                                                            <Grid key={featureIndex} item xs={12} md={4}>
                                                                <Box style={{ marginBottom: 5, paddingBottom: 5, borderBottom: '2px solid #eee' }}>
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography style={{ fontWeight: 'bold', fontSize: 13, position: 'relative' }}>
                                                                            {getValueFromTranslation(feature.feature.translations, "value")}
                                                                        </Typography>
                                                                        <Button variant="text" color="secondary" size="small" disabled={!feature.suggested} onClick={()=>{removeCategoryFeature(featureIndex)}}><DeleteForever color={feature.suggested ? 'secondary': 'disabled'} fontSize={"small"}/></Button>
                                                                    </Box>
                                                                    <FormItem control={control} name={`features.${featureIndex}.values`} onChange={async () => { await handleFeatureChange(featureIndex) }}>
                                                                        {getFeatureComponent(feature.feature.featureFieldType, featureIndex, feature.options)}
                                                                    </FormItem>
                                                                </Box>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Typography align={"center"} variant="subtitle2" color="textSecondary">Bu kategori için öntanımlı özellik mevcut değil, lütfen aşağıdan ekleyin.</Typography>
                                            )
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Yeni Özellik Tanımlayın"} subheader={"Mevcut özellikler yeterli gelmediyse ürüne size sunduğumuz özellikleri tanımlayabilir, istediğiniz özellik listede yoksa ekleyebilirsiniz."} />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={8}>
                                            <Select freeSolo={true} key="add-feature-input" label="Özellik Ekle" value={selectedFeature} options={selectFeatures} onChange={(val)=> {setSelectedFeature(val) }} displayMember="title" newItemDescription="yeni özellik ekle"/>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button fullWidth variant="outlined" color="primary" style={{margin:'10px 0'}} onClick={addNewFeature}><Add/> Ekle</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary">Öntanımlı özellikler yeterli gelmediği durumda metin alanına özellik adını yazarak ekleme yapabilirsiniz.</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={2}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"flex-start"} justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            {
                                !watchIsModule ?
                                    <Card square>
                                        <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Fiyat ve Ölçüler"} subheader={"Ürüne fiyat ve ölçülerini ekleyin."} />
                                        <Divider />
                                        <CardContent>
                                            <ProductSingle watch={watch} control={control} supplier={supplier.current} />
                                        </CardContent>
                                    </Card>
                                    :
                                    <Card square>
                                        <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Modülleri"} subheader={"Ürüne ait modülleri ekleyin."} />
                                        <Divider />
                                        <CardContent>
                                            {
                                                fieldsModule.length === 0 && (
                                                    <Typography style={{ paddingTop: 8 }} align={"center"} variant={"subtitle2"} color="textSecondary">Eklediğiniz modüller burada görünecektir.</Typography>
                                                )
                                            }
                                            {
                                                fieldsModule.map((moduleField, moduleIndex) =>
                                                (
                                                    <Box xs={12} key={`module-${moduleIndex}`} style={{ position: 'relative', marginBottom: 10, paddingBottom: 5, borderBottom: '4px solid #eee' }}>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography variant={"h6"}>Modül #{moduleIndex + 1}</Typography>
                                                            {
                                                                moduleIndex !== 0 && <span onClick={() => removeModule(moduleIndex)} style={{ cursor: 'pointer', color: red[500] }} title="Modülü kaldır"><DeleteForever /></span>

                                                            }
                                                        </Box>
                                                        <ProductModule watch={watch} setValue={setValue} control={control} index={moduleIndex} definedModuleList={moduleOptions} onModuleAdded={addModuleInDefined} supplier={supplier.current} />
                                                    </Box>
                                                )
                                                )
                                            }
                                            <Box style={{ textAlign: 'center', margin: 20 }}>
                                                <Button onClick={addModule} color="primary" variant="outlined"><Add /> MODÜL EKLE</Button>

                                            </Box>
                                        </CardContent>
                                    </Card>
                            }
                        </Grid>
                        <Grid item md={3}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Uyarı ve Öneriler"}/>
                                <Divider/>
                                <CardContent>
                                    <Box>
                                        <strong><u>Modül Adları Hakkında</u></strong>
                                        <ul style={{color: '#424242', padding: 0, paddingLeft: 16}}>
                                            <li>
                                                Modül adında ürünün model adını kullanmayın. Dataware ürününüzün model adını modül isimlerinizle birleştirmektedir.
                                                <ul>
                                                    <li><code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>Barcelona İkili Koltuk</code> yerine <code style={{background: green[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: green[600]}}>İkili Koltuk</code> olarak modülünüzü belirtin.</li>
                                                </ul>
                                            </li>
                                            <li>Modül adını seçenekler arasından seçin, eğer istediğiniz seçeneği bulamazsanız modül adını yazarak arayın.</li>
                                            <li>
                                                Modül adında <strong>yazıyla ifade edilebilir isimleri rakamla yazmaktan kaçının</strong>.
                                                <ul>
                                                    <li><code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>2'li koltuk</code> yerine <code style={{background: green[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: green[600]}}>İkili Koltuk</code></li>
                                                    <li><code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>1'li koltuk</code> yerine <code style={{background: green[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: green[600]}}>Tekli Koltuk</code></li>
                                                    <li><code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>60'lık</code> yerine <code style={{background: green[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: green[600]}}>60 cm</code> seçeneklerini belirtin.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Modül adlarında tamamen büyük ya da tamamen küçük harf kullanımından kaçının.
                                                <ul>
                                                    <li><code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>KONSOL</code>,&nbsp;<code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>konsol</code>,
                                                        <code style={{background: red[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: red[400]}}>kOnSol</code> yerine <code style={{background: green[100], paddingLeft:4, paddingRight:4, borderRadius: 2, color: green[600]}}>Konsol</code>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Dataware size modül isimlerini önerecektir. Mümkün olduğunca önerilen seçenekleri kullanmaya çalışın.</li>
                                        </ul>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={3}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Teslimat Bilgileri"} subheader={"Ürünün teslimat bilgilerini girin."} />
                                <Divider />
                                <CardContent>
                                    <FormItem control={control} name="productionTime">
                                        <TextField label="Üretim Süresi - Gün" type="number" inputProps={{ min: 1 }} />
                                    </FormItem>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={4}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Görselleri"} subheader={"Ürününün yüksek kaliteli görsellerini yükleyin."} />
                                <Divider />
                                <CardContent>
                                    {
                                        fieldsImage.length === 0 && formState.errors.images?.type === 'min' && (
                                            <Typography style={{ paddingBottom: 8 }} align={"center"} variant={"subtitle2"} color="secondary">{formState.errors.images?.message}</Typography>
                                        )
                                    }
                                    {
                                        fieldsImage.length > 0 && (
                                            <div>
                                                {
                                                    fieldsImage.length > 1 && (<Typography style={{ paddingBottom: 8 }} align={"center"} variant={"subtitle2"} color="textSecondary">Görselleri sıralayabilirsiniz.</Typography>)
                                                }
                                                <SortableImageListContainer
                                                    items={fieldsImage}
                                                    onSortEnd={onImagesSortEnd}
                                                    useDragHandle={true}
                                                    lockAxis="y"
                                                />
                                            </div>
                                        )
                                    }
                                    <Uploader
                                        accept="image/*"
                                        maxFilesize={10}
                                        //minSizeBytes={1024*1024}
                                        maxSizeBytes={10*1024*1024}
                                        getUploadParams={getImageUploadParams}
                                        onChangeStatus={handleImages}
                                        inputContent={"Ürün görsellerini seçin"}
                                        inputWithFilesContent={"Daha fazla ekleyin"}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={5}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Videoları"} subheader={"Ürünün video adreslerini girin."} />
                                <Divider />
                                <CardContent>
                                    {
                                        fieldsVideo.length === 0 && (
                                            <Typography style={{ paddingTop: 8 }} align={"center"} variant={"subtitle2"} color="textSecondary">Eklediğiniz videolar burada görünecektir.</Typography>
                                        )
                                    }
                                    {
                                        fieldsVideo && fieldsVideo.map((video, videoIndex) => (
                                            <Grid key={videoIndex} container spacing={2} style={{ marginBottom: 10, paddingBottom: 5, borderBottom: '2px solid #eee' }}>
                                                <Grid item xs={12} sm={3}>
                                                    <FormItem control={control} name={`videos.${videoIndex}.provider`} onChange={(val)=>{ if(val && val.id) {setValue(`videos.${videoIndex}.provider`, val.id)} }}>
                                                        <Select id="provider" label="Sağlayıcı" options={videoProviders} displayMember="title" />
                                                    </FormItem>
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <FormItem control={control} name={`videos.${videoIndex}.url`}>
                                                        <TextField label="Video Adresi" />
                                                    </FormItem>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box style={{ padding: 10 }}>
                                                        <Button fullWidth onClick={() => { removeVideo(videoIndex) }}><DeleteForever color="secondary" /></Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                        ))
                                    }
                                </CardContent>
                            </Card>
                            <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: 20 }}>
                                <Button onClick={addVideo} color="primary" variant="outlined"><Add /> VİDEO EKLE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={6}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Dokümanları"} subheader={"Ürününün teknik dokümanlarını, kurulum şemalarını yükleyin."} />
                                <Divider />
                                <CardContent>
                                    {
                                        fieldsDocument.length > 0 && (
                                            <List>
                                                {
                                                    fieldsDocument.map((document, documentIndex) => (
                                                        <ListItem key={document.id}>
                                                            <ListItemAvatar>
                                                                <Avatar variant="rounded">
                                                                    <Description color="primary" />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText secondary={`Doküman #${documentIndex+1}`}  style={{marginLeft: 10}}  />
                                                            <Button onClick={() => { removeDocument(document.id) }}><DeleteForever color="secondary" /></Button>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        )
                                    }
                                    <Uploader
                                        accept="application/pdf"
                                        maxFilesize={10}
                                        //minSizeBytes={1024*1024}
                                        maxSizeBytes={10*1024*1024}
                                        getUploadParams={getDocumentUploadParams}
                                        onChangeStatus={handleDocuments}
                                        inputContent={"Ürün dokümanlarını seçin"}
                                        inputWithFilesContent={"Daha fazla ekleyin"}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel tabValue={activeTab} index={7}>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Card square>
                                <CardHeader titleTypographyProps={{ variant: 'h6' }} title={"Ürün Tasarımcısı"} subheader={"Ürünün tasarımcı bilgilerini girin."} />
                                <Divider />
                                <CardContent>
                                    <FormItem control={control} name={'designerId'} onChange={(val)=>{ if(val && val.id) {setValue(`designerId`, val.id)} }}>
                                        <Select freeSolo={true} id="designerId" label="Tasarımcı Adı Soyadı" options={designerList} displayMember="title" newItemDescription="yeni tasarımcı ekle" />
                                    </FormItem>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <Divider style={{ margin: '30px 0' }} />
                <Box display={"flex"} justifyContent={"center"}>
                    <Button style={{ margin: 10, display: activeTab === 0 ? 'none' : 'inline' }} size="small" onClick={goBack} disabled={activeTab === 0}>GERİ</Button>
                    <Button style={{ margin: 10, display: activeTab < selectedTabs.length - 1 ? 'inline' : 'none' }} size="small" variant="contained" color="primary" onClick={goNext}>İLERİ</Button>
                    <Button style={{ margin: 10, display: activeTab === selectedTabs.length - 1 ? 'inline' : 'none' }} size="small" variant="contained" color="primary" type="submit">ÜRÜNÜ KAYDET</Button>
                </Box>
            </form>
        </Container>
    )
}