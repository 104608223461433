import React from "react";
import Typography from "components/typography";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";

export default function RegisterCompleteStep({ visible }) {
  return (
    <div style={{ display: visible ? "block" : "none", width: '100%' }}>
        <Typography variant={"h4"} align={"center"} style={{margin: 10}}>Neredeyse bitti!</Typography>
        <Box display={"flex"} justifyContent="center" mt={3}>
            <Button
                size="large"
                variant="contained"
                color="primary"
                type={"submit"}
            >
                ŞİRKET KAYDINIZI TAMAMLAYIN
            </Button>
        </Box>
    </div>
  );
}
