import React, {useEffect, useState} from "react";
import FormItem from "components/form-item";
import PhoneInput from "components/phone-input";
import TextField from "components/text-field";
import useCustomFetch from "hooks/use-fetch";
import {getValueFromTranslation} from "utils";
import Select from "components/select";
import {Box, Grid, Typography} from "@material-ui/core";

export default function DepartmentStep({ visible, setValue, control, fields = [] }) {
  const { get, response } = useCustomFetch();
  const [title, setTitle] = useState([]);
  useEffect(() => {
    async function getTitle() {
      const data = await get("/i18n-service/organization/roles");
      if (response.ok)
        data &&
          setTitle(
            data.map((item) => ({
              id: item.id,
              tagName: item.tagName,
              name: getValueFromTranslation(item.translations, "value"),
            }))
          );
    }
    getTitle();
  }, []);

  const saleTitleOptions = title.filter((title) => title.tagName === "sale");
  const accountTitleOptions = title.filter((title) => title.tagName === "account");
  const purchasingTitleOptions = title.filter((title) => title.tagName === "purchasing");
  const transportTitleOptions = title.filter((title) => title.tagName === "transport");

  return (
    <Box style={{ display: visible ? "block" : "none", width: "800px", margin: "0 auto" }}>
      <Box style={{borderBottom: '1px solid #ccc', padding: 8}} key="sale">
        <Typography variant={"h6"}>Satış Departmanı / Yetkili Kişi</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.0.title`} onChange={(v)=>{ setValue("departmentChiefs.0.title", v && v.id) }}>
              <Select id="title" label="Ünvanı" options={saleTitleOptions} displayMember="name"/>
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.0.name`}>
              <TextField label="Yetkili Adı" />
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.0.surname`}>
              <TextField label="Yetkili Soyadı" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.0.phone`}>
              <PhoneInput label="Yetkili Telefon" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.0.email`}>
              <TextField label="Yetkili E-posta Adresi" />
            </FormItem>
          </Grid>
        </Grid>
      </Box>
      <Box style={{backgroundColor: '#efefef', borderBottom: '1px solid #ccc', padding: 8}} key="account">
        <Typography variant={"h6"}>Muhasebe Departmanı / Yetkili Kişi</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.1.title`} onChange={(v)=>{ setValue("departmentChiefs.1.title", v && v.id) }}>
              <Select id="title" label="Ünvanı" options={accountTitleOptions} displayMember="name"/>
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.1.name`}>
              <TextField label="Yetkili Adı" />
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.1.surname`}>
              <TextField label="Yetkili Soyadı" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.1.phone`}>
              <PhoneInput label="Yetkili Telefon" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.1.email`}>
              <TextField label="Yetkili E-posta Adresi" />
            </FormItem>
          </Grid>
        </Grid>
      </Box>
      <Box style={{borderBottom: '1px solid #ccc', padding: 8}} key="purchasing">
        <Typography variant={"h6"}>Satınalma Departmanı / Yetkili Kişi</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.2.title`} onChange={(v)=>{ setValue("departmentChiefs.2.title", v && v.id) }}>
              <Select id="title" label="Ünvanı" options={purchasingTitleOptions} displayMember="name"/>
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.2.name`}>
              <TextField label="Yetkili Adı" />
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.2.surname`}>
              <TextField label="Yetkili Soyadı" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.2.phone`}>
              <PhoneInput label="Yetkili Telefon" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.2.email`}>
              <TextField label="Yetkili E-posta Adresi" />
            </FormItem>
          </Grid>
        </Grid>
      </Box>
      <Box style={{backgroundColor: '#efefef', padding: 8}} key="transport">
        <Typography variant={"h6"}>Sevkiyat Departmanı / Yetkili Kişi</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.3.title`} onChange={(v)=>{ setValue("departmentChiefs.3.title", v && v.id) }}>
              <Select id="title" label="Ünvanı" options={transportTitleOptions} displayMember="name"/>
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.3.name`}>
              <TextField label="Yetkili Adı" />
            </FormItem>
          </Grid>
          <Grid item sm={4}>
            <FormItem control={control} name={`departmentChiefs.3.surname`}>
              <TextField label="Yetkili Soyadı" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.3.phone`}>
              <PhoneInput label="Yetkili Telefon" />
            </FormItem>
          </Grid>
          <Grid item sm={6}>
            <FormItem control={control} name={`departmentChiefs.3.email`}>
              <TextField label="Yetkili E-posta Adresi" />
            </FormItem>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
