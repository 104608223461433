import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Box, Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  contentDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent'
  }
}));

export default function HorizontalLabelPositionBelowStepper({
  steps,
  onSubmit,
  onStepChange,
  control,
  setValue,
  fields,
  optionalParams,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) return;
    const valid = await onStepChange(activeStep);
    valid && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((item) => (
          <Step key={item.key}>
            <StepLabel>
              <Typography variant="h6">{item.title}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.stepContent}>
        <form onSubmit={onSubmit}>
          {steps.map((step, index) => (
            <step.Component
              key={`step-${index}`}
              control={control}
              setValue={setValue}
              fields={fields}
              optionalParams={optionalParams}
              visible={index === activeStep}
            />
          ))}
          <Divider className={classes.contentDivider} />
          <Box display={"flex"} justifyContent={"center"}>
            <Button style={{ display: (activeStep === 0 ) ? "none" : "" }}
                size="small"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
            >
              GERİ
            </Button>
            <Button style={{ display: (activeStep < steps.length - 1) ? "inline" : "none" }}
                size="small"
                variant="contained"
                color="primary"
                type={"button"}
                onClick={handleNext}
            >
              SONRAKİ
            </Button>
          </Box>
          <Divider className={classes.contentDivider} />
        </form>
      </div>
    </div>
  );
}
