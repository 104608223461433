import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader, Chip,
    Divider,
    List,
    ListItem, ListItemAvatar,
    ListItemText, useTheme
} from "@material-ui/core";
import Grid from "../../components/grid";
import Container from "../../components/container";
import Typography from "@material-ui/core/Typography";
import {
    blue,
    common, cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen, lime,
    orange,
    pink,
    purple,
    red, teal
} from "@material-ui/core/colors";
import {
    ArrowDropDown,
    Cancel, CheckCircle,
    Favorite, HelpOutline, ListAlt,
    People,
    Public, Search, ShoppingBasket, Star,
    Store, TrendingUp, ViewModule,
    Visibility
} from "@material-ui/icons";
import React from "react";
import {Bar, Line} from "react-chartjs-2";
import {alpha, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cardHeaderAction: {
        margin:0
    },
    anchor: {
        display: "block",
        position: "relative",
        top: "-250px",
        visibility: "hidden"
    }
}));


export default function Reports(props){
    const classes = useStyles();
    const theme = useTheme();

    const productViewsData = {
        datasets: [
            {
                fill: true,
                lineTension: 0.5,
                borderJoinStyle: 'miter',
                pointRadius: 3,
                pointHitRadius: 10,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                borderColor: alpha(lightGreen[500], 0.8),
                backgroundColor: alpha(lightGreen[600], 0.8),
                data: [8200, 9500, 13000, 17000, 18000, 21000, 28000],
            }
        ],
        labels: ['1 Mayıs', '2 Mayıs', '3 Mayıs', '4 Mayıs', '5 Mayıs', '6 Mayıs', '7 Mayıs']
    };

    const productViewsChartOptions = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {},
            y: {
                beginAtZero: true,
                min: 0
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
            }
        }
    };

    const categoryProductData = {
        datasets: [
            {
                data: [63, 76, 102, 86, 45, 59],
                backgroundColor: [
                    indigo[500],
                    red[600],
                    orange[600],
                    pink[600],
                    green[600],
                    blue[600],
                ],
                borderWidth: 8,
                borderColor: common.white,
                hoverBorderColor: common.white
            }
        ],
        labels: ['Koltuk Takımı', 'Köşe Koltuk', 'Yatak Odası', 'Berjer', 'Tv Ünitesi', 'Üçlü Koltuk']
    };

    const categoryProductDataOptions = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleColor: grey[700],
                backgroundColor: theme.palette.background.paper,
                callbacks: {
                    labelTextColor: function(context) {
                        return grey[800];
                    }
                }

            }
        }
    };

    const salesData = {
        datasets: [
            {
                fill: true,
                lineTension: 0.5,
                borderJoinStyle: 'miter',
                pointRadius: 3,
                pointHitRadius: 10,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                borderColor: alpha(indigo[500], 0.8),
                backgroundColor: alpha(indigo[600], 0.8),
                data: [12, 15, 23, 17, 18, 21, 28],
            }
        ],
        labels: ['1 Mayıs', '2 Mayıs', '3 Mayıs', '4 Mayıs', '5 Mayıs', '6 Mayıs', '7 Mayıs']
    };

    const salesChartOptions = {
        animation: true,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {},
            y: {
                beginAtZero: true,
                min: 0
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
            }
        }
    };

    const salesByCategoryData = {
        datasets: [
            {
                data: [1684, 2487, 3658, 2415, 1586, 1587],
                backgroundColor: [
                    indigo[500],
                    red[600],
                    orange[600],
                    pink[600],
                    green[600],
                    blue[600],
                ],
                borderWidth: 8,
                borderColor: common.white,
                hoverBorderColor: common.white
            }
        ],
        labels: ['Koltuk Takımı', 'Köşe Koltuk', 'Yatak Odası', 'Berjer', 'Tv Ünitesi', 'Üçlü Koltuk']
    };

    const salesByCategoryDataOptions = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleColor: grey[700],
                backgroundColor: theme.palette.background.paper,
                callbacks: {
                    labelTextColor: function(context) {
                        return grey[800];
                    }
                }

            }
        }
    };

    const activeSalesByCategoryData = {
        datasets: [
            {
                data: [184, 187, 208, 115, 186, 187],
                backgroundColor: [
                    indigo[500],
                    red[600],
                    orange[600],
                    pink[600],
                    green[600],
                    blue[600],
                ],
                borderWidth: 8,
                borderColor: common.white,
                hoverBorderColor: common.white
            }
        ],
        labels: ['Koltuk Takımı', 'Köşe Koltuk', 'Yatak Odası', 'Berjer', 'Tv Ünitesi', 'Üçlü Koltuk']
    };

    const activeSalesByCategoryDataOptions = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleColor: grey[700],
                backgroundColor: theme.palette.background.paper,
                callbacks: {
                    labelTextColor: function(context) {
                        return grey[800];
                    }
                }

            }
        }
    };

    const salesLocationData = {
        datasets: [
            {
                data: [
                    171,
                    187,
                    155,
                    157,
                    146,
                    234,
                    193,
                    121,
                    110,
                    135,
                ],
                backgroundColor: [
                    indigo[600],
                    red[600],
                    orange[600],
                    pink[600],
                    green[600],
                    blue[600],
                    purple[600],
                    cyan[600],
                    teal[600],
                    lime[600]
                ],
                borderWidth: 8,
                borderColor: common.white,
                hoverBorderColor: common.white
            }
        ],
        labels: ['Sivas','İzmir','Bursa','Antalya','Eskişehir','İstanbul','Ankara','Rize','Van','Samsun']
    };

    const salesLocationDataOptions = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleColor: grey[700],
                backgroundColor: theme.palette.background.paper,
                callbacks: {
                    labelTextColor: function(context) {
                        return grey[800];
                    }
                }

            }
        }
    };

    const sshsData = {
        datasets: [
            {
                data: [305, 263, 158, 62, 82, 157],
                backgroundColor: [
                    indigo[500],
                    red[600],
                    orange[600],
                    pink[600],
                    green[600],
                    blue[600],
                ],
                borderWidth: 8,
                borderColor: common.white,
                hoverBorderColor: common.white
            }
        ],
        labels: ['Koltuk Takımı', 'Köşe Koltuk', 'Yatak Odası', 'Berjer', 'Tv Ünitesi', 'Üçlü Koltuk']
    };

    const sshsDataOptions = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                titleColor: grey[700],
                backgroundColor: theme.palette.background.paper,
                callbacks: {
                    labelTextColor: function(context) {
                        return grey[800];
                    }
                }

            }
        }
    };

    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2} style={{marginBottom: '1em'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Ürünler Raporuna Genel Bakış</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} classes={{action: classes.cardHeaderAction }}
                                    action={(
                                        <Button endIcon={<ArrowDropDown />} size="small" variant="text" color="primary">Son 7 gün</Button>
                                    )}
                                    title="Ürün Görüntülenme Sayısı"
                        />
                        <Divider />
                        <CardContent>
                            <Box style={{height: 268, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Line data={productViewsData} options={productViewsChartOptions} type="line"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Toplam Ürün Hareketleri"/>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightBlue[600]}}>
                                        <Visibility />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Görüntüleme</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: pink[600]}}>
                                        <Search />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Arama</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: indigo[600]}}>
                                        <ListAlt />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Listeleme</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightGreen[600]}}>
                                        <ShoppingBasket />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Sepete Ekleme</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: red[600]}}>
                                        <Favorite />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Favorileme</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: '1em'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Siparişler Raporuna Genel Bakış</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={8}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} classes={{action: classes.cardHeaderAction }}
                                    action={(
                                        <Button endIcon={<ArrowDropDown />} size="small" variant="text" color="primary">Son 7 gün</Button>
                                    )}
                                    title="Son Siparişler"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Line data={salesData} options={salesChartOptions} type="line"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Toplam Sipariş Özeti"/>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightBlue[600]}}>
                                        <Visibility />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Sipariş Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: indigo[600]}}>
                                        <ListAlt />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Aktif Sipariş Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightGreen[600]}}>
                                        <TrendingUp />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Trend Ürün Kategorisi</ListItemText>
                                <Typography variant="h6">...</Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} lg={6}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Kategorilere Göre Toplam Siparişler"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Bar data={salesByCategoryData} options={salesByCategoryDataOptions} type="bar"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} lg={6}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Kategorilere Göre Aktif Siparişler"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Bar data={activeSalesByCategoryData} options={activeSalesByCategoryDataOptions} type="bar"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: '1em'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Kategoriler Raporuna Genel Bakış</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={8}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Satıştaki Ürün Kategorileri"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Bar data={categoryProductData} options={categoryProductDataOptions} type="pie"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Toplam Ürün Özeti"/>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightBlue[600]}}>
                                        <Visibility />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Ürün Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: indigo[600]}}>
                                        <ListAlt />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Satıştaki Ürün Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightGreen[600]}}>
                                        <ViewModule />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Ürünleri İçeren Kategori Sayısı</ListItemText>
                                <Typography variant="h6">0</Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: '1em'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Satış Bölgeleri Raporuna Genel Bakış</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={8}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="En Aktif 10 Satış Bölgesi"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Bar data={salesLocationData} options={salesLocationDataOptions} type="pie"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Satış Bölgeleri Özeti"/>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: green[600]}}>
                                        <CheckCircle />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Satışa Açık Bölge Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: grey[600]}}>
                                        <Cancel />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Satışa Kapalı Bölge Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: red[600]}}>
                                        <Star />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>En Aktif Satış Bölgesi</ListItemText>
                                <Typography variant="h6">...</Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginBottom: '1em'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Satış Sonrası Hizmetler Raporuna Genel Bakış</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={8}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Kategorilere göre SSH Talepleri"/>
                        <Divider />
                        <CardContent>
                            <Box style={{height: 182, position: 'relative'}}>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.5, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                                <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                                    <Typography variant="subtitle2" style={{color: '#383838'}}>Bu rapor henüz aktif değil.</Typography>
                                </Box>
                                <Bar data={sshsData} options={sshsDataOptions} type="pie"/>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card style={{ height: '100%' }}>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} style={{padding:10 }} title="Toplam SSH Talebi Özeti"/>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightBlue[600]}}>
                                        <Visibility />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Aktif SSH Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: indigo[600]}}>
                                        <ListAlt />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>Toplam SSH Adeti</ListItemText>
                                <Typography variant="h5">0</Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: lightGreen[600]}}>
                                        <ViewModule />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>En Çok SSH İçeren Ürün Kategorisi</ListItemText>
                                <Typography variant="h6" align="right">...</Typography>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Dataware Platform İstatistikleri</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card style={{ height: '100%' }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                                <Box>
                                    <Typography variant="h6">Toplam Mağaza Sayısı</Typography>
                                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">128.769 <Chip variant="outlined" disabled size="small" label="Örnek veridir." icon={<HelpOutline/>} /></Box>
                                </Box>
                                <Avatar style={{backgroundColor: red[600], height: 56, width: 56}}>
                                    <Store/>
                                </Avatar>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card style={{ height: '100%' }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                                <Box>
                                    <Typography variant="h6">Toplam Satış Personeli Sayısı</Typography>
                                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">18.392 <Chip variant="outlined" disabled size="small" label="Örnek veridir." icon={<HelpOutline/>} /></Box>
                                </Box>
                                <Avatar style={{backgroundColor: indigo[600], height: 56, width: 56}}>
                                    <People/>
                                </Avatar>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card style={{ height: '100%' }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                                <Box>
                                    <Typography variant="h6">Toplam Satış Bölgesi Sayısı</Typography>
                                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">157.658 <Chip variant="outlined" disabled size="small" label="Örnek veridir." icon={<HelpOutline/>} /></Box>
                                </Box>
                                <Avatar style={{backgroundColor: lightBlue[600], height: 56, width: 56}}>
                                    <Public/>
                                </Avatar>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card style={{ height: '100%' }}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                                <Box>
                                    <Typography variant="h6">Toplam Ürün Kategorisi Sayısı</Typography>
                                    <Box component="span" fontWeight="fontWeightBold" fontSize="h5.fontSize">1.862 <Chip variant="outlined" disabled size="small" label="Örnek veridir." icon={<HelpOutline/>} /></Box>
                                </Box>
                                <Avatar style={{backgroundColor: lightGreen[600], height: 56, width: 56}}>
                                    <ViewModule/>
                                </Avatar>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}