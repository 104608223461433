import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useCustomFetch from "hooks/use-fetch";
import FeatureItem from "components/feature-item";
import PropTypes from "prop-types";

const StringSelect = (props) => {
  const { definedOptionList, type, label, showEdit, value, onChange, error, helperText, loadingText, newItemDescription } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState(value || []);
  const [inputValue, setInputValue] = React.useState("");

  const { post, response } = useCustomFetch();


  useEffect(() => {
    value && setSelected(value);
  }, [value]);

  async function handleSearch(e) {
    if (e.target.value.length <= 2) {
      return false;
    }
    setOptions([]);
    setLoading(true)
    const data = await post("/product-service/viewwords/search", {
      lang: "tr",
      term: e.target.value,
      type: type,
    });

    if (response.ok && data && data.length > 0){
      setOptions(data.map((key) => ({ name: key.value, value: key.id })));
    }else{
      let value = e.target.value.trim();
      setOptions([{ name: value, value: value, isNew: true }])
    }
    setLoading(false)

  }

  function onInputChange(e, value){
    setInputValue(value)
  }

  function handleSelectChange(e, value) {
    if (!value) return;
    let exists = selected.find((item)=> item.value === value.value);
    if (exists) return;
    let val = [...selected, value];
    setSelected(val);
    onChange(val);
  }

  function handleRemove(id) {
    setInputValue("")
    const val = selected.filter((item) => item.value !== id);
    setSelected(val.length > 0 ? val : undefined);
    onChange(val);
  }

  function handleFeatureChange(item, value) {
    let val = [...selected];
    val.forEach((a) => {
      if (a.value === item.value) {
        a.featureValue = value;
      }
    });
    setSelected(val);
    onChange(val);
  }

  async function handleDefinedOptions(){
    setOptions([])
    if (definedOptionList.length){
      setLoading(true)
      const data = await post("/product-service/viewwords/FindByIdList", { IdList: definedOptionList});
      if (data && data.length){
        setOptions(data.map((key) => ({ name: key.values[0].value, value: key.id })));
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={async () => {
          await handleDefinedOptions();
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        renderOption={(option)=> {
          return (
              <React.Fragment>
                {option.name}
                {
                  option.isNew && newItemDescription !== null && (<i style={{marginLeft: 6, color: '#828282'}}> · {newItemDescription}</i>)
                }
              </React.Fragment>
          )
        }}
        options={options}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={"seçenekler için arama yapın..."}
        onChange={handleSelectChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={"outlined"}
            margin={"dense"}
            onChange={handleSearch}
            onFocus={handleSearch}
            fullWidth
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {selected &&
        selected.map((item, index) => (
          <FeatureItem
            color={item.isNew ? 'secondary' : "primary"}
            key={`feature-item-${index}`}
            value={item}
            showEdit={showEdit}
            onRemove={() => handleRemove(item.value)}
            onFeatureChange={handleFeatureChange}
          />
        ))}
    </>
  );
};

StringSelect.defaultProps = {
  type: "wordItem",
  showEdit: true,
  onChange: () => {},
  value: [],
  loadingText: 'Loading...',
  definedOptionList: [],
  newItemDescription: null,
};

StringSelect.propTypes = {
  type: PropTypes.string,
  showEdit: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array,
  loadingText: PropTypes.string,
  definedOptionList: PropTypes.array,
  newItemDescription: PropTypes.string,
};

export default StringSelect;
