import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    minWidth: 60,
  },
}));

export default function InputSelector({
  inputCount,
  dropValues,
  label,
  error,
  helperText,
  onChange,
  value,
}) {
  const [componentValue, setComponentValue] = React.useState(value || []);

  const classes = useStyles();

  const setFormValue = (val) => {
    if (val.filter((item) => item).length > inputCount) {
      onChange(val);
    }
  };

  const handleSelectChange = (e) => {
    let val = [...componentValue];
    val[inputCount] = e.target.value;
    setComponentValue(val);

    setFormValue(val);
  };

  const handleInputChange = (e, index) => {
    let val = [...componentValue];
    val[index] = e.target.value;
    setComponentValue(val);
    setFormValue(val);
  };

  function getInputs() {
    let inputs = [];
    for (let index = 1; index <= inputCount; index++) {
      inputs.push(
        <TextField
          id={`${label}-${index}`}
          label={label}
          className={classes.textField}
          type="number"
          onChange={(e) => handleInputChange(e, index - 1)}
          value={componentValue[index - 1]}
          key={index}
          error={error}
          helperText={helperText}
          variant="outlined"
          margin="dense"
        />
      );
    }
    return inputs.map((item) => item);
  }

  return (
    <>
      {getInputs()}
      <FormControl className={classes.formControl} error={error}>
        {label && <InputLabel id={`select-${label}`}>Birim</InputLabel>}
        <Select
          fullWidth
          label={"label"}
          labelId={`select-${label}`}
          id={`select-${label}`}
          onChange={handleSelectChange}
          value={componentValue[inputCount] || ''}
        >
          {dropValues.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
