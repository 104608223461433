import { Box, Link, Typography } from "@material-ui/core";
import React from "react";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://dataware.com.tr/">
        Dataware
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer(props) {
  
  return (
    <Box style={{padding:"10px"}}>
      <Copyright />
    </Box>
  );
}
