import React from "react";
import {alpha, makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Box, Button, Link, Tooltip} from "@material-ui/core";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import {ExitToApp, Person} from "@material-ui/icons";
import Logo from "assets/logo-inverted.svg"

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid #046393`,
    backgroundColor: "#046393",
    color: "#fff"
  },
  toolbar: {
    flexWrap: "wrap",
    justifyContent: 'space-between',
  },
  toolbarTitle: {
    fontSize: "20px",
    color: "#fff",
    //flexGrow: 1,
    "&:hover": {
      textDecoration: 'none',
    },
    width: 150,
    height: '100%',
    position: 'relative'
  },
  nav: {
    display: 'flex'
  },
  link: {
    padding: theme.spacing(1, 1.5),
    fontWeight: "bold",
    color: "#fff",
    textDecoration: 'none',
    textTransform: 'none',
    "&:hover": {
      textDecoration: 'none',
      backgroundColor: alpha("#00425f", 0.5),
      borderRadius: "3px",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const { logout } = useReactOidc();
  return (
    <AppBar
      position="fixed"
      elevation={1}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Link
            href="/"
            className={classes.toolbarTitle}
        >
          <img style={{width: '100%'}} src={Logo} alt="Dataware Production" />
          <span style={{position: 'absolute', bottom: 2, right: 0, fontSize: '10px', color: '#fff'}}>ÜRETİM</span>
        </Link>
        <nav className={classes.nav}>
          <Link
              variant="button"
              href="/store-relations"
              className={classes.link}
          >
            Mağaza İlişkileri
          </Link>
          <Link
              variant="button"
              href="/locations"
              className={classes.link}
          >
            Bölge Yönetimi
          </Link>
          <Link
              variant="button"
              href="/orders"
              className={classes.link}
          >
            Siparişler
          </Link>
          <Link
            variant="button"
            href="/products"
            className={classes.link}
          >
            Ürünler
          </Link>
          <Link
              variant="button"
              href="/customers"
              className={classes.link}
          >
            Müşteriler
          </Link>
          <Link
              variant="button"
              href="/users"
              className={classes.link}
          >
            Kullanıcılar
          </Link>
          <Link
              variant="button"
              href="/reports"
              className={classes.link}
          >
            Raporlar
          </Link>
        </nav>
        <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
          <Link
              variant="button"
              href="/company"
              className={classes.link}
          >
            Firma Bilgileri
          </Link>
          <Button variant="text" onClick={()=>{ console.log("open dropdown for my account.") }} className={classes.link}>
            <Tooltip title={"Hesabım"}>
              <Person/>
            </Tooltip>
          </Button>
          <Button variant="text" onClick={logout} className={classes.link}>
            <Tooltip title={"Çıkış"}>
              <ExitToApp/>
            </Tooltip>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
