import React, {useEffect} from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import trLocale from "date-fns/locale/tr";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";

export default function DatePicker({
  label,
  onChange,
  format,
  value,
  inputRef,
  ...other
}) {
  const [componentValue, setComponentValue] = React.useState(value || null);

  useEffect(() => {
    setComponentValue(value || null);
  }, [value]);

  const handleOnChange = (date, newValue) => {
    setComponentValue(date);
    onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
      <KeyboardDatePicker
        inputVariant="outlined"
        size="small"
        margin="dense"
        label={label}
        autoOk
        inputRef={inputRef}
        format={format}
        value={componentValue}
        onChange={handleOnChange}
        animateYearScrolling
        {...other}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  label: "Date Picker",
  onChange: () => {},
  format: "dd/MM/yyyy",
};
