import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container, TextField, Select,
  Typography, MenuItem, InputLabel, FormControl
} from "@material-ui/core";
import customers from './data';
import Grid from "../../components/grid";
import Button from "@material-ui/core/Button";
import CustomerTable from "./CustomerTable";


export default function Customers() {

  const [country, setCountry] = React.useState('0');
  const [state, setState] = React.useState('0');
  const [city, setCity] = React.useState('0');

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  return (
      <Container maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4">Müşteriler</Typography>
        </Box>
        <Card>
          <CardContent>
            <Grid container  spacing={3} style={{alignItems: 'center'}}>
              <Grid item xs={12} sm={4}>
                <TextField variant="outlined" fullWidth  size="small" placeholder="Müşteri ara: ad, kod, yetkili kişi"/>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="customer_search_country_label">Ülke</InputLabel>
                  <Select variant="outlined" size="small" fullWidth value={country} placeholder="Ülke" onChange={handleCountryChange} label="Ülke" labelId="customer_search_country_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                    <MenuItem value={10}>ABD</MenuItem>
                    <MenuItem value={20}>İngiltere</MenuItem>
                    <MenuItem value={30}>İsveç</MenuItem>
                    <MenuItem value={40}>Türkiye</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="customer_search_state_label">Şehir</InputLabel>
                  <Select variant="outlined" size="small" fullWidth value={state} placeholder="Şehir" onChange={handleStateChange} label="Şehir" labelId="customer_search_state_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                    <MenuItem value={10}>İzmir</MenuItem>
                    <MenuItem value={20}>Londra</MenuItem>
                    <MenuItem value={30}>New Mexico</MenuItem>
                    <MenuItem value={40}>Stockholm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="customer_search_city_label">İlçe</InputLabel>
                  <Select variant="outlined" size="small" fullWidth value={city} placeholder="İlçe" onChange={handleCityChange} label="İlçe" labelId="customer_search_city_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                    <MenuItem value={10}>Albuquerque</MenuItem>
                    <MenuItem value={20}>Buca</MenuItem>
                    <MenuItem value={30}>Chelsea</MenuItem>
                    <MenuItem value={40}>Södermalm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button fullWidth color="primary" variant="contained">Ara</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box style={{ paddingTop: 10,position:'relative'}}>
          <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.7, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
          <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
            <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
            <Typography variant="subtitle2" style={{color: '#383838'}}>Henüz müşteriniz bulunmamaktadır.</Typography>
          </Box>
          <CustomerTable customers={customers}/>
        </Box>
      </Container>
  );
}
