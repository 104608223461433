import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import OIDC_CONFIG from "./config/oidc.config";
import { AuthenticationProvider, oidcLog } from "@axa-fr/react-oidc-context";
import Routes from "./router";
import Layout from "pages/layout";
import SecureFlow from "components/secure-flow/index"
import MobilClose from "./components/mobile-close";


const SessionLost = () => {
  return <div style={{textAlign: 'center'}}>Oturum sonlandı.</div>;
};
const NotAuthenticated = () => {
  return <div style={{textAlign: 'center'}}>Oturum başlatılamadı.</div>;
};
const NotAuthorized = () => {
  return <div style={{textAlign: 'center'}}>Bu işlemi yapmaya yetkiniz yok.</div>;
};
const AuthenticatingCallback = () => {
  return <div style={{textAlign: 'center'}}>Kimliğiniz doğrulanıyor...</div>;
};
const AuthenticatedCallback = () => {
  return <div style={{textAlign: 'center'}}>Oturum açma başarılı, uygulamaya yönlendiriliyorsunuz...</div>;
};

function App() {
  return (
    <AuthenticationProvider
      notAuthenticated={NotAuthenticated}
      authenticating={AuthenticatingCallback}
      callbackComponentOverride={AuthenticatedCallback}
      notAuthorized={NotAuthorized}
      sessionLostComponent={SessionLost}
      configuration={OIDC_CONFIG}
      loggerLevel={oidcLog.WARN}
      isEnabled={true}
    >
      <Router>
        <Layout>
          <SecureFlow/>
          <MobilClose/>
          <Routes />
        </Layout>
      </Router>
    </AuthenticationProvider>
  );
}
export default App;
