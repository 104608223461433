import React, {useState, useRef, useEffect} from "react";
import {
    Box,
    Card,
    CardContent,
    Container, TextField,
    Typography, FormControl
} from "@material-ui/core";
import Grid from "../../components/grid";
import Button from "@material-ui/core/Button";
import UserTable from "./UserTable";
import {getCurrentUser} from "../../utils";
import useCustomFetch from "../../hooks/use-fetch";
import Select from "components/select";


export default function Users(props) {

    const [role, setRole] = useState(-1);
    const [selectDepartments, setSelectDepartments] = useState([]);
    const departments = useRef([]);
    const departmentTags = useRef([]);
    const [department, setDepartment] = useState(-1);
    const [selectRoles, setSelectRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const {get, response} = useCustomFetch();
    const currentUser = getCurrentUser();
    const supplier_id = currentUser.profile?.supplier_id;

    const handleDepartmentChange = (departmentId) => {
        setDepartment(departmentId);
        if (departmentTags.current.length){
            let roles = departmentTags.current.filter((dt)=> dt.departmentId === departmentId).map((d) => ({
                id: d.id,
                name: d.translations.find((t)=> t.lang === 'tr').value
            }));
            setSelectRoles(roles)
        }else{
            setSelectRoles([])
        }
    };

    const handleRoleChange = (value) => {
        setRole(value);
    };

    const getDepartments = async () => {
        const data = await get("/i18n-service/organization/departments");
        if (response.ok && data.length) {
            departments.current = data;
            setSelectDepartments(data.map((d) => ({
                id: d.id,
                name: d.translations.find((t)=> t.lang === 'tr').value
            })))
        }
    }
    const getDepartmentTags = async () => {
        const data = await get("/i18n-service/organization/roles");
        if (response.ok && data.length) {
            departmentTags.current = data;
        }
    }

    const getUsers = async () => {
        const supplierData = await get(`/supplier-service/suppliers/GetById/${supplier_id}`);
        if (response.ok && supplierData) {
            let users = supplierData.departmentChiefs.map((chief) => {
                chief.role = departmentTags.current.find((dept) => dept.id === chief.title).translations.find((t) => t.lang === 'tr').value;
                return chief;
            })
            setUsers(users)
        }
    }

    useEffect(() => {
        getDepartments().then(() => {
            getDepartmentTags().then(() => {
                getUsers().then(()=>{});
            })
        })
    }, [])

    return (
        <Container maxWidth="lg">
            <Box mb={2}>
                <Typography variant="h4">Kullanıcılar</Typography>
            </Box>
            <Card>
                <CardContent>
                    <Grid container spacing={3} style={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={3}>
                            <TextField variant="outlined" fullWidth size="small" placeholder="Kullanıcı ara: ad, email, rol"/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <Select id="department" label="Departman" options={selectDepartments} displayMember="name" onChange={handleDepartmentChange} value={department} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <Select id="role" label="Rol" options={selectRoles} displayMember="name" onChange={handleRoleChange} value={role} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button fullWidth color="primary" variant="contained">Ara</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Box style={{paddingTop: 10}}>
                <UserTable users={users}/>
            </Box>
        </Container>
    );
}