import React, {useEffect} from "react";
import MuiTextField from "@material-ui/core/TextField";

export default function TextField({
  onChange,
  error,
  value,
  helperText,
  fullWidth,
  multiline,
  rows,
  label,
  type,
  disabled,
  inputProps,
  placeholder,
}) {

  const isDefined = (content)=>{
    return typeof content !== "undefined" || content  !== null
  }

  const [componentValue, setComponentValue] = React.useState(isDefined(value) ? value :  "");

  useEffect(() => {
    setComponentValue(isDefined(value) ? value :  "");
  }, [value]);

  return (
    <MuiTextField
      variant="outlined"
      value={componentValue}
      onChange={(event) => {
        setComponentValue(event.target.value);
        onChange && onChange(event.target.value);
      }}
      margin="dense"
      error={error}
      label={label}
      helperText={helperText}
      fullWidth={fullWidth}
      multiline={multiline}
      minRows={rows}
      type={type}
      disabled={disabled}
      inputProps={inputProps}
      placeholder={placeholder}
    />
  );
}

TextField.defaultProps = {
  fullWidth: true,
};
