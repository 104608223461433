const products = [
  {
    id: 7,
    sku: 'ABV2131567',
    name: 'Victoria',
    isImmediateDelivery: true,
    active: false,
  },
  {
    id: 8,
    sku: 'ABP128790',
    name: 'Picasso',
    isImmediateDelivery: false,
    active: true,
  },
  {
    id: 9,
    sku: 'ABD5461234',
    name: 'Da Vinci',
    isImmediateDelivery: true,
    active: false,
  },
  {
    id: 10,
    sku: 'ABT121521',
    name: 'Tesla',
    isImmediateDelivery: false,
    active: true,
  },
];
export default products;