import React, { useEffect } from "react";
import "react-phone-number-input/style.css";
import MuiPhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { TextField } from "@material-ui/core";

const CustomInput = React.forwardRef((props, ref) => (
      <TextField
          {...props}
          error={props.error}
          helperText={props.error && (props.helperText || "Lütfen Telefon formatını kontrol ediniz")}
          inputRef={ref}
          fullWidth
          variant="outlined"
          margin="dense"
      >
        {props.children}
      </TextField>
));

export default function PhoneInput({label, onChange, error, value, helperText }) {
  const [componentValue, setComponentValue] = React.useState(value || null);
    useEffect(() => {
    setComponentValue(value);
  }, [value]);

  const handleOnChange = (newValue) => {
    setComponentValue(newValue);
    newValue && isValidPhoneNumber(newValue) && onChange(newValue);
  };

  return (
    <MuiPhoneInput
      label={label}
      helperText={helperText}
      value={componentValue}
      international
      countryCallingCodeEditable={false}
      defaultCountry="TR"
      error={(componentValue && !isValidPhoneNumber(componentValue)) || error}
      inputComponent={CustomInput}
      onChange={handleOnChange}
      style={{marginBottom: 4, alignItems: 'center' }}
    />
  );
}
