import React from "react";
import Header from "./header";
import Footer from "./footer";
import {createTheme, makeStyles, ThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Provider as UseFetchProvider} from "use-http";
import {useRouter} from "hooks/use-router";
import {getCurrentUser} from "../../utils";

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    //height: "100vh",
    overflow: "auto",
    //padding: theme.spacing(5),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const { pathname } = useRouter();

  const options = {
    interceptors: {
      // every time we make an http request, this will run 1st before the request is made
      // url, path and route are supplied to the interceptor
      // request options can be modified and must be returned
      request: async ({ options, url, path, route }) => {
        const oidcUser = getCurrentUser();
        if (oidcUser?.access_token && options.method !== "OPTIONS") {
          options.headers.Authorization = `Bearer ${oidcUser.access_token}`;
        }
        return options;
      },
    },
  };


  return (
    <UseFetchProvider options={options}>
      {pathname === "/register" || pathname === "/require-activation" ? (
        children
      ) : (
          <div>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Header />
              <main className={classes.content}>{children}</main>
              <Footer />
            </ThemeProvider>
          </div>
        )}
    </UseFetchProvider>
  );
}
