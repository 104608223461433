import React, {useEffect, useState} from "react";
import {Avatar, Box, Button, Chip, Divider, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import {CloudDownload, DeleteForever, DragHandle, HelpOutline} from "@material-ui/icons";
import useCustomFetch from "hooks/use-fetch";

import Checkbox from "components/checkbox";
import FormItem from "components/form-item";
import Grid from "components/grid";
import TextField from "components/text-field";
import Select from "components/select";
import Uploader from "../../../components/uploader";
import envConfig from "../../../config/env.config";
import {generateImageURL, generateOriginalImageUrl, getCurrentUser} from "../../../utils";
import {useFieldArray} from "react-hook-form";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import ImageComponent from "material-ui-image";
import {green} from "@material-ui/core/colors";
import PropTypes from "prop-types";


const ProductModule = ({control, index, watch, setValue, definedModuleList, onModuleAdded, supplier, showModuleImages, onModuleImageAdded}) => {
    const user = getCurrentUser();

    const [isStandard, setIsStandard] = useState(false);
    const [rates, setRates] = useState({});
    const [currencyRates, setCurrencyRates] = useState(null);
    const [priceValue, setPriceValue] = useState(null);
    const {get, response} = useCustomFetch();

    const currencies = {
        try: {
            title: 'Türk Lirası',
            code: 'try',
        },
    }

    const exportRate = supplier?.money?.exportRate || 0;
    const currency = currencies[supplier?.money?.currency] || currencies.try;

    const watchModule = watch(`modules.${index}`, {});
    const watchHasExpandedDimension = watch(`modules.${index}.hasExpandedDimensions`, false);
    const watchHasSeatDimension = watch(`modules.${index}.hasSeatDimensions`, false);
    const watchHasFootDimension = watch(`modules.${index}.hasFootDimensions`, false);

    const { fields: fieldsImage, append: appendImage, remove: removeImage, move: fieldsImageMove } = useFieldArray({ control, name: `modules.${index}.images`, keyName: `product_modules_${index}.image_id` });

    const calculateRates = () => {
        if (!priceValue){
            return;
        }
        let price = parseInt(priceValue.toString());
        if (price && currencyRates) {
            let rate = (parseFloat(exportRate) / 100) || 0,
                usd = currencyRates.usd,
                eur = currencyRates.eur,
                gbp = currencyRates.gbp;
            let ratedPrice = price + (price * rate);

            let usdPrice = (ratedPrice / usd).toFixed(2);
            let eurPrice = (ratedPrice / eur).toFixed(2);
            let gbpPrice = (ratedPrice / gbp).toFixed(2);

            setRates({usdPrice, eurPrice, gbpPrice})
        } else {
            setRates({});
        }
    }

    const onModuleChange = (val) => {
        if(!val){
            return;
        }
        if (val.isNew) {
            setValue(`modules.${index}.isNew`, true)
        } else {
            setValue(`modules.${index}.isNew`, false)
        }

        if (val.isNew || val.searched){
            onModuleAdded(val)
        }
        setValue(`modules.${index}.moduleId`, val.id)
    }

    const getCurrencyRates = async () => {
        if (currencyRates) {
            return currencyRates;
        }
        let data = await get(`/i18n-service/currencies/rates`);
        if (response.ok && data) {
            return data.rates;
        } else {
            console.log("Güncel kur verisi bulunamadı!");
        }
    }

    const getImageUploadParams = () => {
        return {
            headers: { "Authorization": `Bearer ${user.access_token}` },
            url: `${envConfig.API_URL}/asset-service/upload/image`
        };
    };

    const handleImages = ({ meta, file, xhr, remove }, status) => {
        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            appendImage(response, response.id)
            onModuleImageAdded(response, response.id);
            remove(); // Remove from upload list for hide previews.
        }
    }

    const DragHandler = SortableHandle(() => (
        <ListItemIcon title="Görselin sırasını değiştirebilirsiniz.">
            <DragHandle color="primary" fontSize="large" cursor="pointer" />
        </ListItemIcon>
    ));

    const SortableImageListContainer = SortableContainer(({ items }) => (
        <List component="div">
            {items.map((image, index) => (<SortableImageItem key={image.id} index={index} image={image} imageIndex={index} />))}
        </List>
    ));

    const SortableImageItem = SortableElement(({image, imageIndex}) => {
        const aspectRatio = image.width / image.height;
        return (
            <ListItem key={imageIndex} ContainerComponent="div">
                <ListItemAvatar>
                    <Link href={generateImageURL(image, 800)} target="_blank">
                        <ImageComponent cover={true} aspectRatio={aspectRatio} style={{width: 200}} src={generateImageURL(image, 200)}/>
                    </Link>
                </ListItemAvatar>
                <ListItemText secondary={`Modül Görseli #${imageIndex+1}`}  style={{marginLeft: 10}}  />
                <DragHandler />
                <Button style={{color: green[600]}} href={generateOriginalImageUrl(image)} target="_blank"><CloudDownload/></Button>
                <Button onClick={() => { removeImage(imageIndex); }}><DeleteForever color="secondary" /></Button>
            </ListItem>
        )
    });

    const onImagesSortEnd = ({ oldIndex, newIndex }) => {
        fieldsImageMove(oldIndex, newIndex)
    };

    const hasImageUploadValidationError = () =>{
        return (
            control._formState.errors.modules
            &&
            control._formState.errors.modules[index]
            &&
            control._formState.errors.modules[index].images
            &&
            control._formState.errors.modules[index].images.type === 'min'
        );
    }


    useEffect(() => {
        getCurrencyRates().then((data) => {
            setCurrencyRates(data)
            if (watchModule && watchModule.price) {
                setPriceValue(watchModule.price)
            }
            if (watchModule && watchModule.isStandard) {
                setIsStandard(watchModule.isStandard)
            }
        });
        return () => {
            setCurrencyRates({});
            setRates({});
        }
    }, [])

    useEffect(() => {
        calculateRates()
    }, [priceValue])

    return (
        <Grid container spacing={1}>
            <Grid item md={12}>
                <FormItem control={control} name={`modules.${index}.moduleId`} onChange={onModuleChange}>
                    <Select freeSolo={true} label="Modül Adı" options={definedModuleList} searchable={true} searchType="moduleItem" displayMember="title" newItemDescription="yeni modül ekle"/>
                </FormItem>
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.price`} onChange={(value) => setPriceValue(value)}>
                    <TextField label={`Modül Fiyatı (${currency.title} ${currency.code.toUpperCase()})`} type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={8}>
                <Box display={"flex"} alignItems={"center"} style={{height: 52}}>
                    {
                        rates && rates.usdPrice &&
                        (
                            <Chip avatar={<Avatar>$</Avatar>} label={`${rates.usdPrice} Dolar`} title={`Dolar kuru: ${currencyRates.usd}`} variant="outlined" color="primary" style={{marginRight: 10}}/>
                        )
                    }
                    {
                        rates && rates.eurPrice &&
                        (
                            <Chip avatar={<Avatar>€</Avatar>} label={`${rates.eurPrice} Euro`} title={`Euro kuru: ${currencyRates.eur}`} variant="outlined" color="secondary" style={{marginRight: 10}}/>
                        )
                    }
                    {
                        rates && rates.gbpPrice &&
                        (
                            <Chip avatar={<Avatar>£</Avatar>} label={`${rates.gbpPrice} Sterlin`} title={`Sterlin kuru: ${currencyRates.gbp}`} variant="outlined" color="default" style={{marginRight: 10}}/>
                        )
                    }
                    <Box style={{marginTop: 3}} title="Desteklenen para birimleri ve dönüşümleri burada görünecektir.">
                        <HelpOutline color={"action"}/>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={12}>
                <small style={{color: '#686868'}}>Fiyat dönüşümü, TCMB güncel kur değerleri ve ayarlarınızda bulunan
                    "İhracat Farkı Yüzdesi" değeri(<strong title={"Mevcut İhracat Farkı Yüzdesi"}>%{exportRate}</strong>) kullanılarak hesaplanmaktadır.</small>
            </Grid>

            <Grid item md={6}>
                <Box style={{paddingTop: 5}}>
                    <FormItem control={control} name={`modules.${index}.isStandard`} onChange={setIsStandard}>
                        <Checkbox label="Modül, takım içeriğinde standart olarak var mı?"/>
                    </FormItem>
                </Box>

            </Grid>
            <Grid item md={6}>
                <FormItem control={control} name={`modules.${index}.standardQuantity`}>
                    <TextField label="Takım içeriğindeki Adeti" type="number" disabled={!isStandard}
                               inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={12}>
                <small style={{color: '#686868'}}>Ürünün <strong>standart satış fiyatı</strong> takım içeriğinde öntanımlı olarak seçilen modüllerin fiyat ve adeti kullanılarak hesaplanmaktadır.</small>
            </Grid>

            <Grid item md={12}>
                <Typography style={{ paddingBottom: 8, fontSize: 16 }} variant={"subtitle2"} color="textSecondary">Ölçüler</Typography>
                <Divider style={{marginBottom: 5}} />
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.dimensions.width`}>
                    <TextField label="Genişlik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.dimensions.height`}>
                    <TextField label="Yükseklik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.dimensions.depth`}>
                    <TextField label="Derinlik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.packaging.quantity`}>
                    <TextField label="Paketleme Adedi" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.packaging.weight`}>
                    <TextField label="Ağırlık (kg)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.packaging.desi`}>
                    <TextField label="Desi" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.hasExpandedDimensions`}>
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Açılır Boyut girmek istiyorum</Typography>)} style={{textColor: 'red'}} size="small" />
                </FormItem>
                {
                    watchHasExpandedDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.expandedDimensions.width`}>
                                    <TextField label="Açılır Genişlik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.expandedDimensions.height`}>
                                    <TextField label="Açılır Yükseklik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.expandedDimensions.depth`}>
                                    <TextField label="Açılır Derinlik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.hasSeatDimensions`}>
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Oturum Boyutu girmek istiyorum</Typography>)} size="small" />
                </FormItem>
                {
                    watchHasSeatDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.seatDimensions.width`}>
                                    <TextField label="Oturum Genişliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.seatDimensions.height`}>
                                    <TextField label="Oturum Yüksekliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.seatDimensions.depth`}>
                                    <TextField label="Oturum Derinliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`modules.${index}.hasFootDimensions`}>
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Ayak Boyutu girmek istiyorum</Typography>)} size="small" />
                </FormItem>
                {
                    watchHasFootDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.footDimensions.height`}>
                                    <TextField label="Ayak Yüksekliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`modules.${index}.footDimensions.diameter`}>
                                    <TextField label="Ayak Çapı (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>



            {
                showModuleImages && (
                    <Grid item md={12}>
                        <Typography style={{ paddingBottom: 8, fontSize: 16 }} variant={"subtitle2"} color="textSecondary">Görseller</Typography>
                        <Divider style={{marginBottom: 5}} />
                        {
                            fieldsImage.length === 0 && hasImageUploadValidationError() && (
                                <Typography style={{paddingBottom: 8 }} align={"center"} variant={"subtitle2"} color="secondary">Modül görseli gereklidir.</Typography>
                            )
                        }
                        {
                            fieldsImage.length > 0 && (
                                <div>
                                    {
                                        fieldsImage.length > 1 && (<Typography style={{ paddingTop: 8, paddingBottom: 8 }} align={"center"} variant={"subtitle2"} color="textSecondary">Görselleri sıralayabilirsiniz.</Typography>)
                                    }
                                    <SortableImageListContainer
                                        items={fieldsImage}
                                        onSortEnd={onImagesSortEnd}
                                        useDragHandle={true}
                                        lockAxis="y"
                                    />
                                </div>
                            )
                        }
                        <Uploader
                            accept="image/*"
                            maxFilesize={10}
                            //minSizeBytes={1024*1024}
                            maxSizeBytes={10*1024*1024}
                            getUploadParams={getImageUploadParams}
                            onChangeStatus={handleImages}
                            inputContent={"Modül görsellerini seçin"}
                            inputWithFilesContent={"Daha fazla modül görseli ekleyin"}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
}

ProductModule.defaultProps = {
    supplier: null,
    definedModuleList: [],
    showModuleImages: false,
    onModuleAdded: () => {},
    onModuleImageAdded: () => {},
}

ProductModule.propTypes = {
    supplier: PropTypes.object,
    definedModuleList: PropTypes.array,
    showModuleImages: PropTypes.bool,
    onModuleAdded: PropTypes.func,
    onModuleImageAdded: PropTypes.func,
}

export default ProductModule;