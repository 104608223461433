import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useCustomFetch from "../../hooks/use-fetch";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Select({
  options,
  displayMember,
  id,
  onChange,
  label,
  error,
  value,
  helperText,
  groupBy,
  freeSolo,
  newItemDescription,
  searchable,
  searchType
}) {

  const [loading, setLoading] = React.useState(false);

  const [newOptions, setNewOptions] = React.useState([]);

  const [componentValue, setComponentValue] = React.useState(newOptions.find((item) => item.id === value) || null);
  const [inputValue, setInputValue] = React.useState("");

  const { post, response } = useCustomFetch();

  const resetOptions = ()=>{
      setNewOptions(options.filter((option)=> option[displayMember] !== null ))
  }


  const handleSearch = async (e)=>{
    if (!freeSolo){
          return false;
      }
    if (e.target.value.length <= 2) {
        resetOptions()
        return false;
    }
    setNewOptions([]);
    setLoading(true);

    if (searchable){
        const data = await post("/product-service/viewwords/search", {
            lang: "tr",
            term: e.target.value,
            type: searchType //"moduleItem",
        });
        if (response.ok && data && data.length > 0){
            setNewOptions(data.map((key) => ({ id: key.id, [displayMember]: key.value, searched: true })));
        }else{
            let value = e.target.value.trim();
            let exists = newOptions.find((opt) => opt[displayMember] === value);
            console.log(exists);
            if (!exists){
                setNewOptions([...options, { id: value, [displayMember]: value, isNew: true, groupName: 'Onay Bekleyen' }])
            }
        }
    }else{
        let value = e.target.value.trim();
        let exists = newOptions.find((opt) => opt[displayMember] === value);
        if (!exists){
            setNewOptions([...options, { id: value, [displayMember]: value, isNew: true, groupName: 'Onay Bekleyen' }])
        }
    }
    setLoading(false)
  }

  useEffect(() => {
    const val = newOptions.find((item) => item && item.id && item.id.toString() === value);
    if (val){
      setComponentValue(val);
    }
  }, [value, newOptions]);

  useEffect(() => {
    setNewOptions(options.filter((option)=> option[displayMember] !== null ))
  }, [options]);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      noOptionsText={"seçenekler için arama yapın..."}
      options={newOptions}
      value={componentValue || null}
      onChange={(_, newValue) => {
        setComponentValue(newValue);
        onChange(newValue);
      }}
      inputValue={inputValue || ""}
      onInputChange={ (e, value) => {
        setInputValue(value);
      }}
      getOptionLabel={(option) => option[displayMember]}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionDisabled={(option) => option.disabled}
      renderOption={(option)=> {
        return (
            <React.Fragment>
              {option[displayMember]}
              {
                option.isNew && newItemDescription !== null && (<i style={{marginLeft: 6, color: '#828282'}}> · {newItemDescription}</i>)
              }
            </React.Fragment>
        )
      }}
      groupBy={groupBy}
      id={id}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          margin="dense"
          variant="outlined"
          onChange={handleSearch}
          onBlur={resetOptions}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {loading ? (
                      <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  displayMember: PropTypes.string,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
  newItemDescription: PropTypes.string,
  searchable: PropTypes.bool,
  searchType: PropTypes.string,
};

Select.defaultProps = {
  id: "select",
  onChange: () => { },
  options: [],
  displayMember: "label",
  label: "Select",
  freeSolo: false,
  newItemDescription: null,
  searchable: false,
  searchType: "wordItem",
};
