import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Card, Checkbox, Chip, IconButton, Link, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography} from '@material-ui/core';
import {Archive, Edit, FileCopy, Print, Unarchive, Visibility} from "@material-ui/icons";
import {blue, common, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import useCustomFetch from "../../../hooks/use-fetch";
import Popup from "../../../components/popup";

const ProductTable = ({ products, onPreview, onRefresh, total, page, setPage, limit, setLimit, onShowPriceListDialog }) => {
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const { post, put, response } = useCustomFetch();

  const productStatuses = [
    {
      title: 'Onay Bekliyor',
      color: common.black,
      background: yellow[500]
    },
    {
      title: 'Yayında',
      color: common.white,
      background: green[500]
    },
    {
      title: 'Arşivlenmiş',
      color: common.white,
      background: grey[500]
    },
    {
      title: 'Onaylanmadı',
      color: common.white,
      background: red[500]
    }
  ]


  const handleSelectAll = (event) => {
    let newSelectedProductIds;

    if (event.target.checked) {
      newSelectedProductIds = products.map((p) => p.id);
    } else {
      newSelectedProductIds = [];
    }

    setSelectedProductIds(newSelectedProductIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedProductIds.indexOf(id);
    let newSelectedProductIds = [];

    if (selectedIndex === -1) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds, id);
    } else if (selectedIndex === 0) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds.slice(1));
    } else if (selectedIndex === selectedProductIds.length - 1) {
      newSelectedProductIds = newSelectedProductIds.concat(selectedProductIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProductIds = newSelectedProductIds.concat(
          selectedProductIds.slice(0, selectedIndex),
          selectedProductIds.slice(selectedIndex + 1)
      );
    }

    setSelectedProductIds(newSelectedProductIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleArchiveProduct = async (product)=> {

    let isArchived = (product.deletedAt !== null);

    let question_text = isArchived ? 'arşivden çıkarmak' : 'arşivlemek';
    let message_success_text = isArchived ? 'arşivden çıkarıldı' : 'arşivlendi';
    let message_error_text = isArchived ? 'arşivden çıkarılamadı' : 'arşivlenemedi';

    let authorize = await Popup.fire({
      html: `<strong>${product.modelName}</strong> ürününü ${question_text} istediğinizden emin misiniz?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    });

    if (authorize.isConfirmed) {
      await put(`product-service/products/${product.id}/archive`, {});
      if (response.ok) {
        await Popup.fire({
          icon: "success",
          title: `${product.modelName} ürünü ${message_success_text}.`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 3000,
        });

        return onRefresh();

      }else{
        await Popup.fire({
          icon: "error",
          title: `${product.modelName} ürünü ${message_error_text}.`,
          text: "Lütfen tekrar deneyin.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 3000,
        });
      }

    }
  }

  const copyProduct = async (product)=>{

    let authorize = await Popup.fire({
      html: `<strong>${product.modelName}</strong> ürününü kopyalamak istediğinizden emin misiniz?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    });

    if (authorize.isConfirmed) {
      let data = await post(`product-service/products/${product.id}/duplicate`, {});

      if (response.ok && data) {
        await Popup.fire({
          icon: "success",
          title: `${product.modelName} ürünü kopyalandı.`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 3000,
        }).then(()=>{
          window.location.reload();
        });

      }else{
        await Popup.fire({
          icon: "error",
          title: `${product.modelName} ürünü kopyalanamadı.`,
          text: "Lütfen tekrar deneyin.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  return (
    <Card>
      <Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                    checked={selectedProductIds.length === products.length}
                    color="primary"
                    indeterminate={
                      selectedProductIds.length > 0
                      && selectedProductIds.length < products.length
                    }
                    onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Kod</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Adı</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Kategori</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Hemen Al</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Durum</TableCell>
              <TableCell style={{fontWeight: 'bold'}} align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product,i) => (
                <TableRow hover key={i} id={product.id} selected={selectedProductIds.indexOf(product.id) !== -1}>
                  <TableCell padding="checkbox">
                    <Checkbox
                        checked={selectedProductIds.indexOf(product.id) !== -1}
                        onChange={(event) => handleSelectOne(event, product.id)}
                        value="true"
                    />
                  </TableCell>
                  <TableCell><Link href={`/products/${product.id}/edit`}>{product.sku}</Link></TableCell>
                  <TableCell>
                    <Link href={`/products/${product.id}/edit`}>{product.modelName}</Link>
                  </TableCell>
                  <TableCell><Typography variant={"body2"}>{product.category}</Typography></TableCell>
                  <TableCell>
                    <Chip size="small" style={{
                      backgroundColor: grey[300],
                      color: common.black
                    }} label={"Mevcut Değil"}/>
                  </TableCell>
                  <TableCell>
                    {
                      (!product.active && product.deletedAt !== null) && (
                            <Chip size="small" style={{
                              backgroundColor: productStatuses[2].background,
                              color: productStatuses[2].color
                            }} label={productStatuses[2].title}/>
                        )
                    }
                    {
                        (!product.active && product.deletedAt === null)&& (
                            <Chip size="small" style={{
                              backgroundColor: productStatuses[0].background,
                              color: productStatuses[0].color
                            }} label={productStatuses[0].title}/>
                        )
                    }
                    {
                        product.active && (
                            <Chip size="small" style={{
                              backgroundColor: productStatuses[1].background,
                              color: productStatuses[1].color
                            }} label={productStatuses[1].title}/>
                        )
                    }
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton onClick={()=>{ onShowPriceListDialog(product.id) }} aria-label="preview" title={"Fiyat Listesi Yazdır"}>
                        <Print fontSize="small" style={{color: green["800"]}}/>
                      </IconButton>
                      <IconButton onClick={()=>{ onPreview(product) }} aria-label="preview" title={"Önizle"}>
                        <Visibility fontSize="small" style={{color: blue["500"]}}/>
                      </IconButton>
                      <IconButton aria-label="edit" href={`/products/${product.id}/edit`} title={"Düzenle"}>
                        <Edit fontSize="small" color="primary" />
                      </IconButton>
                      <IconButton aria-label="copy" onClick={()=>{ copyProduct(product) }} title={"Kopyala"}>
                        <FileCopy fontSize="small" style={{color: green["500"]}} />
                      </IconButton>
                      {
                          product.deletedAt === null && (
                              <IconButton aria-label="archive" onClick={()=> { toggleArchiveProduct(product) } } title={"Ürünü Arşivle"}>
                                <Archive fontSize="small" style={{color: red[500]}} />
                              </IconButton>
                          )
                      }
                      {
                        product.deletedAt !== null && (
                            <IconButton aria-label="unarchive" onClick={()=> { toggleArchiveProduct(product) } } title={"Ürünü Arşivden Çıkar"}>
                              <Unarchive fontSize="small" style={{color: orange[500]}} />
                            </IconButton>
                        )
                      }

                    </Box>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        labelRowsPerPage="Sayfa başına göster:"
        labelDisplayedRows={({ from, to, count }) => `${from} / ${to} ürün`}
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
      />
    </Card>
  );
};

ProductTable.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductTable;