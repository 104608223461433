export const tabs = [
    {
        id: 'general',
        title: 'GENEL',
        fields: [
            'modelName',
            'categoryId',
            'isModule',
        ]
    },
    {
        id: 'features',
        title: 'ÖZELLİKLER',
        fields: [
            'features',
        ]
    },
    {
        id: 'modules',
        title: 'MODÜLLER',
        fields: [
            'modules',
        ]
    },
    {
        id: 'single',
        title: 'FİYAT ve ÖLÇÜ',
        fields: [
            'single'
        ]
    },
    {
        id: 'shipping',
        title: 'TESLİMAT',
        fields: [
            'productionTime'
        ]
    },
    {
        id: 'images',
        title: 'GÖRSELLER',
        fields: [
            'images'
        ]
    },
    {
        id: 'videos',
        title: 'VİDEOLAR',
        fields: [
            'videos'
        ]
    },
    {
        id: 'documents',
        title: 'DOKÜMANLAR',
        fields: [
            'documents',
        ]
    },
    {
        id: 'designer',
        title: 'TASARIMCI',
        fields: [
            'designerId'
        ]
    }
]