import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

export default function FormItem({ control, name, children, onChange, defaultValue }) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange: controllerOnChange, value},
        fieldState: { error },
      }) => React.cloneElement(children, {
          value: value,
          onChange: (val, isNew=false) => {
              controllerOnChange({ target: { value: val } });
              onChange && onChange(val, isNew);
          },
          error: !!error,
          helperText: error ? error.message : null,
      })

      }
    />
  );
}

FormItem.propTypes = {
    control: PropTypes.any,
    name: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
};
