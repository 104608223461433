let client_host_address = process.env.REACT_APP_OIDC_CLIENT_HOST;
const config = {
  client_id: "supplierapp",
  redirect_uri: `${client_host_address}/authentication/callback`,
  response_type: "token id_token",
  post_logout_redirect_uri: `${client_host_address}`,
  scope: "openid profile email offline_access profile",
  authority: "https://auth.dataware.com.tr/auth/realms/dw_supplier",
  silent_redirect_uri: `${client_host_address}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  triggerAuthFlow: true,
  filterProtocolClaims: true,
  monitorSession: true,
};
export default config;