import React from 'react';
import {Box, Container} from '@material-ui/core'
import Typography from "components/typography";
import Logo from "../../assets/logo.svg";

function RequireActivation(props){
    return (
        <Container>
            <Box style={{margin:"200px auto 0", width: 200, height: 70, position: 'relative'}}>
                <img style={{width: '100%'}} src={Logo} alt="Dataware Production" />
                <span style={{position: 'absolute', bottom: 5, right: 0, fontSize: '13px', color: '#00436e'}}>ÜRETİM</span>
            </Box>
            <Typography variant="h5" align={"center"} style={{margin: "10px 0"}}>ŞİRKETİNİZ ONAY BEKLİYOR.</Typography>
            <Typography variant="h5" align={"center"} style={{margin: "10px 0"}}>Dataware şirketiniz hakkında paylaşmış olduğunuz bilgileri inceliyor.</Typography>
            <Typography variant="h6" align={"center"} style={{margin: "10px 0"}}>Sistem onayı gerçekleştiğinde e-posta ile bilgilendirileceksiniz.</Typography>
      </Container>
    );
}
export default RequireActivation;