import React, {useEffect, useState} from "react";
import {
  Box,
  Card,
  CardContent,
  Container, TextField, Select,
  Typography, MenuItem, InputLabel, FormControl, CircularProgress
} from "@material-ui/core";
import ordersData from './data';
import Grid from "../../components/grid";
import Button from "@material-ui/core/Button";
import OrderTable from "./OrderTable";
import useCustomFetch from "../../hooks/use-fetch";
import TempOrderTable from "./TempOrderTable";

export default function Orders(props) {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = React.useState('0');
  const [shop, setShop] = React.useState('0');
  const [status, setStatus] = React.useState('0');
  const orders = React.useRef([]);

  const {get, response} = useCustomFetch();
  const getOrders = async () => {
    let data = await get('/order-service/orders/temps');
    if (response && response.ok){
      orders.current = data;
    }else{
      orders.current = ordersData;
    }

  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleShopChange = (event) => {
    setShop(event.target.value);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(()=>{
    if (!loading) {
      return;
    }
    getOrders().then(()=>{
      setLoading(false)
    })
  }, [loading])

  return (
      loading
          ?
          <Box textAlign={"center"}>
            <Box><CircularProgress/></Box>
            <br/>
            <Box>Siparişleriniz yükleniyor, lütfen bekleyin...</Box>
          </Box>
          :
      <Container maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4">Siparişler</Typography>
        </Box>
        <Card>
          <CardContent>
            <Grid container  spacing={3} style={{alignItems: 'center'}}>
              <Grid item xs={12} sm={4}>
                <TextField variant="outlined" size="small" fullWidth placeholder="Sipariş Kodu"/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="orders_search_category_label">Kategori</InputLabel>
                  <Select value={category} onChange={handleCategoryChange} label="Kategori" labelId="orders_search_category_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                    <MenuItem value={10}>Oturma Odası</MenuItem>
                    <MenuItem value={40}>Koltuk Takımı</MenuItem>
                    <MenuItem value={50}>Köşe Koltuk Takımı</MenuItem>
                    <MenuItem value={20}>Yatak Odası</MenuItem>
                    <MenuItem value={30}>Yemek Odası</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="orders_search_shop_label">Mağaza</InputLabel>
                  <Select value={shop} onChange={handleShopChange} label="Mağaza" labelId="orders_search_shop_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth  size="small">
                  <InputLabel id="orders_search_status_label">Durum</InputLabel>
                  <Select value={status} onChange={handleStatusChange} label="Durum" labelId="orders_search_status_label">
                    <MenuItem value={0}><em>Seçin</em></MenuItem>
                    <MenuItem value={10}>Devam Ediyor</MenuItem>
                    <MenuItem value={20}>Sevkiyatta</MenuItem>
                    <MenuItem value={30}>Teslim Edildi</MenuItem>
                    <MenuItem value={40}>İptal Edilmiş</MenuItem>
                    <MenuItem value={50}>Tamamlanmış</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant="outlined" fullWidth size="small" label="Sipariş Tarihi Başlangıç" type="date" defaultValue="2021-01-01" InputLabelProps={{shrink: true,}}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant="outlined" fullWidth size="small" label="Sipariş Tarihi Bitiş" type="date" defaultValue="2021-06-01" InputLabelProps={{shrink: true,}}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button fullWidth color="primary" variant="contained">Ara</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {
          orders.current.length === 0 && (
                <Box style={{ paddingTop: 10,position:'relative'}}>
                  <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{background:'#fff',opacity:.7, zIndex: 4, position:'absolute', right:0, left:0, top:0, bottom:0}}/>
                  <Box alignItems={"center"} justifyContent={"center"} alignContent={"center"} flexDirection={"column"} display={"flex"} style={{zIndex: 5, position:'absolute', right:0, left:0, top:0, bottom:0}}>
                    <Typography variant="h6" style={{color: '#383838'}}>Örnek veridir.</Typography>
                    <Typography variant="subtitle2" style={{color: '#383838'}}>Henüz siparişiniz bulunmamaktadır.</Typography>
                  </Box>
                  <TempOrderTable orders={ordersData}/>
                </Box>
            )
        }
        {
            orders.current.length > 0 && (<Box style={{ paddingTop: 10}}><OrderTable orders={orders.current}/></Box>)
        }
      </Container>
  );
}
