import React, {useCallback, useEffect, useState} from "react";
import {Box, CardContent, CardHeader, Chip, CircularProgress, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import Typography from "components/typography";
import {makeStyles} from "@material-ui/core/styles";
import {blue, grey} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {Edit} from "@material-ui/icons";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCustomFetch from "hooks/use-fetch";
import {getCurrentUser} from "../../utils";
import Popup from "components/popup";
import Container from "components/container";
import Grid from "components/grid";
import Card from "components/card";
import FormItem from "components/form-item";
import Select from "components/select";
import TextField from "components/text-field";
import Checkbox from "components/checkbox";

import {companyPricesForm} from './validation'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        "&:last-child": {
            padding: 0,
        },
    },
    cardHeaderRoot: {
        background: "#046393",// blueGrey[50],
        color: "white",
    },
    chip: {
        marginBottom: 10,
        padding: 3,
        display: 'inline-block',
        background: blue[50],
    },
    List: {
        padding: 0,
        "& > .MuiListItem-root": {
            cursor: "pointer",
        },
    },
}));

export default function Company(){

    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [supplier, setSupplier] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [currencies] = useState([
        {
            id: 'try',
            title: 'Türk Lirası ‧ TRY ‧ ₺'
        },
        {
            id: 'usd',
            title: 'Dolar ‧ USD ‧ $',
            disabled: true
        },
        {
            id: 'eur',
            title: 'Euro ‧ EUR ‧ €',
            disabled: true
        },
        {
            id: 'gbp',
            title: 'Sterlin ‧ GBP ‧ £',
            disabled: true
        }
    ]);

    const { get, put, response } = useCustomFetch();
    const currentUser = getCurrentUser();
    const supplier_id = currentUser.profile?.supplier_id;

    const {control, handleSubmit, reset, watch, setValue} = useForm({
        resolver: yupResolver(companyPricesForm)
    })

    const watchUseFixedExchangeRates = watch("money.useFixedExchangeRates");

    const resetExchangeRates = useCallback(()=>{
        if(!watchUseFixedExchangeRates){
            setValue("money.exchangeRates", {usd: "", eur: "", gbp: ""});
        }
    }, [watchUseFixedExchangeRates, setValue])

    const onFormSubmit = async (values)=>{
        if(!values.money.useFixedExchangeRates){
            values.money.exchangeRates = null;
        }

        let postData = {
            ...supplier,
            ...values
        };

        let data = await put(`/supplier-service/suppliers/${supplier_id}`, postData)

        if (response.ok && data) {
            Popup.fire({
                icon: "success",
                title: "Ayarlarınız güncellendi.",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 3000,
            }).then(()=>{
                Popup.close();
                window.location.reload();
            })
        }else{
            Popup.fire({
                icon: "error",
                title: "Ayarlarınız güncellenemedi!",
                text: "Lütfen tekrar deneyin.",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 3000,
            }).then(()=>{
                Popup.close();
            })
        }
    }
    const onFormError = (errors)=>{
        //
    }

    const onCurrencyChange = (currency)=>{
        if (currency){
            setValue("money.currency", currency.id);
        }else{
            setValue("money.currency", "");
        }
    }


    useEffect(()=>{
        resetExchangeRates()
    }, [resetExchangeRates])




    useEffect( ()=>{
        const getData = async()=>{
            if (!loading){
                return;
            }
            const supplierData = await get(`/supplier-service/suppliers/GetById/${supplier_id}?_${Date.now()}`);
            // TODO: Make single type endpoint for a company type.

            const [companyTypes, roles] = await Promise.all([get("/i18n-service/organization/companytypes"), get("/i18n-service/organization/roles")])


            if (response.ok && supplierData){
                let country = supplierData.location.countryId ? await get(`/i18n-service/countries/${supplierData.location.countryId}`) : null;
                let state = supplierData.location.stateId ? await get(`/i18n-service/states/${supplierData.location.stateId}`) : null;
                let city = supplierData.location.cityId ? await get(`/i18n-service/cities/${supplierData.location.cityId}`) : null;

                let tax_country = supplierData.taxInformation.countryId ? await get(`/i18n-service/countries/${supplierData.taxInformation.countryId}`) : null;
                let tax_state = supplierData.taxInformation.stateId ? await get(`/i18n-service/states/${supplierData.taxInformation.stateId}`) : null;
                let tax_city = supplierData.taxInformation.cityId ? await get(`/i18n-service/cities/${supplierData.taxInformation.cityId}`) : null;
                // TODO: Make single type endpoint for a tax department.
                let state_departments = supplierData.taxInformation.stateId ? await get(`/i18n-service/states/${supplierData.taxInformation.stateId}/departments/select`) : null;

                let tax_department = (state_departments && supplierData.taxInformation?.departmentId) ? state_departments.find((dept) => dept.id === parseInt(supplierData.taxInformation.departmentId)) : null;
                let companyType = (companyTypes && supplierData.companyOwner?.companyType) ? companyTypes.find((comp) => comp.id === supplierData.companyOwner.companyType) : null;
                let departmentType = (roles && supplierData.companyOwner?.title) ?  roles.find((dept) => dept.id === supplierData.companyOwner.title) : null;

                if (response.ok){
                    supplierData.location.country_name =  country ? country.translations['tr'] : null;
                    supplierData.location.state_name = state ? state.name : null;
                    supplierData.location.city_name = city ? city.name : null;

                    supplierData.taxInformation.country_name = tax_country ? tax_country.translations['tr'] : null;
                    supplierData.taxInformation.state_name = tax_state ? tax_state.name : null;
                    supplierData.taxInformation.city_name = tax_city ? tax_city.name : null;
                    supplierData.taxInformation.department_name = tax_department ? tax_department.name : null;

                    supplierData.commercial.company_type = companyType ? companyType.translations.find((t) => t.lang === 'tr').value : null;
                    supplierData.companyOwner.department_type = departmentType ? departmentType.translations.find((t) => t.lang === 'tr').value : null;
                }

                setSupplier(supplierData);

                reset({
                    money: supplierData.money !== null ? supplierData.money : {
                        currency: '',
                        exportRate: '',
                        storeMinimumSalesRate: '',
                        useFixedExchangeRates: false,
                        exchangeRates: null,
                    },
                })
            }else{
                console.log("supplier data couldn't fetch.")
            }
            setLoading(false)
        }
        getData();
    }, [loading])



    return (
        loading
        ?
        <Box textAlign={"center"}>
            <Box><CircularProgress /></Box>
            <Box>Bilgileriniz yükleniyor, lütfen bekleyin...</Box>
        </Box>
        :
        <Container maxWidth="md">
            <Box mb={2} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant="h4">Firma Bilgileri</Typography>
                    <Typography variant="subtitle1">Kurumsal bilgilerinizi düzenleyebilir, ayarlarınızı güncelleyebilirsiniz.</Typography>
                </Box>
                <Button href="/register" color={"primary"} variant="outlined" size={"small"}><Edit/> DÜZENLE</Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card style={{marginBottom: 8}}>
                        <CardContent className={classes.root}>
                            <List className={classes.List}>
                                <ListItem onClick={()=>{ setActiveTab(1) }} style={{background: activeTab === 1 ? '#046393' : 'none', color: activeTab === 1 ? '#fff' : '#000'}}>
                                    <ListItemText primary={"Kurumsal"}/>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem onClick={()=>{ setActiveTab(2) }} style={{background: activeTab === 2 ? '#046393' : 'none', color: activeTab === 2 ? '#fff' : '#000'}}>
                                    <ListItemText primary={"Yetkili"}/>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem onClick={()=>{ setActiveTab(3) }} style={{background: activeTab === 3 ? '#046393' : 'none', color: activeTab === 3 ? '#fff' : '#000'}}>
                                    <ListItemText primary={"Konum"}/>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem onClick={()=>{ setActiveTab(4) }} style={{background: activeTab === 4 ? '#046393' : 'none', color: activeTab === 4 ? '#fff' : '#000'}}>
                                    <ListItemText primary={"Vergilendirme"}/>
                                </ListItem>
                                <Divider component="li" />
                                <ListItem onClick={()=>{ setActiveTab(5) }} style={{background: activeTab === 5 ? '#046393' : 'none', color: activeTab === 5 ? '#fff' : '#000'}}>
                                    <ListItemText primary={"Oranlar"}/>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                    <Grid item xs={12} style={{display: activeTab === 1 ? 'inline' : 'none'}}>
                        <Card style={{marginBottom: 8}}>
                            <CardHeader classes={{root: classes.cardHeaderRoot }} titleTypographyProps={{variant: 'h6'}} title="Kurumsal Bilgiler" />
                            <Divider />
                            <CardContent className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={"Marka Adı"} secondary={supplier?.commercial?.brand ||  "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Ticari Ünvan"} secondary={supplier?.commercial?.title ||  "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Şirket Türü"} secondary={supplier?.commercial?.company_type ||  "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Telefon"} secondary={supplier?.commercial?.phone || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"E-posta Adresi"} secondary={supplier?.commercial?.email || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"İnternet Sitesi"} secondary={supplier?.commercial?.website || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{display: activeTab === 2 ? 'inline' : 'none'}}>
                        <Card style={{marginBottom: 8}}>
                            <CardHeader classes={{root: classes.cardHeaderRoot }} titleTypographyProps={{variant: 'h6'}} title="Firma Sahibi"/>
                            <Divider />
                            <CardContent className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={"Adı Soyadı"} secondary={supplier?.companyOwner?.name + " " + supplier?.companyOwner?.surname || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Ünvanı"} secondary={supplier?.companyOwner?.department_type || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Telefon"} secondary={supplier?.companyOwner?.phone || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"E-posta Adresi"} secondary={supplier?.companyOwner?.email || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{display: activeTab === 3 ? 'inline' : 'none'}}>
                        <Card style={{marginBottom: 8}}>
                            <CardHeader classes={{root: classes.cardHeaderRoot }} titleTypographyProps={{variant: 'h6'}} title="Adres Bilgileri"/>
                            <Divider />
                            <CardContent className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={"Ülke"} secondary={supplier?.location?.country_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Şehir"} secondary={supplier?.location?.state_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"İlçe"} secondary={supplier?.location?.city_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Adres"} secondary={supplier?.location?.address || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{display: activeTab === 4 ? 'inline' : 'none'}}>
                        <Card style={{marginBottom: 8}}>
                            <CardHeader classes={{root: classes.cardHeaderRoot }} titleTypographyProps={{variant: 'h6'}} title="Vergilendirme Bilgileri"/>
                            <Divider />
                            <CardContent className={classes.root}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={"Ülke"} secondary={supplier?.taxInformation?.country_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Şehir"} secondary={supplier?.taxInformation?.state_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"İlçe"} secondary={supplier?.taxInformation?.city_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Vergi Dairesi"} secondary={supplier?.taxInformation?.department_name || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Vergi Numarası"} secondary={supplier?.taxInformation?.number || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText primary={"Vergi Mükellefi Adresi"} secondary={supplier?.taxInformation?.address || "Bilgi mevcut değil"}/>
                                    </ListItem>
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{display: activeTab === 5 ? 'inline' : 'none'}}>
                        <Card style={{marginBottom: 8}}>
                            <CardHeader classes={{root: classes.cardHeaderRoot }} titleTypographyProps={{variant: 'h6'}} title="Para Birimi ve Oranlar"/>
                            <Divider />
                            <CardContent>
                                <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
                                    <Box>
                                        <Chip classes={{root: classes.chip }} variant="outlined" size="small" label="Para Birimi" />
                                        <p style={{fontSize: 13, marginTop: 0, marginBottom: 10}}>Ürünlerinizin fiyat bilgisinde kullanılacak ana para biriminizi seçin.</p>
                                        <FormItem control={control} name="money.currency" onChange={onCurrencyChange}>
                                            <Select options={currencies} displayMember="title" id="currency" label="Para Birimi"/>
                                        </FormItem>
                                    </Box>
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                    <Box>
                                        <Chip classes={{root: classes.chip }} variant="outlined" size="small" label="Döviz Kuru" />
                                        {
                                            !watchUseFixedExchangeRates && (
                                                <p style={{fontSize: 13, margin: 0}}>Merkez Bankası verileri ile en son iş günündeki güncel döviz kurları dikkate alınarak fiyat hesaplaması yapılacaktır.</p>
                                            )
                                        }
                                        {
                                            watchUseFixedExchangeRates && (
                                                <p style={{fontSize: 13, marginTop: 0, marginBottom: 5}}>Fiyat hesaplamasında aşağıda tanımladığınız döviz kuru değerleri dikkate alınacaktır.</p>
                                            )
                                        }
                                        <FormItem control={control} name="money.useFixedExchangeRates">
                                            <Checkbox id="useFixedExchangeRates"  label="Sabit döviz kuru kullanmak istiyorum."/>
                                        </FormItem>
                                        {
                                            watchUseFixedExchangeRates && (
                                                <>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={4}>
                                                            <FormItem control={control} name={'money.exchangeRates.usd'}>
                                                                <TextField type="number" inputProps={{min: 0, step: 0.01}} label="Dolar ‧ USD ‧ $"/>
                                                            </FormItem>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormItem control={control} name={'money.exchangeRates.eur'}>
                                                                <TextField type="number" inputProps={{min: 0, step: 0.01}} label="Euro ‧ EUR ‧ €"/>
                                                            </FormItem>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormItem control={control} name={'money.exchangeRates.gbp'}>
                                                                <TextField type="number" inputProps={{min: 0, step: 0.01}} label="Sterlin ‧ GBP ‧ £"/>
                                                            </FormItem>
                                                        </Grid>
                                                    </Grid>
                                                    <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Bu alanda mevcut olmayan diğer para birimlerine dönüşüm yapılırken, belirlediğiniz <i>Amerikan Doları(USD) kuru</i>, <strong>çapraz kur</strong> olarak kullanılacaktır.</p>
                                                </>
                                            )
                                        }
                                    </Box>
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                    <Box>
                                        <Chip classes={{root: classes.chip }} variant="outlined" size="small" label="Satış Mağazası Minimum Satış Yüzdesi" />
                                        <p style={{fontSize: 13, marginTop: 0, marginBottom: 5}}>Mağazaların ürünlerinizi satışa sunarken belirlediği yüzdelik farkın en az ne kadar olması gerektiğini ayarlayabilirsiniz.</p>
                                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Bu genel bir ayardır. Bölge yönetiminden çalıştığınız her mağaza için ayrıca ayar yapabilirsiniz.</p>

                                        <FormItem control={control} name={"money.storeMinimumSalesRate"}>
                                            <TextField type="number" label={"Satış Mağazası Minimum Satış Yüzdesi %"} inputProps={{min: 0, max: 100, step: 0.01}} />
                                        </FormItem>
                                    </Box>
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                    <Box>
                                        <Chip classes={{root: classes.chip }} variant="outlined" size="small" label="İhracat Fiyat Farkı Yüzdesi" />
                                        <p style={{fontSize: 13, marginTop: 0, marginBottom: 5}}>Yurtdışı satış fiyatınız belirlediğiniz sabit kurlar ya da merkez bankası güncel kurları ile hesaplanmaktadır. Kur çarpanı dışında ekstra fiyat çarpanı girmek için aşağıdaki alanı kullanınız.</p>
                                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Bu genel bir ayardır. Bölge yönetiminden çalıştığınız her mağaza için ayrıca ayar yapabilirsiniz.</p>

                                        <FormItem control={control} name={"money.exportRate"}>
                                            <TextField type="number" label={"İhracat Fiyat Farkı Yüzdesi %"} inputProps={{min: 0, max: 100, step: 0.01}} />
                                        </FormItem>
                                    </Box>
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                    <Box textAlign={"right"}>
                                        <Button type="submit" variant="contained" size={"small"} color="primary">Kaydet</Button>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}