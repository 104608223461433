import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider} from "@material-ui/core";
import Typography from "../../components/typography";
import {makeStyles} from "@material-ui/core/styles";
import {TreeItem, TreeView} from "@material-ui/lab";
import {ChevronRight, ExpandMore} from "@material-ui/icons";
import useCustomFetch from "hooks/use-fetch";
import {green, red} from "@material-ui/core/colors";

const useViewStyles = makeStyles({
    root: {}
});

const useItemStyles = makeStyles(theme => ({
    root: {
        "& > .MuiTreeItem-content > .MuiTreeItem-label": {
            display: "flex",
            alignItems: "center",
            padding: "4px 0",
            background: "transparent !important",
            pointerEvents: "none",
            fontSize: 14,
        },
        "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
            content: "''",
            display: "inline-block",
            width: 20,
            height: 20,
            marginRight: 8,
            border: "1px solid #ccc",
            background: red[300],
            position: 'relative',
        }
    },
    iconContainer: {
        marginRight: 20,
        "& > svg": {
            padding: 0,
            fontSize: 24,
            "&:hover": {
                opacity: 0.6
            }
        }
    },
    label: {
        padding: 0
    },
    selected: {
        "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
            background: green[500],
            border: "1px solid transparent"
        }
    }
}));

const useCardHeaderStyles = makeStyles((theme) => ({
    cardHeaderAction: {
        margin:0
    }
}));

export default function Locations(props) {

    const { get, response } = useCustomFetch();

    const classesView = useViewStyles();
    const classesItem = useItemStyles();
    const classesCardHeader = useCardHeaderStyles();

    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = React.useState([]);
    const selected = React.useRef([]);
    const [countries, setCountries] = React.useState([]);
    const [changed, setChanged] = React.useState(false);



    const handleToggle = (event, nodeIds) => {
        if (event.target.nodeName !== "svg") {
            return;
        }
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        if (event.target.nodeName === "svg") {
            return;
        }

        let result = [];
        const first = nodeIds[0];
        let country = countries.find((country) => {
            return country.id === parseInt(first)
        });

        let state = countries[0].states.find((state) => {
            return state.id === parseInt(first)
        });

        console.log(country, state)

        if (selected.current.includes(first)) {
            if (country){
                //TODO: remove country states and cities ids from array.
                result = [];
            }else if(state){
                // TODO: Proper city remove.
                let cityIds = state.cities.map(city => city.id.toString());
                result = selected.current.filter((id) =>{
                    return id !== first && !cityIds.includes(id);
                });
            }
            else{
                result = selected.current.filter((id) =>id !== first);
            }
        } else {
            if (country){
                let allIds = [country.id.toString()];
                country.states.map((state)=>{
                    allIds.push(state.id.toString());
                    state.cities.map((city)=> allIds.push(city.id.toString()))
                    return state;
                })
                result = allIds;
            }else if(state){
                let stateAllIds = [state.id.toString()];
                state.cities.map((city)=> stateAllIds.push(city.id.toString()))

                result = [...stateAllIds, ...selected.current];
            }else{
                result = [first, ...selected.current];
            }
        }

        selected.current = result;

        localStorage.setItem('selectedLocations', JSON.stringify(result));

        setChanged(!changed);
    };

    const getSelectedLocations = ()=>{
        let data = localStorage.getItem('selectedLocations');
        let selectedLocations = [];
        if (data){
            selectedLocations = JSON.parse(data);
        }
        return selectedLocations;
    }

    const getLocationTree = async ()=>{
        let countries = await get(`i18n-service/countries/active`)

        let locationIds = [];
        let selectedLocations = getSelectedLocations();

        if(response.ok && countries.length){

            countries = await Promise.all(countries.map(async (country)=>{
                locationIds.push(country.id.toString())


                let states = await get(`i18n-service/countries/${country.id}/states/active`)

                if (response.ok && states.length){
                    states = await Promise.all(states.map(async(state)=>{
                        let cities = await get(`i18n-service/states/${state.id}/cities/active`)

                        let cityIds = cities.map((city)=> city.id.toString());

                        locationIds.push(state.id.toString())
                        locationIds.push(...cityIds)
                        state.cities = cities;
                        return state;
                    }))
                    country.states = states;
                }
                return country;
            }))
            setCountries(countries)

            if (selectedLocations.length > 0 ){
                selected.current = locationIds.filter((s) => selectedLocations.indexOf(s) > -1);
            }else{
                selected.current = locationIds
            }
            setLoading(false)
        }
    }


    useEffect(()=>{
        if (!loading){
            return;
        }
        getLocationTree()
    }, [loading])

    return (
        loading
        ?
        <Box textAlign={"center"}>
            <Box><CircularProgress /></Box>
            <Box>Satış Bölgeleri yükleniyor, lütfen bekleyin...</Box>
        </Box>
        :
        <Container maxWidth={"md"}>
            <Box mb={2} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant="h4">Satış Bölgeleri</Typography>
                    <Typography variant="subtitle1">Ürünlerinizin satışa sunulacağı bölgeleri ayarlayın.</Typography>
                </Box>
                <Box>
                    <Typography variant="h5"><strong>14.893</strong> Aktif Satış Bölgesi</Typography>

                </Box>
            </Box>
            {
                (countries && countries.length > 0) && (
                    <Card>
                        <CardHeader titleTypographyProps={{variant: 'h6'}} title="Satış Bölgelerini Seçin" classes={{action: classesCardHeader.cardHeaderAction }} action={(
                            <Button size="small" variant="outlined" color="primary">SATIŞ BÖLGELERİNİ GÜNCELLE</Button>
                        )} />
                        <Divider/>
                        <CardContent>
                            <TreeView
                                classes={classesView}
                                defaultCollapseIcon={<ExpandMore fontSize={"large"} />}
                                defaultExpandIcon={<ChevronRight fontSize={"large"} />}
                                expanded={expanded}
                                selected={selected.current}
                                onNodeToggle={handleToggle}
                                onNodeSelect={handleSelect}
                                multiSelect
                            >
                                {
                                    countries.map((country)=>(
                                        <TreeItem classes={classesItem} key={country.id} nodeId={country.id.toString()} label={`${country.translations.tr}`} style={{userSelect: 'none'}}>
                                            {
                                                (country.states && country.states.length > 0) && country.states.map((state)=> (
                                                    <TreeItem classes={classesItem} key={state.id} nodeId={state.id.toString()} label={state.name}>
                                                        {
                                                            (state.cities && state.cities.length > 0) && state.cities.map((city)=> (
                                                                    <TreeItem classes={classesItem} key={city.id} nodeId={city.id.toString()} label={city.name} />
                                                                )
                                                            )
                                                        }
                                                    </TreeItem>

                                                    )
                                                )
                                            }
                                        </TreeItem>
                                    ))
                                }
                            </TreeView>
                        </CardContent>
                    </Card>
                )
            }
        </Container>
    )
}