const data = [
  {
    id: 1,
    code: 'LN149632',
    name: 'XYZ Furniture',
    location: 'Pankow / Berlin / Almanya',
    authorizedPerson: {
      name: 'John Doe',
      phone: '+49 771 793 336'
    },
    order_count: 142,
  },
  {
    id: 2,
    code: 'ACM9856325',
    name: 'KLM Bench',
    location: 'Albuquerque / New Mexico / ABD',
    authorizedPerson: {
      name: 'Saul Goodman',
      phone: '+1 505 842 5662'
    },
    order_count: 107,
  },
  {
    id: 3,
    code: 'LXS4389654',
    name: 'ABC Sofa',
    location: 'Buca / İzmir / Türkiye',
    authorizedPerson: {
      name: 'Ahmet Yılmaz',
      phone: '+90 555 489 75 63'
    },
    order_count: 87,
  },
  {
    id: 4,
    code: 'EF78654320',
    name: 'DEF Furniture',
    location: 'Chelsea / Londra / İngiltere',
    authorizedPerson: {
      name: 'Kimberly Johnson',
      phone: '+44 020 8688 7744'
    },
    order_count: 96,
  },
];
export default data;