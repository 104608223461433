import React, {useEffect} from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiFormControl from "@material-ui/core/FormControl";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";

export default function Checkbox({
  onChange,
  error,
  value,
  helperText,
  disabled,
  label,
  fullWidth,
  color,
  size,
  labelPlacement
}) {
  const [componentValue, setComponentValue] = React.useState(value || false);

  useEffect(() => {
    setComponentValue(value);
  }, [value]);

  const handleChange = (event) => {
    setComponentValue(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <MuiFormControl error={error} fullWidth={fullWidth}>
      <MuiFormControlLabel
        control={
          <MuiCheckbox
            size={size}
            color={color}
            checked={componentValue || false}
            disabled={disabled}
            onChange={handleChange}
            name="checkbox"
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {
        helperText && (<MuiFormHelperText>{helperText}</MuiFormHelperText>)
      }
    </MuiFormControl>
  );
}

Checkbox.defaultProps = {
  fullWidth: true,
  color: "primary",
  size: "medium",
  labelPlacement: "end"
};
