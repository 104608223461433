import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Box, CircularProgress, Dialog, DialogContent, IconButton, Typography} from "@material-ui/core";
import {Close, Print} from "@material-ui/icons";
import {generateImageURL, getCurrentUser, getFeatureComputedValues, getPrice, getValueFromTranslation} from "../../utils";
import React, {useEffect, useState} from "react";
import useCustomFetch from "../../hooks/use-fetch";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "../../components/grid";
import Logo from "../../assets/logo.svg"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        borderBottom: `1px solid #046393`,
        backgroundColor: "#046393",
        color: "#fff"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));


const PriceListDialog = ({productId, onClose}) =>{

    let originalTitle = document.title;

    const classes = useStyles();

    const {get, post, response} = useCustomFetch();

    const [isLoading, setIsLoading] = useState(true);
    const [priceDialogProduct, setPriceDialogProduct] = useState(undefined);

    const [lang, setLang] = useState('tr');
    const [currency, setCurrency] = useState('try');


    let supplier = undefined;
    let currencyRates = undefined;
    const [supplierName, setSupplierName] = useState("");
    const [logo, setLogo] = useState("");

    const translations = {
        tr: {
            totalPrice: 'TOPLAM FİYAT',
            createdAt: 'Belge Oluşturma Zamanı',
            features: 'Özellikler',
            quantity: 'Adet',
            width: 'G',
            height: 'Y',
            depth: 'D',
            diameter: 'Ç',
            weight: 'Ağırlık',
            desi: 'Desi',
            expandedDimensions: "Açılır Boyutlar",
            seatDimensions: "Oturum Boyutları",
            footDimensions: "Ayak Boyutları",
            sizeDimensions: "Ürün Boyutları",
            packagingDimensions: "Paketleme Boyutları",
            optionalModules: "Opsiyonel Modüller",
            paginationMessage: 'Not: Bu fiyat listesi toplam :total sayfadan oluşmaktadır.',
        },
        en: {
            totalPrice: 'TOTAL PRICE',
            createdAt: 'Document Creaton Time',
            features: 'Features',
            quantity: 'Piece(s)',
            width: 'W',
            height: 'H',
            depth: 'D',
            diameter: 'Dia',
            weight: 'Weight',
            desi: 'Volume',
            expandedDimensions: "Expanded Dimensions",
            seatDimensions: "Seat Dimensions",
            footDimensions: "Foot Dimensions",
            sizeDimensions: "Product Dimensions",
            packagingDimensions: "Packaging Dimensions",
            optionalModules: "Optional Modules",
            paginationMessage: 'Note: This price list consists of :total page(s) in total.',
        }
    }
    const [translation, setTranslation] = useState(translations["tr"]);

    useEffect(() => {
        setTranslation(translations[lang]);
        if (priceDialogProduct){
            document.title = priceDialogProduct.modelName + ' ' + getValueFromTranslation(priceDialogProduct.categoryTranslations, 'title', lang) +  ' - ' + (new Date()).toLocaleString();
        }

        return () => {
            document.title = originalTitle;
        };
    }, [lang]);

    useEffect(() => {
        //console.log(currency, supplier.money);
    }, [currency]);


    const getProduct = async () => {
        let product = await get(`/product-service/products/${productId}`)
        if (response.ok && product){
            /* Category */
            let category = await get(`/product-service/categories/${product.categoryId}`);
            if(response.ok  && category){
                product.categoryTranslations = category.translations;
            }

            /* Features */
            if(product.featureIds && product.featureIds.length > 0 && product.features.length > 0){
                let features = await post(`/product-service/features/findByIdList`, {idList: product.featureIds})
                if (response.ok && features.length > 0){
                    product.features = product.features.map((feat) => {
                        let feature = features.find(f => f.id === feat.featureId);
                        if (feature){
                            feat.translations = feature.translations;
                        }
                        return feat;
                    });
                }
            }

            let supplierMoney = supplier.money;
            let supplierCurrency = supplierMoney.currency;

            let rates = {
                try: 1,
                usd: currencyRates.rates['usd'],
                eur: currencyRates.rates['eur'],
                gbp: currencyRates.rates['gbp'],
            };

            if (supplierMoney.useFixedExchangeRates === true && supplierMoney.exchangeRates !== null){
                rates = supplierMoney.exchangeRates;
                rates['try'] = 1;
            }

            /* Modules */
            if (product.isModule === true && product.modules && product.modules.length > 0){
                let modules = await post(`/product-service/viewwords/findByIdList`, {idList: product.modules.map(m => m.moduleId)})
                if (response.ok && modules.length > 0){
                    product.modules = product.modules.map((mod) => {
                        let module = modules.find(f => f.id === mod.moduleId);
                        if (module){
                            mod.translations = module.values;
                        }
                        return mod;
                    });
                }

                product.modules = product.modules.map((module) => {
                    module.prices = Object.keys(rates).map((currency) => {
                       return {
                           currency: currency,
                           quantity: supplierCurrency === currency ? module.price.quantity.toFixed(2) : ((module.price.quantity + (module.price.quantity * supplierMoney.exportRate / 100)) / rates[currency]).toFixed(2),
                       }
                    });

                    return module;
                });


                product.standardModules = product.modules.filter(m => m.isStandard);
                product.optionalModules = product.modules.filter(m => !m.isStandard);

                product.totalModulesPrice = product.standardModules.reduce((acc, curr) => {
                    curr.prices.map(p => {
                        acc.find(c => c.currency === p.currency).quantity += (p.quantity * curr.standardQuantity)
                    });
                    return acc;
                }, product.modules[0].prices.map(p => ({currency: p.currency, quantity: 0})));

            }else{
                product.single.prices = Object.keys(rates).map((currency) => {
                    return {
                        currency: currency,
                        quantity: supplierCurrency === currency ? product.single.price.quantity.toFixed(2) : ((product.single.price.quantity + (product.single.price.quantity * supplierMoney.exportRate / 100)) / rates[currency]).toFixed(2),
                    }
                });
            }
            return product;
        }
        throw new Error("product not found!");
    }

    const getCurrencies = async () => {
        return await get(`/i18n-service/currencies/rates?_${Date.now()}`);
    }

    const getSupplier = async () => {
        const currentUser = getCurrentUser();
        const supplierId = currentUser.profile?.supplier_id;
        if(!supplierId){
            throw new Error("supplierId not found!");
        }
        return await get(`/supplier-service/suppliers/GetById/${supplierId}?_${Date.now()}`);
    }

    useEffect(() => {
        setIsLoading(true)

        Promise.all([getSupplier(), getCurrencies()])
            .then((result) => {
                supplier = result[0];
                currencyRates = result[1];

                // todo: use supplier logo feature. remove this.
                if(supplier.id === '64c8db50214917fb6cabe090' || supplier.id === '65860f06736fdd01822bf4e6' || supplier.id === '623083624e5d92472856a13a'){
                    setLogo(supplier.id);
                }else {
                    setLogo(null);
                }
                setSupplierName(result[0].commercial.brand);
            })
            .then(()=>{
                getProduct()
                    .then((product)=>{
                        setPriceDialogProduct(product);
                        document.title = product.modelName + ' ' + getValueFromTranslation(product.categoryTranslations, 'title', lang) +  ' - ' + (new Date()).toLocaleString();
                        setIsLoading(false)
                    })
                    .catch((err)=>{
                        setPriceDialogProduct(null);
                        console.log(err)
                        //alert("Ürün getirilemedi!");
                    });
            })
            .catch((err) => {
                setPriceDialogProduct(null);
                console.log(err);
                //alert("Ürün getirilemedi!");
            });



        /**/

        return ()=>{
            setIsLoading(true);
            supplier = undefined;
            currencyRates = undefined;
            setPriceDialogProduct(undefined);
            document.title = originalTitle;
        };
    }, [productId]);

    return (
        <Dialog fullScreen open={true}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" title={'Kapat'}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6"  className={classes.title}>
                        Ürün Fiyat Listesi
                    </Typography>
                    {
                        !isLoading && (
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <div style={{display:'flex', justifyContent: 'space-between',marginRight:16}}>
                                    <strong style={{marginRight:8}}>Dil:</strong>
                                    <select style={{color: '#fff', background: 'none',border: 'none'}} onChange={(e)=>setLang(e.target.value)}>
                                        <option style={{color:'#000'}} value="tr">TR</option>
                                        <option style={{color:'#000'}} value="en">EN</option>
                                    </select>
                                </div>
                                <div style={{display:'flex', justifyContent: 'space-between',marginRight:16}}>
                                    <strong style={{marginRight:8}}>Para Birimi:</strong>
                                    <select style={{color: '#fff', background: 'none',border: 'none'}} onChange={(e)=>setCurrency(e.target.value)}>
                                        <option style={{color:'#000'}} value="try">TRY</option>
                                        <option style={{color:'#000'}} value="usd">USD</option>
                                        <option style={{color:'#000'}} value="eur">EUR</option>
                                        <option style={{color:'#000'}} value="gbp">GBP</option>
                                    </select>
                                </div>
                                <IconButton edge="start" color="inherit" onClick={()=>{window.print()}} aria-label="close" title={'Yazdır'}>
                                    <Print />
                                </IconButton>
                            </div>
                        )
                    }

                </Toolbar>
            </AppBar>
            <DialogContent id={"DialogPrint"}>
                {
                    isLoading
                    ?
                    <Box textAlign={"center"}>
                        <Box><CircularProgress/></Box>
                        <br/>
                        <Box>Ürün yükleniyor, lütfen bekleyin...</Box>
                    </Box>
                    :
                    (
                        <div id={'DialogPrintContent'} data-lang={lang} data-pagination-message={translation['paginationMessage']} data-min-page={priceDialogProduct.isModule ? 2 : 1}>
                            <table className={'print-table'}>
                                <thead className={'print-header'}>
                                    <tr>
                                        <th style={{textAlign: 'left',fontWeight:'normal'}}>
                                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item xs={4}>
                                                    {
                                                        logo && (<img src={`/supplier_logos/${logo}.png`} style={{maxHeight: 60}}/>)
                                                    }
                                                    {
                                                        !logo && (<span style={{textAlign: 'left', fontSize: '1.9em'}}>{supplierName}</span>)
                                                    }
                                                </Grid>
                                                <Grid item xs={8} style={{textAlign: 'right', fontSize: '1.9em'}}>
                                                    <span style={{fontWeight: 'bold', marginRight: 5}}>{priceDialogProduct.modelName}</span>
                                                    <span>{getValueFromTranslation(priceDialogProduct.categoryTranslations, 'title', lang)}</span>
                                                </Grid>
                                            </Grid>
                                            <hr/>
                                        </th>
                                    </tr>
                                </thead>
                                <tfoot className={'print-footer'}>
                                    <tr>
                                        <th>

                                        </th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                <tr>
                                    <td>
                                        {
                                            priceDialogProduct.isModule === true &&
                                            (
                                                <>
                                                    {
                                                        priceDialogProduct.standardModules.length > 0 && (
                                                            <div className={'standardModules'}>
                                                                <div className={'printProductModules'}>
                                                                    {
                                                                        priceDialogProduct.standardModules.map((module) => (
                                                                            <div key={module.id} className={'moduleItem'}>
                                                                                <div style={{margin: '0 .5em .5em 0'}}>{
                                                                                    module.images.length > 0
                                                                                        ?
                                                                                        <img alt={getValueFromTranslation(module.translations, 'value', lang)} style={{width: '100%'}}
                                                                                             src={generateImageURL(module.images[0], 0, 200, 'fit', 90, 1, 1)}/>
                                                                                        :
                                                                                        <img alt={getValueFromTranslation(module.translations, 'value', lang)} style={{width: '100%'}}
                                                                                             src={generateImageURL(null, 0, 200, 'fit', 90, 1, 1)}/>}</div>
                                                                                <div style={{marginBottom: '.25em', fontSize: '1.1em'}}>
                                                                                    <strong>{getValueFromTranslation(module.translations, 'value', lang)}</strong> x {module.standardQuantity} {translation.quantity}</div>
                                                                                <div style={{fontSize: '.85em'}}>
                                                                                    <div>
                                                                                        <strong>{translation.sizeDimensions}</strong>
                                                                                        <div>
                                                                                            <strong>{translation.width}</strong>: {module.dimensions.width} cm <strong>{translation.height}</strong>: {module.dimensions.height} cm <strong>{translation.depth}</strong>: {module.dimensions.depth} cm
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        module.hasExpandedDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.expandedDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.width}</strong>: {module.expandedDimensions.width} cm <strong>{translation.height}</strong>: {module.expandedDimensions.height} cm <strong>{translation.depth}</strong>: {module.expandedDimensions.depth} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        module.hasSeatDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.seatDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.width}</strong>: {module.seatDimensions.width} cm <strong>{translation.height}</strong>: {module.seatDimensions.height} cm <strong>{translation.depth}</strong>: {module.seatDimensions.depth} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        module.hasFootDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.footDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.height}</strong>: {module.footDimensions.height} cm <strong>{translation.diameter}</strong>: {module.footDimensions.diameter} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div>
                                                                                        <strong>{translation.packagingDimensions}</strong>
                                                                                        <div><strong>{translation.quantity}</strong>: {module.packaging.quantity}&nbsp;<strong>{translation.weight}</strong>: {module.packaging.weight} kg <strong>{translation.desi}</strong>: {module.packaging.desi}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{marginTop: '.2em', fontSize: '1.2em'}}><strong>{new Intl.NumberFormat(lang, {
                                                                                    style: 'currency',
                                                                                    currency: currency,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(getPrice(module.prices, currency))}</strong></div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className={'totalPrice'} style={{
                                                                    textAlign: 'center',
                                                                    border: '1px solid #000',
                                                                    padding: '.25em',
                                                                    marginTop: '.2em',
                                                                    fontSize: '1.2em'
                                                                }}>{translation.totalPrice}: <strong>{new Intl.NumberFormat(lang, {
                                                                    style: 'currency',
                                                                    currency: currency,
                                                                    maximumFractionDigits: 2
                                                                }).format(getPrice(priceDialogProduct.totalModulesPrice, currency))}</strong></div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        priceDialogProduct.optionalModules.length > 0 && (
                                                            <div className={'optionalModules'}>
                                                                <h3 style={{fontSize: '1.2em'}}>{translation.optionalModules}</h3>
                                                                <div className={'printProductModules'} style={{minHeight: priceDialogProduct.optionalModules.length > 5 ?  '1100px' : 'auto'}}>
                                                                    {
                                                                        priceDialogProduct.optionalModules.map((module) => (
                                                                            <div key={module.id} className={'moduleItem'}>
                                                                                <div style={{margin: '0 .5em .5em 0'}}>{
                                                                                    module.images.length > 0
                                                                                        ?
                                                                                        <img alt={getValueFromTranslation(module.translations, 'value', lang)} style={{width: '100%'}}
                                                                                             src={generateImageURL(module.images[0], 0, 200, 'fit', 90, 1, 1)}/>
                                                                                        :
                                                                                        <img alt={getValueFromTranslation(module.translations, 'value', lang)} style={{width: '100%'}}
                                                                                             src={generateImageURL(null, 0, 200, 'fit', 90, 1, 1)}/>}</div>
                                                                                <div style={{marginBottom: '.25em', fontSize: '1.1em'}}><strong>{getValueFromTranslation(module.translations, 'value', lang)}</strong></div>
                                                                                <div style={{fontSize: '.85em'}}>
                                                                                    <div>
                                                                                        <strong>{translation.sizeDimensions}</strong>
                                                                                        <div>
                                                                                            <strong>{translation.width}</strong>: {module.dimensions.width} cm <strong>{translation.height}</strong>: {module.dimensions.height} cm <strong>{translation.depth}</strong>: {module.dimensions.depth} cm
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        module.hasExpandedDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.expandedDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.width}</strong>: {module.expandedDimensions.width} cm <strong>{translation.height}</strong>: {module.expandedDimensions.height} cm <strong>{translation.depth}</strong>: {module.expandedDimensions.depth} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        module.hasSeatDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.seatDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.width}</strong>: {module.seatDimensions.width} cm <strong>{translation.height}</strong>: {module.seatDimensions.height} cm <strong>{translation.depth}</strong>: {module.seatDimensions.depth} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        module.hasFootDimensions && (
                                                                                            <div>
                                                                                                <strong>{translation.footDimensions}</strong>
                                                                                                <div>
                                                                                                    <strong>{translation.height}</strong>: {module.footDimensions.height} cm <strong>{translation.diameter}</strong>: {module.footDimensions.diameter} cm
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div>
                                                                                        <strong>{translation.packagingDimensions}</strong>
                                                                                        <div><strong>{translation.quantity}</strong>: {module.packaging.quantity}&nbsp;<strong>{translation.weight}</strong>: {module.packaging.weight} kg <strong>{translation.desi}</strong>: {module.packaging.desi}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{marginTop: '.2em', fontSize: '1.2em'}}><strong>{new Intl.NumberFormat(lang, {
                                                                                    style: 'currency',
                                                                                    currency: currency,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(getPrice(module.prices, currency))}</strong></div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* features */}
                                                    <h3 className="DialogPrintFeaturesTitle">{translation.features}</h3>
                                                    <div>
                                                        {
                                                            priceDialogProduct.features.map((feature) =>
                                                                (
                                                                    <div key={feature.id} style={{
                                                                        fontSize: '.9em',
                                                                        background: '#fff',
                                                                        borderRadius: 2,
                                                                        border: '1px solid #646464',
                                                                        color: '#414141',
                                                                        padding: '.35rem',
                                                                        display: 'inline-block',
                                                                        margin: '0 .25rem .25rem 0'
                                                                    }}>
                                                                        <strong>{getValueFromTranslation(feature.translations, 'value', lang)}: </strong>
                                                                        <span>{getFeatureComputedValues(feature.values, lang)}</span>
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>

                                                    {/* main image */}
                                                    <div style={{margin: '1em 0', textAlign: 'center'}}>
                                                        <img style={{width: '100%'}} src={generateImageURL(priceDialogProduct.images[0], 900, 506, 'fit', 90, 1, 1)} alt={priceDialogProduct.modelName}/>
                                                    </div>
                                                </>
                                            )
                                        }

                                        {
                                            priceDialogProduct.isModule === false && (
                                                <div>
                                                    {/* main image */}
                                                    <div style={{margin: '1em 0', textAlign: 'center'}}>
                                                        <img style={{width: '100%'}} src={generateImageURL(priceDialogProduct.images[0], 900, 500, 'fill', 90, 1, 1)} alt={priceDialogProduct.modelName}/>
                                                    </div>
                                                    <div style={{fontSize: '.85em'}}>
                                                        <div>
                                                            <strong>{translation.sizeDimensions}</strong>
                                                            <div>
                                                                <strong>{translation.width}</strong>: {priceDialogProduct.single.dimensions.width} cm <strong>{translation.height}</strong>: {priceDialogProduct.single.dimensions.height} cm <strong>{translation.depth}</strong>: {priceDialogProduct.single.dimensions.depth} cm
                                                            </div>
                                                        </div>
                                                        {
                                                            priceDialogProduct.single.hasExpandedDimensions && (
                                                                <div>
                                                                    <strong>{translation.expandedDimensions}</strong>
                                                                    <div>
                                                                        <strong>{translation.width}</strong>: {priceDialogProduct.single.expandedDimensions.width} cm <strong>{translation.height}</strong>: {priceDialogProduct.single.expandedDimensions.height} cm <strong>{translation.depth}</strong>: {priceDialogProduct.single.expandedDimensions.depth} cm
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            priceDialogProduct.single.hasSeatDimensions && (
                                                                <div>
                                                                    <strong>{translation.seatDimensions}</strong>
                                                                    <div>
                                                                        <strong>{translation.width}</strong>: {priceDialogProduct.single.seatDimensions.width} cm <strong>{translation.height}</strong>: {priceDialogProduct.single.seatDimensions.height} cm <strong>{translation.depth}</strong>: {priceDialogProduct.single.seatDimensions.depth} cm
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            priceDialogProduct.single.hasFootDimensions && (
                                                                <div>
                                                                    <strong>{translation.footDimensions}</strong>
                                                                    <div>
                                                                        <strong>{translation.height}</strong>: {priceDialogProduct.single.footDimensions.height} cm <strong>{translation.diameter}</strong>: {priceDialogProduct.single.footDimensions.diameter} cm
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <div>
                                                            <strong>{translation.packagingDimensions}</strong>
                                                            <div><strong>{translation.quantity}</strong>: {priceDialogProduct.single.packaging.quantity}
                                                                <strong>{translation.weight}</strong>: {priceDialogProduct.single.packaging.weight} kg <strong>{translation.desi}</strong>: {priceDialogProduct.single.packaging.desi}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: '.2em', fontSize: '1.2em'}}><strong>{new Intl.NumberFormat(lang, {
                                                        style: 'currency',
                                                        currency: currency,
                                                        maximumFractionDigits: 2
                                                    }).format(getPrice(priceDialogProduct.single.prices, currency))}</strong></div>
                                                    {/* features */}
                                                    <h3 style={{fontSize: '1.2em'}}>{translation.features}</h3>
                                                    <div>
                                                        {
                                                            priceDialogProduct.features.map((feature) =>
                                                                (
                                                                    <div key={feature.id} style={{
                                                                        fontSize: '.9em',
                                                                        background: '#fff',
                                                                        borderRadius: 2,
                                                                        border: '1px solid #646464',
                                                                        color: '#414141',
                                                                        padding: '.35rem',
                                                                        display: 'inline-block',
                                                                        margin: '0 .25rem .25rem 0'
                                                                    }}>
                                                                        <strong>{getValueFromTranslation(feature.translations, 'value', lang)}: </strong>
                                                                        <span>{getFeatureComputedValues(feature.values, lang)}</span>
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <hr/>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                                <span style={{fontSize: '0.85em', marginBottom: 4}}>Powered by</span>
                                                <a href="//dataware.com.tr?ref=production_print">
                                                    <img style={{marginLeft: 5, height: 28, width: '100%'}} src={Logo} alt="Dataware Production"/>
                                                </a>
                                            </div>
                                            <div style={{fontSize: '0.8em'}}>{translation.createdAt}: {(new Date()).toLocaleString()}</div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    );
}


PriceListDialog.defaultProps = {
    productId: undefined,
    onClose: ()=>{},
}

PriceListDialog.propTypes = {
    productId: PropTypes.string,
    onClose: PropTypes.func,
}

export default PriceListDialog;