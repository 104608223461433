import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import Logo from "../../assets/logo.svg";
import React from "react";

export default function MobilClose() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Box display={isMobile ? 'flex' : 'none'} justifyContent={"center"} alignContent={"center"} alignItems={"center"} flexDirection={"column"}
             style={{overflow: 'hidden', textAlign: 'center', background: "#fff", position: 'absolute', width: '100%', height: '100vh', top: '0px', left: '0px', right: '0px', bottom: '0px', zIndex: 1500}}>
            <img style={{width: '50%', marginBottom: '20px'}} src={Logo} alt="Dataware Production" />
            <Box style={{ padding: '10px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}><strong>IOS</strong> ve <strong>Android</strong> Mobil Uygulamamız yakında yayına girecektir!</Typography>
                <Typography variant="h6">Veri girişleriniz için lütfen bilgisayarınızı kullanınız!</Typography>
            </Box>
        </Box>
    )
}