import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button, Link, Chip, Tooltip
} from '@material-ui/core';
import {Visibility} from "@material-ui/icons";
import {blue, green, red, common, indigo, lightGreen} from "@material-ui/core/colors";

const OrderTable = ({ orders }) => {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const orderStatuses = [
    {
      title: 'Oluşturuldu',
      color: common.white,
      background: lightGreen[500]
    },
    {
      title: 'Devam Ediyor',
      color: common.white,
      background: blue[300]
    },
    {
      title: 'Sevkiyatta',
      color: common.white,
      background: indigo[300]
    },
    {
      title: 'Teslim Edildi',
      color: common.white,
      background: green[400]
    },
    {
      title: 'İptal Edilmiş',
      color: common.white,
      background: red[400]
    },
    {
      title: 'Tamamlanmış',
      color: common.white,
      background: green[600]
    }
  ]

  const handleSelectAll = (event) => {
    let newSelectedOrderIds;

    if (event.target.checked) {
      newSelectedOrderIds = orders.map((o) => o.id);
    } else {
      newSelectedOrderIds = [];
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrderIds.indexOf(id);
    let newSelectedOrderIds = [];

    if (selectedIndex === -1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds, id);
    } else if (selectedIndex === 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds.slice(1));
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
          selectedOrderIds.slice(0, selectedIndex),
          selectedOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                    checked={selectedOrderIds.length === orders.length}
                    color="primary"
                    indeterminate={
                      selectedOrderIds.length > 0
                      && selectedOrderIds.length < orders.length
                    }
                    onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Sipariş Kodu</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Sipariş Tarihi</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Ürün Adı</TableCell>
              {/*
              <TableCell style={{fontWeight: 'bold'}}>Kategori</TableCell>
              */}
              <TableCell style={{fontWeight: 'bold'}}>Mağaza</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Durum</TableCell>
              <TableCell style={{fontWeight: 'bold'}} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.slice(0, limit).map((order) => {
              return order.items.map((item, itemidx) => (
                      <TableRow hover key={itemidx} selected={selectedOrderIds.indexOf(itemidx) !== -1}>
                        <TableCell padding="checkbox">
                          <Checkbox
                              checked={selectedOrderIds.indexOf(order.id) !== -1}
                              onChange={(event) => handleSelectOne(event, order.id)}
                              value="true"
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Siparişi İncele" placement="top">
                            <Link href={`/orders/${order.id}/edit`}>{(order.id.substring(0, 8)).toLocaleUpperCase()}</Link>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{(new Date(order.orderDate)).toLocaleDateString()}</TableCell>
                        <TableCell>
                          <Tooltip title="Ürüne git" placement="top">
                            <Link href={`/products/${order.id}`}>{item.name}</Link>
                          </Tooltip>
                        </TableCell>
                        {/*
                        <TableCell>Kategori</TableCell>
                        */}
                        <TableCell>{order.storeName}</TableCell>
                        <TableCell><Chip size="small" style={{
                          backgroundColor: orderStatuses[0].background,
                          color: orderStatuses[0].color
                        }} label={orderStatuses[0].title}/></TableCell>
                        <TableCell>
                          <Tooltip title="Siparişi İncele" placement="top">
                            <Button variant="outlined" color="primary" size="small" href={`/orders/${order.id}`}>
                              <Visibility/>
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                  )
              )
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        labelRowsPerPage="Sayfa başına göster:"
        component="div"
        count={orders.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

OrderTable.propTypes = {
  orders: PropTypes.array.isRequired
};

export default OrderTable;
