import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton, Link
} from '@material-ui/core';
import {Edit, Delete, Lock} from "@material-ui/icons";

const UserTable = ({ users }) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedUserIds;

    if (event.target.checked) {
      newSelectedUserIds = users.map((u) => u.id);
    } else {
      newSelectedUserIds = [];
    }

    setSelectedUserIds(newSelectedUserIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUserIds.indexOf(id);
    let newSelectedUserIds = [];

    if (selectedIndex === -1) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
    } else if (selectedIndex === 0) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
    } else if (selectedIndex === selectedUserIds.length - 1) {
      newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUserIds = newSelectedUserIds.concat(
          selectedUserIds.slice(0, selectedIndex),
          selectedUserIds.slice(selectedIndex + 1)
      );
    }

    setSelectedUserIds(newSelectedUserIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const deleteUser = ()=>{
    window.confirm("Silmek istediğinizden emin misiniz?")
  }

  return (
    <Card>
      <Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                    checked={selectedUserIds.length === users.length}
                    color="primary"
                    indeterminate={
                      selectedUserIds.length > 0
                      && selectedUserIds.length < users.length
                    }
                    onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Adı</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Rolü</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>E-posta Adresi</TableCell>
              <TableCell style={{fontWeight: 'bold'}} align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(0, limit).map((user, index) => (
                <TableRow hover key={index} selected={selectedUserIds.indexOf(index) !== -1}>
                  <TableCell padding="checkbox">
                    <Checkbox
                        checked={selectedUserIds.indexOf(index) !== -1}
                        onChange={(event) => handleSelectOne(event, index)}
                        value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Link href={`/users/${user._id}`}>{user.name} {user.surname}</Link>
                  </TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton aria-label="edit" href={`/users/${user._id}/changePassword`} disabled>
                        <Lock fontSize="small" color="action" />
                      </IconButton>
                      <IconButton aria-label="edit" href={`/users/${user._id}/edit`} disabled>
                        <Edit fontSize="small" color="primary" />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={deleteUser} disabled>
                        <Delete fontSize="small" color="secondary" />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        labelRowsPerPage="Sayfa başına göster:"
        component="div"
        count={users.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UserTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default UserTable;
