import React from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}) => {
  return (
    <div>
      {previews}
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {files.length > 0 && submitButton}
    </div>
  );
};

const styles = {
  dropzone: {
    justifyContent: 'center',
    overflow: 'auto',
    minHeight: 80,
  },
  preview: {
    padding: '2% 0',
    marginBottom: 10,
  },
  inputLabelWithFiles: {
    alignSelf: 'center',
    margin: 0,
  }
}


export default function Uploader(props) {
  return <Dropzone {...props} styles={styles} LayoutComponent={Layout} />;
}
