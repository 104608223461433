import * as yup from "yup";
import LocationStep from "../location-step";
import CommercialStep from "../commercial-step";
import RegisterCompleteStep from "../complete-step";
import AuthorizedStep from "../authorized-person-step";
import TaxInformationStep from "../tax-information-step";
import DepartmentStep from "../department-step";

/* eslint-disable no-template-curly-in-string */
const userSchema = {
  title: yup.string().required("Yetkili Ünvanı gereklidir."),
  name: yup
    .string()
    .nullable()
    .required("Yetkili Adı gereklidir.")
    .min(2, "Yetkili Adı en az ${min} karakter içermelidir."),
  surname: yup
    .string()
    .nullable()
    .required("Yetkili Soyadı gereklidir.")
    .min(2, "Yetkili Soyadı en az ${min} karakter içermelidir."),
  phone: yup.string().required("Yetkili Cep Telefonu gereklidir."),
  email: yup
    .string()
    .nullable()
    .required("Yetkili E-posta Adresi gereklidir.")
    .email("E-posta adresi geçersiz."),
};

export const schema = yup.object().shape({
  commercial: yup.object().shape({
    brand: yup
      .string()
      .nullable()
      .required("Marka gereklidir.")
      .min(2, "Marka en az ${min} karakter içermelidir."),
    title: yup
      .string()
      .nullable()
      .required("Ticari Ünvan gereklidir.")
      .min(2, "Ticari Ünvan en az ${min} karakter içermelidir."),
    phone: yup.string().nullable().required("Telefon Numarası gereklidir."),
    email: yup
      .string()
      .nullable()
      .required("E-posta Adresi gereklidir.")
      .email("E-posta adresi geçersiz."),
    website: yup
      .string()
      .nullable()
      .required("İnternet Sitesi gereklidir.")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "İnternet Sitesi geçersiz."
      ),
  }),
  companyOwner: yup.object().shape({
    ...userSchema,
    companyType: yup.string().nullable().required("Şirket Türü gereklidir."),
  }),
  location: yup.object().shape({
    countryId: yup.string().nullable().required("Ülke gereklidir."),
    stateId: yup.string().nullable().required("Şehir gereklidir."),
    cityId: yup.string().nullable().required("İlçe gereklidir."),
    address: yup
      .string()
      .nullable()
      .required("Adres gereklidir.")
      .min(25, "Adres en az ${min} karakter içermelidir."),
  }),
  taxInformation: yup.object().shape({
    countryId: yup.string().nullable().required("Ülke gereklidir."),
    stateId: yup.string().nullable().required("Şehir gereklidir."),
    cityId: yup.string().nullable().required("İlçe gereklidir."),
    address: yup
        .string()
        .nullable()
        .required("Vergi Mükellefi Adresi gereklidir.")
        .min(25, "Adres en az ${min} karakter içermelidir."),
    departmentId: yup.string().nullable().required("Vergi Dairesi gereklidir."),
    number: yup
      .string()
      .nullable()
      .required("Vergi Numarası gereklidir.")
      .matches(/^[0-9]{10,11}$/, "Vergi Numarası 10 ya da 11 karakterli sayı içermelidir."),
  }),
  departmentChiefs: yup.array().of(yup.object().shape({
    title: yup.string().nullable().required("Yetkili Ünvanı gereklidir."),
    name: yup.string().nullable().required("Yetkili Adı gereklidir.").min(2, "Yetkili Adı en az ${min} karakter içermelidir."),
    surname: yup.string().nullable().required("Yetkili Soyadı gereklidir.").min(2, "Yetkili Soyadı en az ${min} karakter içermelidir."),
    phone: yup.string().nullable().required().required("Yetkili Cep Telefonu gereklidir."),
    email: yup.string().nullable().required("Yetkili E-posta Adresi gereklidir.").email("E-posta adresi geçersiz."),
  }))
});

export const steps = [
  {
    key: "companyOwner",
    title: "Firma Sahibi",
    description: "Lütfen Firma Sahibi Bilgilerini Girin",
    fields: [
      "companyOwner.companyType",
      "companyOwner.title",
      "companyOwner.name",
      "companyOwner.surname",
      "companyOwner.phone",
      "companyOwner.email",
    ],
    Component: AuthorizedStep,
  },
  {
    key: "commercial",
    title: "Kurumsal Bilgiler",
    description: "Lütfen Kurum Bilgilerini Girin",
    fields: [
      "commercial.brand",
      "commercial.title",
      "commercial.phone",
      "commercial.email",
      "commercial.website",
    ],
    Component: CommercialStep,
  },
  {
    key: "location",
    title: "Adres Bilgileri",
    description: "Lütfen Kurum Lokasyon Bilgilerini Girin",
    fields: [
      "location.countryId",
      "location.stateId",
      "location.cityId",
      "location.address",
    ],
    Component: LocationStep,
  },
  {
    key: "departmentChiefs",
    title: "Departman Bilgileri",
    description: "Lütfen Departman Bilgileri Bilgilerini Girin",
    fields: [
      "departmentChiefs.0.title",
      "departmentChiefs.0.name",
      "departmentChiefs.0.surname",
      "departmentChiefs.0.phone",
      "departmentChiefs.0.email",
      "departmentChiefs.1.title",
      "departmentChiefs.1.name",
      "departmentChiefs.1.surname",
      "departmentChiefs.1.phone",
      "departmentChiefs.1.email",
      "departmentChiefs.2.title",
      "departmentChiefs.2.name",
      "departmentChiefs.2.surname",
      "departmentChiefs.2.phone",
      "departmentChiefs.2.email",
      "departmentChiefs.3.title",
      "departmentChiefs.3.name",
      "departmentChiefs.3.surname",
      "departmentChiefs.3.phone",
      "departmentChiefs.3.email",
    ],
    Component: DepartmentStep,
  },
  {
    key: "taxInformation",
    title: "Vergilendirme",
    description: "Lütfen Kurum Vergilendirme Bilgilerini Girin",
    fields: [
      "taxInformation.countryId",
      "taxInformation.stateId",
      "taxInformation.cityId",
      "taxInformation.departmentId",
      "taxInformation.number",
      "taxInformation.address",
    ],
    Component: TaxInformationStep,
  },
  {
    key: "complete",
    title: "Tamamlayın",
    description: "Bilgileriniz Kaydedildi",
    fields: [],
    Component: RegisterCompleteStep,
  },
];
