import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {withOidcSecure} from "@axa-fr/react-oidc-context";
import Dashboard from "pages/dashboard";
import Register from "pages/register";
import TempPage from "pages/temp-page";
import Products from "pages/products/list";
import Orders from "pages/orders";
import Customers from "pages/customers";
import Users from "pages/users";
import Reports from "pages/reports";
import Company from "pages/company";
import RequireActivation from "pages/require-activation";
import Locations from "pages/locations";
import ProductCreate from "pages/products/create";
import ProductEdit from "pages/products/edit";
import StoreRelations from "../pages/store-relations";
import {getSessionExpiration} from "../utils";
import StoreRelationCreate from "../pages/store-relations/create";
import StoreRelationEdit from "../pages/store-relations/edit";

const PageNotFound = () => (
    <div className={"flex justify-center"}>
        <div className={"mt-5 mb-5"}>
            <div className={"mb-6"}>Page Not Found</div>
            <hr/>
        </div>
    </div>
);
const SecuredRoute = ({component: Component, ...rest}) => {
    let validExpiration = getSessionExpiration() > Date.now();
    return (
        <Route
            {...rest}
            render={(props) =>
                validExpiration ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                )
            }
        />
    );
};
const Routes = () => {
    return (
        <Switch>
            <SecuredRoute exact path="/require-activation" component={RequireActivation}/>
            <SecuredRoute exact path="/register" component={Register}/>
            <SecuredRoute exact path="/temp-page" component={TempPage}/>
            <Route exact path="/" component={withOidcSecure(Dashboard)}/>
            <SecuredRoute exact path="/products" component={Products}/>
            <SecuredRoute exact path="/products/create" component={ProductCreate}/>
            <SecuredRoute exact path="/products/:id/edit" component={ProductEdit}/>
            <SecuredRoute exact path="/orders" component={Orders}/>
            <SecuredRoute exact path="/customers" component={Customers}/>
            <SecuredRoute exact path="/users" component={Users}/>
            <SecuredRoute exact path="/reports" component={Reports}/>
            <SecuredRoute exact path="/company" component={Company}/>
            <SecuredRoute exact path="/locations" component={Locations}/>
            <SecuredRoute exact path="/store-relations" component={StoreRelations}/>
            <SecuredRoute exact path="/store-relations/create" component={StoreRelationCreate}/>
            <SecuredRoute exact path="/store-relations/:id/edit" component={StoreRelationEdit}/>
            <SecuredRoute component={PageNotFound}/>
        </Switch>
    );
};

export default Routes;
