import {Box, Button, CardContent, CircularProgress, Container} from "@material-ui/core";
import Typography from "../../components/typography";
import React, {useEffect, useState} from "react";
import Card from "../../components/card";
import FormItem from "../../components/form-item";
import TextField from "../../components/text-field";
import {useForm} from "react-hook-form";
import DatePicker from "../../components/date-picker";
import {makeStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";
import {yupResolver} from "@hookform/resolvers/yup";
import {contractUpdateSchema} from "./forms/validation";
import {format} from 'date-fns';
import useCustomFetch from "../../hooks/use-fetch";
import Popup from "../../components/popup";
import {useRouter} from "../../hooks/use-router";
import {getCurrentUser} from "../../utils";

export default function StoreRelationEdit(){

    const currentUser = getCurrentUser();

    const supplier_id = currentUser.profile?.supplier_id;
    const [contract, setContract] = useState(null)
    const [store, setStore] = useState(null)
    const [loading, setLoading] = useState(true)
    const [supplier, setSupplier] = useState(null)
    const [exportRateEnabled, setExportRateEnabled] = useState(false)

    const useStyles = makeStyles(()=>({
        fieldLabel: {
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles();
    const {push, query} = useRouter();
    const { put, get, response } = useCustomFetch();

    const {control, handleSubmit, setValue, reset } = useForm({resolver: yupResolver(contractUpdateSchema), defaultValues: {}});


    const onFormSubmit = async (values)=>{

        let {startDate, endDate, ...newData} = values;

        let data = {
            ...newData
        };
        data['startDate'] = format(startDate, 'yyyy-MM-dd');

        if (endDate){
            data['endDate'] = format(endDate, 'yyyy-MM-dd');
        }else{
            data['endDate'] = null;
        }

        await Popup.fire({
            title: "Güncelleniyor",
            text: "Lütfen bekleyin",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Popup.showLoading();
                await put(`/supplier-service/storecontracts/${contract.id}`, data)
                Popup.close();
                if (response.ok){
                    Popup.fire({
                        icon: "success",
                        title: "Güncellendi",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(()=>{
                        return push('/store-relations?r=1');
                    })
                }else{
                    Popup.fire({
                        icon: "error",
                        title: "Güncellenemedi!",
                        text: "Lütfen bilgilerinizi kontrol edin.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        timer: 3000,
                    }).then(()=>{
                        Popup.close();
                    })
                }
            }
        });
    }

    const onFormError = (errors)=>{
        console.log(errors)
    }

    const getSupplier  = async () => {
        return await get(`/supplier-service/suppliers/GetById/${supplier_id}?_${Date.now()}`);
    }


    const getStoreContract = async ()=>{
        return await get(`/supplier-service/storecontracts/${query.id}`);
    }

    const getContractableStore = async (storeId)=>{
        return await get(`/store-service/stores/${storeId}/contractable`);
    }


    useEffect(()=>{
        getSupplier().then((supp)=>{
            getStoreContract().then(sc => {
                sc.startDate = new Date(sc.startDate)
                sc.endDate = sc.endDate ? new Date(sc.endDate) : undefined;
                reset(sc);
                setContract(sc);
                getContractableStore(sc.storeId).then(st => {
                    if (st.countryId !== parseInt(supp.location.countryId)){
                        setExportRateEnabled(true);
                    }else{
                        setValue("exportRate", 0)
                        setExportRateEnabled(false);
                    }
                    setSupplier(supp);
                    setStore(st)
                    setLoading(false);
                })
            });
        })

    }, [])

    return (
        loading
        ?
        <Box textAlign={"center"}>
            <Box><CircularProgress /></Box>
            <Box>Yükleniyor, lütfen bekleyin...</Box>
        </Box>
        :
        <Container maxWidth={"sm"}>
            <Box mb={2} textAlign={"center"}>
                <Typography variant="h4">Mağaza İlişkisi Düzenle</Typography>
                <Typography variant="subtitle1">Mağaza ile ilişkinizi ayarlayın.</Typography>
            </Box>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
                        <Box>
                            <Typography variant="subtitle1" color="primary" style={{fontWeight: 'bold'}}>{store.brand} - {store.location}</Typography>
                        </Box>
                        <hr/>
                        <label className={classes.fieldLabel}>Tarihlerinizi belirleyin.</label>
                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Ürünleriniz belirlediğiniz tarihler aralığında aktif olarak mağazada satışa sunulacaktır.</p>
                        <FormItem control={control} name="startDate">
                            <DatePicker label={"Başlangıç Tarihi"} disabled />
                        </FormItem>
                        <FormItem control={control} name="endDate">
                            <DatePicker label={"Bitiş Tarihi"} />
                        </FormItem>
                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Bitiş tarihini boş bırakırsanız ilişki süresiz olarak tanımlanacaktır.</p>
                        <hr/>
                        <label className={classes.fieldLabel}>Oranlarınızı belirleyin.</label>
                        <FormItem control={control} name="discountRate" defaultValue={""}>
                            <TextField label="İskonto Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                        </FormItem>
                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>İskonto yapmak istemiyorsanız <b>0</b> olarak girebilirsiniz.</p>
                        {
                            exportRateEnabled && (
                                <div>
                                    <FormItem control={control} name="exportRate" defaultValue={""}>
                                        <TextField label="İhracat Fiyat Farkı Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                                    </FormItem>
                                    <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Genel ihracat fiyat farkı oranınız: <b>{supplier.money.exportRate}</b>.</p>
                                </div>
                            )
                        }
                        <FormItem control={control} name="minimumSalesRate" defaultValue={supplier.money.storeMinimumSalesRate}>
                            <TextField label="Minimum Mağaza Satış Oranı (%)" type="number" inputProps={{min: 0, max: 100, step: 0.01}}/>
                        </FormItem>
                        <p style={{fontSize: 13, marginTop: 5, marginBottom: 5, color: grey[700]}}>Genel minimum mağaza satış oranınız: <b>{supplier.money.storeMinimumSalesRate}</b>.</p>

                        <Button style={{marginTop: 16}} size={"large"} variant={"contained"} fullWidth color={"primary"} type="submit">MAĞAZA İLİŞKİSİ GÜNCELLE</Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}