import React from "react";
import FormItem from "components/form-item";
import TextField from "components/text-field";
import PhoneInput from "components/phone-input";

function CommercialStep({ control, visible }) {
  return (
    <div style={{ display: visible ? "block" : "none", width: "500px", margin: "0 auto" }}>
      <FormItem control={control} name="commercial.brand">
        <TextField label="Marka" />
      </FormItem>
      <FormItem control={control} name="commercial.title">
        <TextField label="Ticari Ünvan" />
      </FormItem>
      <FormItem control={control} name="commercial.phone">
        <PhoneInput label="Telefon" placeholder="Telefon" />
      </FormItem>
      <FormItem control={control} name="commercial.email">
        <TextField label="E-Posta Adresi" />
      </FormItem>
      <FormItem control={control} name="commercial.website">
        <TextField label="İnternet Sitesi" />
      </FormItem>
    </div>
  );
}

export default CommercialStep;
