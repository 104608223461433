import * as yup from 'yup'
// TODO: Make unique name error check.
yup.addMethod(yup.array, 'unique', function (message, field) {
    return this.test('unique', message, function (list) {
        let set = new Set(list.map(()=> list[field]));
        let isUnique = list.length === set.size;
        if (isUnique){
            return true;
        }
        let idx = list.findIndex((l, i) => l[field] !== set[i]);
        return this.createError({ path: `${this.path}.[${idx}].${field}`, message });
    });
});

export const createSchema = yup.object().shape({
    modelName: yup.string().nullable().required("Ürün modeli adı gereklidir."),
    categoryId: yup.string().nullable().required("Ürün kategorisi gereklidir."),
    isModule: yup.boolean().oneOf([true, false], "Ürün modül bilgisi gereklidir."),
    productionTime: yup.number().required("Üretim süresi gereklidir.").typeError('Süre sayı içermelidir.').min(1, "Süre en az 1 gün olabilir."),
    features: yup
        .array()
        .of(yup.object().shape({
            featureId: yup.string().nullable().required("Özellik kimliği gereklidir."),
            values: yup.array().of(yup.object().shape({
                name: yup.string().nullable().required(),
                value: yup.string().nullable().required(),
                featureValue: yup.string().nullable(),
                isNew: yup.boolean().default(false),
            })).nullable().required("Özellik içeriği gereklidir.").min(1, "Özellik içeriği gereklidir."),
        })).required("Özellik gereklidir.").min(1, "Özellik gereklidir."),
    modules: yup
        .array()
        .of(yup.object().shape({
            moduleId: yup.string().nullable().required("Modül adı gereklidir."),
            isNew: yup.boolean().default(false),
            price: yup.number().nullable().required("Modül Fiyatı gereklidir.").typeError('Fiyat sayı içermelidir.'),
            isStandard: yup.boolean().default(false),
            standardQuantity: yup.number()
                .transform((c, o) => o === '' ? null : c)
                .nullable()
                .typeError('Takım İçeriğindeki Adet sayı içermelidir.')
                .when('isStandard', {
                    is: true,
                    then: yup.number().transform((c, o) => o === '' ? null : c)
                        .nullable()
                        .typeError('Takım İçeriğindeki Adet sayı içermelidir.')
                        .required("Takım İçeriğindeki Adet gereklidir")
                        .positive('Takım İçeriğindeki Adet sıfırdan büyük olmalıdır.'),
                }),
            dimensions: yup.object().shape({
                width: yup.number().nullable().required("Genişlik gereklidir.").typeError('Genişlik sayı içermelidir.').positive('Genişlik sıfırdan büyük olmalıdır.'),
                height: yup.number().nullable().required("Yükseklik gereklidir.").typeError('Yükseklik sayı içermelidir.').positive('Yükseklik sıfırdan büyük olmalıdır.'),
                depth: yup.number().nullable().required("Derinlik gereklidir.").typeError('Derinlik sayı içermelidir.').positive('Derinlik sıfırdan büyük olmalıdır.'),
            }),
            hasExpandedDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Açılır boyut seçimi geçerli değil."),
            expandedDimensions: yup.object().nullable().when(['hasExpandedDimensions','$fromContext'], {
                is: (value) => !!value,
                then: yup.object().shape({
                    width: yup.number().nullable().required("Açılır Genişlik gereklidir.").typeError('Açılır Genişlik sayı içermelidir.').positive('Açılır Genişlik sıfırdan büyük olmalıdır.'),
                    height: yup.number().nullable().required("Açılır Yükseklik gereklidir.").typeError('Açılır Yükseklik sayı içermelidir.').positive('Açılır Yükseklik sıfırdan büyük olmalıdır.'),
                    depth: yup.number().nullable().required("Açılır Derinlik gereklidir.").typeError('Açılır Derinlik sayı içermelidir.').positive('Açılır Derinlik sıfırdan büyük olmalıdır.'),
                }).required("Açılır boyutlar gereklidir.")
            }),
            hasSeatDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Oturum boyutları seçimi geçerli değil."),
            seatDimensions: yup.object().nullable().when(['hasSeatDimensions','$fromContext'], {
                is: (value) => !!value,
                then: yup.object().shape({
                    width: yup.number().nullable().required("Oturum Genişliği gereklidir.").typeError('Oturum Genişliği sayı içermelidir.').positive('Oturum Genişliği sıfırdan büyük olmalıdır.'),
                    height: yup.number().nullable().required("Oturum Yüksekliği gereklidir.").typeError('Oturum Yüksekliği sayı içermelidir.').positive('Oturum Yüksekliği sıfırdan büyük olmalıdır.'),
                    depth: yup.number().nullable().required("Oturum Derinliği gereklidir.").typeError('Oturum Derinliği sayı içermelidir.').positive('Oturum Derinliği sıfırdan büyük olmalıdır.'),
                }).required("Oturum boyutları gereklidir.")
            }),
            hasFootDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Ayak boyutları seçimi geçerli değil."),
            footDimensions: yup.object().nullable().when(['hasFootDimensions','$fromContext'], {
                is: (value) => !!value,
                then: yup.object().shape({
                    height: yup.number().nullable().required("Ayak Yüksekliği gereklidir.").typeError('Ayak Yüksekliği sayı içermelidir.').positive('Ayak Yüksekliği sıfırdan büyük olmalıdır.'),
                    diameter: yup.number().nullable().required("Ayak Çapı gereklidir.").typeError('Ayak Çapı sayı içermelidir.').positive('Ayak Çapı sıfırdan büyük olmalıdır.'),
                }).required("Ayak boyutları gereklidir.")
            }),
            packaging: yup.object().shape({
                quantity: yup.number().nullable().required("Paketleme Adedi gereklidir.").typeError('Paketleme Adedi sayı içermelidir.'),
                weight: yup.number().nullable().required("Ağırlık gereklidir.").typeError('Ağırlık sayı içermelidir.'),
                desi: yup.number().nullable().required("Desi gereklidir.").typeError('Desi sayı içermelidir.'),
            }),
            images: yup.array().of(yup.object()),//.min(1, "Modül Görseli gereklidir."),
        })).when('isModule', {
            is: true,
            then: yup.array()/*.unique('Modül adı benzersiz olmalıdır.', 'name')*/.required("Modül içeriği gereklidir.").min(2, "Ürününüzün modüler olması için en az 2 adet modül eklemelisiniz.")
        }),
    single: yup.object().when('isModule',
        {
            is: false,
            then: yup.object().shape({
                price: yup.number().nullable().required("Fiyat gereklidir.").typeError('Fiyat sayı içermelidir.'),
                dimensions: yup.object().shape({
                    width: yup.number().nullable().required("Genişlik gereklidir.").typeError('Genişlik sayı içermelidir.').positive('Genişlik sıfırdan büyük olmalıdır.'),
                    height: yup.number().nullable().required("Yükseklik gereklidir.").typeError('Yükseklik sayı içermelidir.').positive('Yükseklik sıfırdan büyük olmalıdır.'),
                    depth: yup.number().nullable().required("Derinlik gereklidir.").typeError('Derinlik sayı içermelidir.').positive('Derinlik sıfırdan büyük olmalıdır.'),
                }),
                hasExpandedDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Açılır boyut seçimi geçerli değil."),
                expandedDimensions: yup.object().nullable().when(['hasExpandedDimensions','$fromContext'], {
                    is: (value) => !!value,
                    then: yup.object().shape({
                        width: yup.number().nullable().required("Açılır Genişlik gereklidir.").typeError('Açılır Genişlik sayı içermelidir.').positive('Açılır Genişlik sıfırdan büyük olmalıdır.'),
                        height: yup.number().nullable().required("Açılır Yükseklik gereklidir.").typeError('Açılır Yükseklik sayı içermelidir.').positive('Açılır Yükseklik sıfırdan büyük olmalıdır.'),
                        depth: yup.number().nullable().required("Açılır Derinlik gereklidir.").typeError('Açılır Derinlik sayı içermelidir.').positive('Açılır Derinlik sıfırdan büyük olmalıdır.'),
                    }).required("Açılır boyutlar gereklidir.")
                }),
                hasSeatDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Oturum boyutları seçimi geçerli değil."),
                seatDimensions: yup.object().nullable().when(['hasSeatDimensions','$fromContext'], {
                    is: (value) => !!value,
                    then: yup.object().shape({
                        width: yup.number().nullable().required("Oturum Genişliği gereklidir.").typeError('Oturum Genişliği sayı içermelidir.').positive('Oturum Genişliği sıfırdan büyük olmalıdır.'),
                        height: yup.number().nullable().required("Oturum Yüksekliği gereklidir.").typeError('Oturum Yüksekliği sayı içermelidir.').positive('Oturum Yüksekliği sıfırdan büyük olmalıdır.'),
                        depth: yup.number().nullable().required("Oturum Derinliği gereklidir.").typeError('Oturum Derinliği sayı içermelidir.').positive('Oturum Derinliği sıfırdan büyük olmalıdır.'),
                    }).required("Oturum boyutları gereklidir.")
                }),
                hasFootDimensions: yup.boolean().nullable().default(false).oneOf([true, false], "Ayak boyutları seçimi geçerli değil."),
                footDimensions: yup.object().nullable().when(['hasFootDimensions','$fromContext'], {
                    is: (value) => !!value,
                    then: yup.object().shape({
                        height: yup.number().nullable().required("Ayak Yüksekliği gereklidir.").typeError('Ayak Yüksekliği sayı içermelidir.').positive('Ayak Yüksekliği sıfırdan büyük olmalıdır.'),
                        diameter: yup.number().nullable().required("Ayak Çapı gereklidir.").typeError('Ayak Çapı sayı içermelidir.').positive('Ayak Çapı sıfırdan büyük olmalıdır.'),
                    }).required("Ayak boyutları gereklidir.")
                }),
                packaging: yup.object().shape({
                    quantity: yup.number().nullable().required("Paketleme Adedi gereklidir.").typeError('Paketleme Adedi sayı içermelidir.'),
                    weight: yup.number().nullable().required("Ağırlık gereklidir.").typeError('Ağırlık sayı içermelidir.'),
                    desi: yup.number().nullable().required("Desi gereklidir.").typeError('Desi sayı içermelidir.'),
                })
            })
        }),
    designerId: yup.string().nullable(),//.required("Tasarımcı adı gereklidir."),
    images: yup.array().of(yup.object()).min(1, "Ürün Görseli gereklidir."),
    videos: yup
        .array()
        .of(yup.object().shape({
            provider: yup.string().nullable().required("Video sağlayıcı gereklidir."),
            url: yup.string().url("Video adresi geçerli değil.").nullable().required("Video adresi gereklidir."),
        })
        ),
    documents: yup.array().of(yup.object()),
});

