import React, {useEffect, useState} from "react";
import FormItem from "components/form-item";
import Select from "components/select";
import TextField from "components/text-field";
import useCustomFetch from "hooks/use-fetch";

export default function LocationStep({ control, setValue, visible, optionalParams }) {
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const { get, response } = useCustomFetch();

  useEffect(() => {
    async function getCountries() {
      const data = await get("i18n-service/countries/select/active");
      if (response.ok)
        setCountryOptions(
          data.map((item) => ({ id: item.id, name: item.translations.tr }))
        );
    }
    getCountries();
  }, []);

  useEffect(() => {
    if (optionalParams) {
      const {
        location: { countryId, stateId },
      } = optionalParams;
      countryId && handleCountryChange({id: countryId});
      stateId && handleStateChange({id: stateId});
    }
  }, [optionalParams]);

  const handleCountryChange = async (val) => {
    setValue("location.countryId", val && val.id);

    const data = await get(
      `i18n-service/countries/${val.id}/states/select/active`
    );
    if (response.ok)
      setStateOptions(data.map((item) => ({ id: item.id, name: item.name })));
  };

  const handleStateChange = async (val) => {
    setValue("location.stateId", val && val.id);

    const data = await get(`i18n-service/states/${val.id}/cities/select/active`);
    if (response.ok)
      setCityOptions(data.map((item) => ({ id: item.id, name: item.name })));
  };

  return (
    <div style={{ display: visible ? "block" : "none", width: "500px", margin: "0 auto" }}>
      <FormItem
        control={control}
        name="location.countryId"
        onChange={handleCountryChange}
      >
        <Select
          id="country"
          label="Ülke"
          options={countryOptions}
          displayMember="name"
        />
      </FormItem>
      <FormItem
        control={control}
        name="location.stateId"
        onChange={handleStateChange}
      >
        <Select
          id="state"
          label="Şehir"
          options={stateOptions}
          displayMember="name"
        />
      </FormItem>
      <FormItem control={control} name="location.cityId" onChange={(v)=>{ setValue("location.cityId", v && v.id) }}>
        <Select
          id="city"
          label="İlçe"
          options={cityOptions}
          displayMember="name"
        />
      </FormItem>
      <FormItem control={control} name="location.address">
        <TextField label="Adres" multiline rows={2} />
      </FormItem>
    </div>
  );
}
