import * as yup from 'yup'

export const companyPricesForm = yup.object().shape({
    money: yup.object().nullable().shape({
        currency: yup.string().nullable().required("Para birimi gereklidir."),
        exportRate: yup.number().nullable().required("İhracat fiyat farkı yüzdesi gereklidir.").typeError("İhracat fiyat farkı yüzdesi gereklidir."),
        storeMinimumSalesRate: yup.number().nullable().required("Satış mağazası minimum satış yüzdesi gereklidir.").typeError("Satış mağazası minimum satış yüzdesi gereklidir."),
        useFixedExchangeRates: yup.boolean().nullable().required("Döviz kuru türü gereklidir.").default(false).oneOf([true, false], "Döviz kuru seçimi geçerli değil."),
        exchangeRates: yup.object().nullable().when(['useFixedExchangeRates','$fromContext'], {
            is: (value) => !!value,
            then: yup.object().shape({
                usd: yup.number().nullable().required("Dolar kuru gereklidir.").typeError("Dolar kuru gereklidir."),
                eur: yup.number().nullable().required("Euro kuru gereklidir.").typeError("Euro kuru gereklidir."),
                gbp: yup.number().nullable().required("Sterlin kuru gereklidir.").typeError("Sterlin kuru gereklidir."),
            }).required("Döviz kurları gereklidir.")
        }),
    }).required("Para birimi ve oranlar gereklidir."),
});

