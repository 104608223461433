import React, {useEffect, useState} from "react";
import FormItem from "components/form-item";
import Grid from "components/grid";
import TextField from "components/text-field";
import {Avatar, Box, Chip, Divider, Typography} from "@material-ui/core";
import {HelpOutline} from "@material-ui/icons";
import useCustomFetch from "hooks/use-fetch";
import Checkbox from "../../../components/checkbox";

const ProductSingle = ({control, watch, supplier}) => {
    const [rates, setRates] = useState({});
    const [currencyRates, setCurrencyRates] = useState(null);
    const [priceValue, setPriceValue] = useState(null);
    const {get, response} = useCustomFetch();

    const currencies = {
        try: {
            title: 'Türk Lirası',
            code: 'try',
        },
    }

    const exportRate = supplier?.money?.exportRate || 0;
    const currency = currencies[supplier?.money?.currency] || currencies.try;

    const watchPrice = watch("single", {});

    const watchHasExpandedDimension = watch("single.hasExpandedDimensions", false);
    const watchHasSeatDimension = watch("single.hasSeatDimensions", false);
    const watchHasFootDimension = watch("single.hasFootDimensions", false);

    const calculateRates = ()=>{
        let price = parseInt(priceValue);
        if (price && currencyRates){
            let rate = (parseFloat(exportRate) / 100) || 0,
                usd = currencyRates.usd,
                eur = currencyRates.eur,
                gbp = currencyRates.gbp;
            let ratedPrice = price + (price * rate);

            let usdPrice = (ratedPrice / usd).toFixed(2);
            let eurPrice = (ratedPrice / eur).toFixed(2);
            let gbpPrice = (ratedPrice / gbp).toFixed(2);

            setRates({usdPrice, eurPrice, gbpPrice})
        }else{
            setRates({});
        }
    }

    const getCurrencyRates = async () => {
        if (currencyRates){
            return currencyRates;
        }
        let data = await get(`/i18n-service/currencies/rates`);
        if (response.ok && data){
            return data.rates;
        }else{
            console.log("Güncel kur verisi bulunamadı!");
        }
    }

    useEffect(()=>{
        getCurrencyRates().then((data)=>{
            setCurrencyRates(data)
            if (watchPrice && watchPrice.price) {
                setPriceValue(watchPrice.price)
            }
        });
    }, [])

    useEffect(() => {
        calculateRates()
    }, [priceValue])

    return (
        <Grid container spacing={1}>
            <Grid item md={4}>
                <FormItem control={control} name={`single.price`} onChange={(value) => setPriceValue(value)}>
                    <TextField label={`Ürün Fiyatı (${currency.title} ${currency.code.toUpperCase()})`} type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={8}>
                <Box display={"flex"} alignItems={"center"} style={{height: 52}}>
                    {
                        rates && rates.usdPrice &&
                        (
                            <Chip avatar={<Avatar>$</Avatar>} label={`${rates.usdPrice} Dolar`} title={`Dolar kuru: ${currencyRates.usd}`} variant="outlined" color="primary" style={{marginRight: 10}}/>
                        )
                    }
                    {
                        rates && rates.eurPrice &&
                        (
                            <Chip avatar={<Avatar>€</Avatar>} label={`${rates.eurPrice} Euro`} title={`Euro kuru: ${currencyRates.eur}`} variant="outlined" color="secondary" style={{marginRight: 10}}/>
                        )
                    }
                    {
                        rates && rates.gbpPrice &&
                        (
                            <Chip avatar={<Avatar>£</Avatar>} label={`${rates.gbpPrice} Sterlin`} title={`Sterlin kuru: ${currencyRates.gbp}`} variant="outlined" color="default" style={{marginRight: 10}} />
                        )
                    }
                    <Box style={{marginTop: 3}} title="Desteklenen para birimleri ve dönüşümleri burada görünecektir.">
                        <HelpOutline color={"action"}/>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={12}>
                <small style={{color: '#686868'}}>Fiyat dönüşümü, TCMB güncel kur değerleri ve ayarlarınızda bulunan
                    "İhracat Farkı Yüzdesi" değeri(<strong title={"Mevcut İhracat Farkı Yüzdesi"}>%{exportRate}</strong>) kullanılarak hesaplanmaktadır.</small>
            </Grid>

            <Grid item md={12}>
                <Typography style={{ paddingBottom: 8, fontSize: 16 }} variant={"subtitle2"} color="textSecondary">Ölçüler</Typography>
                <Divider style={{marginBottom: 5}} />
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`single.dimensions.width`}>
                    <TextField label="Genişlik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`single.dimensions.height`}>
                    <TextField label="Yükseklik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`single.dimensions.depth`}>
                    <TextField label="Derinlik (cm)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name={`single.packaging.quantity`}>
                    <TextField label="Paketleme Adedi" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`single.packaging.weight`}>
                    <TextField label="Ağırlık (kg)" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>
            <Grid item md={4}>
                <FormItem control={control} name={`single.packaging.desi`}>
                    <TextField label="Desi" type="number" inputProps={{min: 0}}/>
                </FormItem>
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name="single.hasExpandedDimensions">
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Açılır Boyut girmek istiyorum</Typography>)} style={{textColor: 'red'}} size="small" />
                </FormItem>
                {
                    watchHasExpandedDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.expandedDimensions.width`}>
                                    <TextField label="Açılır Genişlik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.expandedDimensions.height`}>
                                    <TextField label="Açılır Yükseklik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.expandedDimensions.depth`}>
                                    <TextField label="Açılır Derinlik (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name="single.hasSeatDimensions">
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Oturum Boyutu girmek istiyorum</Typography>)} size="small" />
                </FormItem>
                {
                    watchHasSeatDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.seatDimensions.width`}>
                                    <TextField label="Oturum Genişliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.seatDimensions.height`}>
                                    <TextField label="Oturum Yüksekliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.seatDimensions.depth`}>
                                    <TextField label="Oturum Derinliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>

            <Grid item md={4}>
                <FormItem control={control} name="single.hasFootDimensions">
                    <Checkbox label={(<Typography variant={"body1"} color="textSecondary">Ayak Boyutu girmek istiyorum</Typography>)} size="small" />
                </FormItem>
                {
                    watchHasFootDimension && (
                        <>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.footDimensions.height`}>
                                    <TextField label="Ayak Yüksekliği (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                            <Grid item md={12}>
                                <FormItem control={control} name={`single.footDimensions.diameter`}>
                                    <TextField label="Ayak Çapı (cm)" type="number" inputProps={{min: 0}}/>
                                </FormItem>
                            </Grid>
                        </>
                    )
                }
            </Grid>

        </Grid>
    );
}

ProductSingle.defaultProps = {
    supplier: null,
}

export default ProductSingle;