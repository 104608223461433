import OidcConstants from "../config/oidc.config";
import envConfig from "../config/env.config";
import Popup from "../components/popup";
import HmacHasher from "jssha";

export const getValueFromTranslation = (
    translations,
    propertyName,
    lang = "tr"
) => {
    const value = translations.find((item) => item.lang === lang) || {};
    return value[propertyName];
};

export const getFeatureComputedValues = (values = [], lang = "tr") => {
    let items = values.map((value) => value.translations.find(t => t.lang === lang) || {})
    let text = items.reduce((acc, curr) => [...acc, curr.value], []).join(', ');

    return text !== '' ? text : '-';
}


export const getSessionExpiration = () => {
    let session = getCurrentUser();
    if (!session) {
        return 0;
    }
    return parseInt(session.expires_at + "000");
}


export const getCurrentUser = () => {
    let storage = sessionStorage.getItem(`oidc.user:${OidcConstants.authority}:${OidcConstants.client_id}`);
    if (!storage) {
        return null;
    }
    return JSON.parse(storage);
}


function hex2a(hexx) {
    let hex = hexx.toString() //force conversion
    let str = ''
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
    return str
}


function generateProxyUrl(opts) {
    let encoded_url = btoa(opts.url).replace(/=/g, "").replace(/\//g, '_').replace(/\+/g, '-');
    let path = "/rs:" + opts.resize + ":" + opts.width + ":" + opts.height + ":" + opts.enlarge + "/ex:" + opts.extend +  "/bg:" + opts.bg +"/g:" + opts.gravity + "/q:" + opts.quality + "/" + encoded_url + "." + opts.extension
    let hash = new HmacHasher("SHA-256", "BYTES")
    hash.setHMACKey(opts.key, "HEX")
    hash.update(hex2a(opts.salt))
    hash.update(path)
    let hmac = hash.getHMAC("B64").replace(/=/g, "").replace(/\//g, '_').replace(/\+/g, '-')
    return opts.proxy_url + "/" + hmac + path
}


export const generateImageURL = (image = null, width=0, height=0, resize='auto', quality=80, enlarge=0, extend=0, bg="ffffff") => {
    let path =  image !== null ? image.path : 'Uploads/images/default.jpg';
    return  generateProxyUrl({
        url: path.replace(/Uploads/, envConfig.ASSET_URL),
        proxy_url: envConfig.IMGPROXY_URL,
        key:  envConfig.IMGPROXY_KEY,
        salt: envConfig.IMGPROXY_SALT,
        resize: resize,
        width: width,
        height: height,
        enlarge: enlarge,
        extend: extend,
        bg: bg,
        quality: quality,
        gravity: 'ce',
        extension: 'webp',
    });
}

export const generateOriginalImageUrl = (image) => {
    return image.path.replace(/Uploads/, envConfig.ASSET_URL);
}

export const removeSpaces = (text) => {
    if (!text) return;
    return text.replace(/\s{2,}/g, " ").trim();
}

export const makeUpperFirstLetters = (text) => {
    text = removeSpaces(text);
    if (!text) return;
    return text
        .toLowerCase()
        .split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1))
        .join(' ');
}

export const notImplemented = ()=> {
    return Popup.fire({
        icon: "warning",
        title: `Bu özellik henüz aktif değil!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 700,
    });
}

export const slugify = (str) => {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

export const getPrice = (prices, currency) => {
    let price = prices.find(p => p.currency === currency);
    if (price){
        return price.quantity;
    }
    return null;
}

