import {Box, Button, Chip, CircularProgress, Container, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Typography from "../../components/typography";
import React, {useEffect, useState} from "react";
import {Add, Delete, Edit} from "@material-ui/icons";
import Card from "../../components/card";
import {common, green, grey, red} from "@material-ui/core/colors";
import useCustomFetch from "../../hooks/use-fetch";
import Popup from "../../components/popup";
import {getCurrentUser} from "../../utils";

export default function StoreRelations(props){

    const currentUser = getCurrentUser();

    const supplier_id = currentUser.profile?.supplier_id;

    const { put, del, get, response,  } = useCustomFetch();

    const [loading, setLoading] = useState(true)
    const [storeContracts, setStoreContracts] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [supplierCanCreateRelation, setSupplierCanCreateRelation] = useState(false)


    const changeActiveStatus = async (event, storeContract)=>{

        storeContract.active = !storeContract.active;

        await updateStatus(storeContract);

        let stIndex = storeContracts.findIndex(st => st.id === storeContract.id);

        storeContracts[stIndex] = storeContract;

        setStoreContracts([...storeContracts])

    };

    const updateStatus = async (contract)=>{
        await Popup.fire({
            title: "Durum değiştiriliyor",
            text: "Lütfen bekleyin",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Popup.showLoading();
                await put(`/supplier-service/storecontracts/${contract.id}`, contract)
                Popup.close();
                if (response.ok){
                    Popup.fire({
                        icon: "success",
                        title: "Değiştirildi",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 2000,
                    }).then(()=>{

                    })
                }else{
                    Popup.fire({
                        icon: "error",
                        title: "Değiştirilemedi!",
                        text: "Lütfen bilgilerinizi kontrol edin.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        timer: 2000,
                    }).then(()=>{
                        Popup.close();
                    })
                }
            }
        });
    }

    const deleteContract = async (contract)=>{

        await Popup.fire({
            title: 'Emin misiniz?',
            html: `<b>${contract.store.brand}</b> mağazası ile ilişkinizi kaldırıyorsunuz?<br><br><small>Ürünleriniz bu mağazada satışa sunulmayacaktır.</small>`,
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: 'Evet',
            showCancelButton: true,
            cancelButtonText: 'İptal',
        }).then(async (answer)=>{
            if (answer.isConfirmed){
                await Popup.fire({
                    title: "İlişki kaldırılıyor",
                    text: "Lütfen bekleyin",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Popup.showLoading();
                        await del(`/supplier-service/storecontracts/${contract.id}`)
                        Popup.close();
                        if (response.ok){
                            Popup.fire({
                                icon: "success",
                                title: "İlişki kaldırıldı!",
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                timer: 2000,
                            }).then(()=>{
                                setLoading(true)
                                getStoreContracts().then((contracts)=>{
                                    setStoreContracts(contracts)
                                    setLoading(false)
                                })
                            })
                        }else{
                            Popup.fire({
                                icon: "error",
                                title: "İlişki kaldırılamadı!",
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                timer: 2000,
                            }).then(()=>{
                                Popup.close();
                            })
                        }
                    }
                });
            }
            return false;
        })

    }

    const getSupplier  = async () => {
        return await get(`/supplier-service/suppliers/GetById/${supplier_id}?_${Date.now()}`);
    }

    const getStoreContracts = async ()=>{
        let url = `/supplier-service/storecontracts/listings`;
        if(props.location.search !== ""){
            url = `${url}?_${Date.now()}`;
        }
        return await get(url);
    };

    useEffect(()=>{
        getSupplier().then((response)=> {
            setSupplier(response);
            setSupplierCanCreateRelation(response.money !== null && response.money.currency !== null);
            getStoreContracts().then((contracts)=>{
                setStoreContracts(contracts)
                setLoading(false)
            })
        })

    }, [])

    return(
        loading
        ?
        <Box textAlign={"center"}>
            <Box><CircularProgress /></Box>
            <Box>Yükleniyor, lütfen bekleyin...</Box>
        </Box>
        :
        <Container maxWidth={"lg"}>
            <Box mb={2} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant="h4">Mağaza İlişkileri</Typography>
                    <Typography variant="subtitle1">Ürünlerinizin satışa sunulacağı mağazalar.</Typography>
                </Box>
                <Box>
                    <Button href="/store-relations/create" color={"primary"} variant="outlined" size={"small"} disabled={!supplierCanCreateRelation}><Add/> YENİ MAĞAZA İLİŞKİSİ OLUŞTUR</Button>
                </Box>
            </Box>
            {
                (!supplierCanCreateRelation) && (
                    <Box style={{textAlign: 'center'}}>
                        <Typography variant="subtitle2" style={{color: red[600]}}>Lütfen ilk önce ana para biriminizi ve oranlarınızı ayarlayın.</Typography>
                        <Button href="/company" variant={"outlined"} color={"primary"} style={{marginTop: 10}}>FİRMA BİLGİLERİNİZİ DÜZENLEYİN</Button>
                    </Box>
                )
            }
            {
                supplierCanCreateRelation && storeContracts.length === 0 && (
                    <Box style={{textAlign: 'center'}}>
                        <Typography variant="subtitle2" style={{color: grey[700]}}>Henüz bir mağaza ilişkisi oluşturmadınız.</Typography>
                    </Box>
                )
            }
            {
                storeContracts.length > 0 && (
                    <Card style={{marginTop: 10}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}}># No.</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Mağaza</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Konum</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Başlangıç Tarihi</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Bitiş Tarihi</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}} align={"center"}>İskonto Oranı</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}} align={"center"}>İhracat Fiyat Farkı Oranı</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}} align={"center"}>Minimum Satış Oranı</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}} align={"center"}>Aktif?</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}} align="right">İşlemler</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    storeContracts.map((st) => (
                                        <TableRow key={st.id}>
                                            <TableCell>{st.id.substring(0, 8).toLocaleUpperCase()}</TableCell>
                                            <TableCell>{st.store.brand}</TableCell>
                                            <TableCell>{st.store.location}</TableCell>
                                            <TableCell>{(new Date(st.startDate)).toLocaleDateString()}</TableCell>
                                            <TableCell>{st.endDate ? (new Date(st.endDate)).toLocaleDateString() : 'Süresiz'}</TableCell>
                                            <TableCell align={"center"}><Chip size={"small"} color={"secondary"} label={`%${st.discountRate}`} /></TableCell>
                                            <TableCell align={"center"}><Chip size={"small"} color={"default"} label={`%${st.exportRate}`} /></TableCell>
                                            <TableCell align={"center"}><Chip size={"small"} style={{color: common.white, background: green[700]}} label={`%${st.minimumSalesRate}`} /></TableCell>
                                            <TableCell align={"center"}>
                                                <Switch
                                                    checked={st.active}
                                                    onChange={(event)=>{changeActiveStatus(event, st)}}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <IconButton aria-label="edit" href={`/store-relations/${st.id}/edit`} title={"Düzenle"}>
                                                        <Edit fontSize="small" color="primary" />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" title={"İptal Et"} onClick={()=>{deleteContract(st)}}>
                                                        <Delete fontSize="small" color="secondary" />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>

                        </Table>
                    </Card>
                )
            }
        </Container>
    )
}